import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { FlagIconSource } from "../../../../utils/imagesource";
import './CountrySelection.css';


const CountryCard = (props) => {
    const [iconinfo, setIconInfo] = useState(props.country?.iconref);

    return (
        <div title={props.country.label} className="countrycard-root" onClick={() => props.onClick(props.country)}>
            <div className="countrycard-label">{props.country.label}</div>
            <div className="countrycard-flag">
                <img src={iconinfo} />
            </div>
        </div>
    )

}

const CountrySelection = (props) => {
    const countries = useSelector(state => state.config.countries);
    let countryOptions = countries.map((country) =>{
        country.iconref = FlagIconSource[country?.code] || null;
        return country
    })
    
    return (
        <div className="country-root">
            <div className="country-label">
               <p>Select a Supported Country</p>
            </div>
            <div className="country-list">
                {
                    countryOptions.map((country, index) => {
                        return <CountryCard key={index} country={country} onClick={props.onClick} />
                    })
                }
            </div>

        </div>
    )

}

export default CountrySelection;

