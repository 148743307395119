import React, { useRef, useEffect, useState } from "react";
import './Legal.css';

function Privacy() {

  return (
    <div className="privacy">
      <h1>PRIVACY POLICY</h1>
      <p>
        Visit <a target="#" href="https://www.birdlife.org/privacy-policy/">www.birdlife.org/privacy-policy/</a> to view AVISTEP’s privacy policy.
      </p>
    </div>
  );
}



export default Privacy;
