import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { formatLatitude, formatLongitude } from '../../../../utils/map';

//Redux Imports
import { setCellReport } from '../../../../redux/reducers/map';

// Esri imports
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
//import * as watchUtils from "@arcgis/core/core/watchUtils";
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import Graphic from '@arcgis/core/Graphic';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';

// Styled Components
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

// Component
const CellMap = (props) => {
  const mapContainer = 'cell-map-container';
  const chartContainer = 'cell-chart-container';
  const mapDiv = useRef(null);
  const mapViewRef = useRef(null);
  const energyLyrRef = useRef(null);
  const regionLyrRef = useRef(null);
  const countryLyrRef = useRef(null);
  const gridlayerRef = useRef(null);
  const mapStateRef = useRef({});
  const [mapViewLoaded, setMapViewLoaded] = useState(false);
  const [mapDataLoaded, setMapDataLoaded] = useState(false);
  const [cellImage, setCellImage] = useState(false);
  const [regionImage, setRegionImage] = useState(false);
  const [chartImage, setChartImage] = useState(false);
  const [landcoverData, setLandCoverData] = useState(null);
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const countries = useSelector((state) => state.config.countries);
  const regions = useSelector((state) => state.config.regions);
  const mapState = useSelector((state) => state.map.mapState);
  const cellReportStatus = useSelector((state) => state.map.cellReportStatus);
  const cellSelected = useSelector((state) => state.map.cellSelected);
  const cellData = useSelector((state) => state.map.cellData);
  const cellSpecies = useSelector((state) => state.map.cellSpecies);
  const cellSites = useSelector((state) => state.map.cellSites);

  const generateRegionImage = async (coords) => {
    let mapextent = mapViewRef.current.extent.clone();
    mapextent = mapextent.expand(1.2);
    let areabound = new Graphic({
      symbol: {
        type: 'simple-fill',
        outline: { width: 2.3, color: [5, 5, 255, 1.5] },
        color: [0, 0, 0, 0],
      },
      geometry: mapextent,
    });
    mapViewRef.current.graphics.removeAll();
    mapViewRef.current.graphics.add(areabound);

    gridlayerRef.current.visible = false;
    energyLyrRef.current.opacity = 0.6;
    energyLyrRef.current.visible = true;
    let regionview = await mapViewRef.current.goTo({ target: coords, zoom: 7 });
    /* watchUtils.whenFalseOnce(mapViewRef.current, "updating", function(){
            setTimeout(function(){
                mapViewRef.current.takeScreenshot().then(function(screenshot){
                    setRegionImage(screenshot);
                });
            }, 500);
        }); */

    reactiveUtils.when(
      () => !mapViewRef.current.updating,
      async () => {
        //console.log("updating: " + mapViewRef.current.updating);
        await setTimeout(() => {
          mapViewRef.current.takeScreenshot().then((screenshot) => {
            setRegionImage(screenshot);
          });
        }, 500);
      },
      {
        once: true,
      }
    );

   
  };

  const generateCellImage = async (coords) => {
    /* const returnImage = () => {
      return;
    }; */
    /* watchUtils.whenFalseOnce(mapViewRef.current, "updating", function(){
            setTimeout(function(){
                mapViewRef.current.takeScreenshot().then(function(screenshot){
                    setCellImage(screenshot);
                    generateRegionImage(coords);
                });
            }, 500);
        }); */


    reactiveUtils.when(
      () => !mapViewRef.current.updating,
      async () => {
        //console.log("updating: " + mapViewRef.current.updating);
        await setTimeout(() => {
          mapViewRef.current.takeScreenshot().then((screenshot) => {
            setCellImage(screenshot);
            generateRegionImage(coords);
          });
        }, 500);
      },
      {
        once: true,
      }
    );
  };

  const generateMapImages = async (coords) => {
    if (
      mapStateRef.current.country &&
      mapStateRef.current.energy &&
      mapViewRef.current
    ) {
      await generateCellImage(coords);
    }
  };

  const generateChartImage = async () => {
    //Convert chart div to image, pass to state
    var node = document.getElementById(chartContainer);
    let imagedata = await htmlToImage.toPng(node);
    setChartImage({ dataUrl: imagedata, data: {} });
  };

  useEffect(() => {
    let loaded = false;
    (async () => {
      try {
        if (true) {
          let webmapID = config.webmapID;
          var mapConfig = {
            portalItem: { id: webmapID, portal: { url: config.portal } },
            center: [79.48189730121511, 23.49926361971663],
            zoom: 4,
          };

          // create the Map
          const map = new WebMap({
            ...mapConfig,
          });

          map.when(async () => {});

          // eslint-disable-next-line
          const view = new MapView({
            map,
            container: mapDiv.current,
            center: [79.48189730121511, 23.49926361971663],
            zoom: 4,
            constraints: { snapToZoom: false },
          });
          //padding for the left panel
          let paddingWidth = document.getElementsByClassName("left-main")[0].clientWidth;
          view.padding.left = paddingWidth;
          // Remove attribution from map
          view.ui._removeComponents(['zoom', 'attribution']);
          view.when(async () => {
            mapViewRef.current = view;
            view.popup = null;

            view.map.basemap = 'streets-navigation-vector';

            let scaleBar = new ScaleBar({
              view: view,
            });
            view.ui.add(scaleBar, {
              position: 'bottom-left',
            });

            // Override layer settings
            view.map.layers.forEach((layer) => {
              layer.visible = false;
              layer.outFields = ['*'];
              if (layer.type === 'feature') {
                layer.popupEnabled = false;
              }
            });

            let regionenergytitle = `avistep_${mapState.country.toLowerCase()}_${mapState.energy.code.toLowerCase()}_areas`;
            let energyLyr = view.map.layers.find(
              (layer) => layer.title === regionenergytitle
            );
            if (energyLyr) {
              energyLyrRef.current = energyLyr;
            }

            let regionLyr = view.map.layers.find(
              (layer) => layer.title === 'avistep_internal_boundaries'
            );
            if (regionLyr) {
              regionLyr.visible = true;
              regionLyrRef.current = regionLyr;
            }
            let countryLyr = view.map.layers.find(
              (layer) => layer.title === 'avistep_countries'
            );
            if (countryLyr) {
              countryLyrRef.current = countryLyr;
            }

            if (!loaded) {
              setMapViewLoaded(true);
            }
          });
        }
      } catch (err) {
        console.log('ERR', err);
        dispatch({
          payload: {
            error: err,
            msg: 'map failed to load',
          },
          type: 'MAP_ERROR',
        });
      }
    })();
    return () => {
      loaded = false;
    };
  }, []);

  useEffect(() => {
    mapStateRef.current = mapState;
  }, [mapState]);

  useEffect(() => {
    if (cellReportStatus == 'pending' && mapViewLoaded) {
      (async () => {
        if (
          mapStateRef.current.country != null &&
          mapStateRef.current.energy != null
        ) {
          // Show the grid layer
          let layertitle = `avistep_${mapStateRef.current.country.toLowerCase()}_${mapStateRef.current.energy.code.toLowerCase()}_grid`; // ie "avistep_ind_solar_grid"
          if (layertitle != gridlayerRef.current?.title) {
            if (gridlayerRef.current) {
              gridlayerRef.current.visible = false;
            }

            let gridLayer = mapViewRef.current.map.layers.find(
              (layer) => layer.title === layertitle
            );
            if (gridLayer) {
              // If sensitivity grid layer is present in the map, assign to layer reference and update vis
              gridlayerRef.current = gridLayer;
              gridLayer.popupEnabled = false;
              gridLayer.opacity = 0.6;
              gridLayer.visible = true;
            }
          }

          // Zoom to Selected Grid Cell
          let query = {
            where: `GRID_ID = ${cellData['GRID_ID']}`,
            outFields: ['*'],
            returnGeometry: true,
          };
          let results = await gridlayerRef.current.queryFeatures(query);
          if (results.features.length > 0) {
            let selectedCell = results.features[0].clone();
            selectedCell.symbol = {
              type: 'simple-fill',
              outline: { width: 2.3, color: [255, 255, 255, 1.5] },
              color: [0, 0, 0, 0],
            };
            mapViewRef.current.graphics.add(selectedCell);

            let latitude = results.features[0].geometry.centroid.latitude;
            let longitude = results.features[0].geometry.centroid.longitude;

            await mapViewRef.current
              .goTo({
                target: [longitude, latitude],
                zoom: 12,
              })
              .then(async () => {
                await generateMapImages([longitude, latitude]);
              })
              .catch((error) => {
                //if (error.name != "AbortError") {
                console.error(error);
                //}
              });
            console.log('SCALE CELL', mapViewRef.current.scale);
          }

          //landcover data
          let landcover = [];

          if (cellData.landcover_shrub && cellData.landcover_shrub > 0.05) {
            landcover.push({
              name: `${cellData.landcover_shrub.toFixed(1)}% Shrubs`,
              value: cellData.landcover_shrub,
              fill: '#fc8715',
            });
          }
          if (
            cellData.landcover_herbaceous &&
            cellData.landcover_herbaceous > 0.05
          ) {
            landcover.push({
              name: `${cellData.landcover_herbaceous.toFixed(1)}% Herbaceous`,
              value: cellData.landcover_herbaceous,
              fill: '#fdcb44',
            });
          }
          if (
            cellData.landcover_cultivated &&
            cellData.landcover_cultivated > 0.05
          ) {
            landcover.push({
              name: `${cellData.landcover_cultivated.toFixed(1)}% Agricultural`,
              value: cellData.landcover_cultivated,
              fill: '#f35a2b',
            });
          }
          if (cellData.landcover_urban && cellData.landcover_urban > 0.05) {
            landcover.push({
              name: `${cellData.landcover_urban.toFixed(1)}% Urban`,
              value: cellData.landcover_urban,
              fill: '#5d5452',
            });
          }
          if (cellData.landcover_bare && cellData.landcover_bare > 0.05) {
            landcover.push({
              name: `${cellData.landcover_bare.toFixed(1)}% Bare or Sand`,
              value: cellData.landcover_bare,
              fill: '#6d4d0d',
            });
          }
          if (cellData.landcover_snowice && cellData.landcover_snowice > 0.05) {
            landcover.push({
              name: `${cellData.landcover_snowice.toFixed(1)}% Snow and Ice`,
              value: cellData.landcover_snowice,
              fill: '#2c76d9',
            });
          }
          if (
            cellData.lc_permanent_waterbodies &&
            cellData.lc_permanent_waterbodies > 0.05
          ) {
            landcover.push({
              name: `${cellData.lc_permanent_waterbodies.toFixed(1)}% Water`,
              value: cellData.lc_permanent_waterbodies,
              fill: '#4e96c4',
            });
          }
          if (
            cellData.landcover_herbaceous_wetlands &&
            cellData.landcover_herbaceous_wetlands > 0.05
          ) {
            landcover.push({
              name: `${cellData.landcover_herbaceous_wetlands.toFixed(
                1
              )}% Wetlands`,
              value: cellData.landcover_herbaceous_wetlands,
              fill: '#0d6d28',
            });
          }
          if (
            cellData.landcover_moss_lichen &&
            cellData.landcover_moss_lichen > 0.05
          ) {
            landcover.push({
              name: `${cellData.landcover_moss_lichen.toFixed(1)}% Moss`,
              value: cellData.landcover_moss_lichen,
              fill: '#7897e1',
            });
          }
          if (cellData.landcover_forest && cellData.landcover_forest > 0.05) {
            landcover.push({
              name: `${cellData.landcover_forest.toFixed(1)}% Forest`,
              value: cellData.landcover_forest,
              fill: '#53bd60',
            });
          }
          setLandCoverData(landcover);
          setTimeout(() => {
            generateChartImage();
          }, 3000);
        }
      })();
    }
  }, [cellReportStatus, cellData, mapState, mapViewLoaded]);

  useEffect(() => {
    if (cellImage && regionImage && chartImage && cellData) {
      //dispatch action to set data for PDF Report
      let country =
        countries.find(
          (countryitem) => countryitem.code == mapStateRef.current.country
        ) || null;
      country = country ? country.label : '';
      let region = regions[mapStateRef.current.region] || null;

      let lat = Number(mapStateRef.current.lat);
      let long = Number(mapStateRef.current.long);
      let coordinates = `${formatLatitude(lat)}, ${formatLongitude(long)}`;

      const sortSpecies = (species) => {
        try {
          switch (mapStateRef.current.energy.code) {
            case 'OFFSHORE':
              return species.sort((a, b) =>
                a.sensitivity_offshore_collision <
                b.sensitivity_offshore_collision
                  ? 1
                  : -1
              );
            case 'ONSHORE':
              return species.sort((a, b) =>
                a.sensitivity_wind < b.sensitivity_wind ? 1 : -1
              );
            case 'SOLAR':
              return [];
            case 'PWDIST':
              return species.sort((a, b) =>
                a.sensitivity_pw_dis < b.sensitivity_pw_dis ? 1 : -1
              );
            case 'PWTRAN':
              return species.sort((a, b) =>
                a.sensitivity_pw_trans < b.sensitivity_pw_trans ? 1 : -1
              );
            default:
              return species;
          }
        } catch (e) {
          return species;
        }
      };

      //sensitivity score and rank data

      const getSensitivity = (data) => {
        switch (mapStateRef.current.energy.code) {
          case 'OFFSHORE':
            return {
              score: data?.wind_sensitivity || 0,
              rank: data?.wind_sensitivity_rank || 1,
            };
          case 'ONSHORE':
            return {
              score: data?.wind_sensitivity || 0,
              rank: data?.wind_sensitivity_rank || 1,
            };
          case 'SOLAR':
            return {
              score: data?.solar_sensitivity || 0,
              rank: data?.solar_sensitivity_rank || 1,
            };
          case 'PWDIST':
            return {
              score: data?.pw_distr_sensitivity || 0,
              rank: data?.pw_distr_sensitivity_rank || 1,
            };
          case 'PWTRAN':
            return {
              score: data?.pw_trans_sensitivity || 0,
              rank: data?.pw_trans_sensitivity_rank || 1,
            };
          default:
            return { score: 0, rank: 1 };
        }
      };
      let sensitivity = getSensitivity(cellData);

      let sites = {};
      let ibaSites = [];
      let protectedSites = [];
      if (mapStateRef.current.energy.code == 'OFFSHORE') {
        ibaSites = [...cellSites.iba_marinecoastal];
        protectedSites = [...cellSites.protected_marinecoastal];
      } else {
        ibaSites = [...cellSites.iba_terrestrial];
        protectedSites = [...cellSites.protected_terrestrial];
      }
      ibaSites.forEach((site) => {
        sites[site.IntName] = 'Important Bird Area';
      });
      protectedSites.forEach((site) => {
        sites[site.NAME] = 'Protected Area';
      });
      let allSites = Object.keys(sites).map((key, index) => {
        return { site: key, type: sites[key] };
      });

      let allSpecies = sortSpecies(cellSpecies);

      dispatch(
        setCellReport({
          country: country,
          region: region,
          energy: mapStateRef.current.energy,
          coordinates: coordinates,
          sensitivity_score: Math.round(sensitivity.score * 100),
          sensitivity_rank: sensitivity.rank,
          species: allSpecies,
          sites: allSites,
          landcover: [],
          region_map: regionImage,
          overview_map: cellImage,
          cell_map: cellImage,
          chartImage: chartImage,
        })
      );
    }
  }, [cellImage, regionImage, chartImage, cellData, cellSpecies, cellSites]);

  //width={700} height={600}

  // Map Container
  return (
    <Container>
      <Container
        id={mapContainer}
        ref={mapDiv}
        style={{ zIndex: 3 }}
      ></Container>
      <div
        style={{
          position: 'absolute',
          top: '5px',
          zIndex: 2,
          height: '95%',
          width: '95%',
          backgroundColor: 'white',
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <ResponsiveContainer id={chartContainer}>
          <PieChart id={chartContainer} width={800} height={650}>
            <Pie
              data={landcoverData}
              dataKey='value'
              outerRadius={270}
              innerRadius={250}
              fill='green'
              paddingAngle={5}
              label={({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                value,
                index,
              }) => {
                const RADIAN = Math.PI / 180;
                // eslint-disable-next-line
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                // eslint-disable-next-line
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                // eslint-disable-next-line
                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                return (
                  <text
                    x={x}
                    y={y}
                    fill='#000000'
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline='central'
                  >
                    {landcoverData[index].name}
                  </text>
                );
              }}
              nameKey='name'
              isAnimationActive={false}
              labelLine={true}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

export default CellMap;
