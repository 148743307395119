import { makeRequest, makeRequestRetry, fetchRetry } from '../utils/request'
const configurl = '/api/GetCred';
const tokenurl = '/api/GetToken';

export function getMapConfig() {

    return fetchRetry({
        url: `${configurl}`,
        strict: false,
        method: "POST",
        timeout: 15000}, 5
    ).then(resp => {
        return resp.json()
    });
}

export function getMapToken() {

    return fetchRetry({
        url: `${tokenurl}`,
        strict: false,
        method: "POST",
        timeout: 15000},
        5).then(resp => {
        return resp.json()
    });
}


export function formatLatitude(num) {
    let coordinate = Number(num) || 0;
    if (Math.sign(coordinate) == 1){
        return `${coordinate.toFixed(5)}N`
    }else if(Math.sign(coordinate) == -1){
        return `${(coordinate * -1).toFixed(5)}S`
    }else {
        return "- N"
    }
}

export function formatLongitude(num) {
    let coordinate = Number(num) || 0;
    if (Math.sign(coordinate) == 1){
        return `${coordinate.toFixed(5)}E`
    }else if(Math.sign(coordinate) == -1){
        return `${(coordinate * -1).toFixed(5)}W`
    }else {
        return "- E"
    }
}


export function buildAreaStats (features) {
    try {
        let stats = {"1": 0, "2": 0, "3": 0, "4": 0};
        let sensitivitystats = features.reduce((prevObj, feature) => ({ ...prevObj, [`${feature.attributes["sensitivity_rank"]}`]: prevObj[`${feature.attributes["sensitivity_rank"]}`] + feature.attributes["AreaKM"] }), stats);
        let totalarea = Object.values(sensitivitystats).reduce((a, b) => a + b);
        let finalstats = {"1": {"area": Math.round(sensitivitystats["1"]), "percentage": Math.round((sensitivitystats["1"] / totalarea) * 100)}, 
                        "2": {"area": Math.round(sensitivitystats["2"]), "percentage": Math.round((sensitivitystats["2"] / totalarea) * 100)}, 
                        "3": {"area": Math.round(sensitivitystats["3"]), "percentage": Math.round((sensitivitystats["3"] / totalarea) * 100)}, 
                        "4": {"area": Math.round(sensitivitystats["4"]), "percentage": Math.round((sensitivitystats["4"] / totalarea) * 100)}};
        return finalstats;
    } catch (e) {
        return      {"1": {"area": 0, "percentage": 0}, 
                    "2": {"area": 0, "percentage": 0}, 
                    "3": {"area": 0, "percentage": 0}, 
                    "4": {"area": 0, "percentage": 0}};
    }
}


export function checkAttributes (attr) {
    // GRID_ID: 78302
    // OBJECTID: 77963
    // landcover_bare: 0
    // landcover_cultivated: 98.59098229
    // landcover_forest: 0.040257649
    // landcover_herbaceous: 0.281803543
    // landcover_herbaceous_wetlands: 0
    // landcover_moss_lichen: 0
    // landcover_permanent_waterbod: 0
    // landcover_shrub: 0.040257649
    // landcover_snowice: 0
    // landcover_urban: 1.046698873
    // pw_distr_confidence: 1
    // pw_distr_sensitivity_rank: 4
    // pw_distr_sensitivity: 0.6730483770370483
    // pw_trans_confidence: 2.2461612224578857
    // pw_trans_sensitivity: 0.6035441160202026
    // pw_trans_sensitivity_rank: 3
    // solar_resource: 1940.4700927734375
    // solar_resource_suitable: 1
    // solar_sensitivity: 0.004000000189989805
    // solar_sensitivity_rank: 1
    // wind_confidence: 2
    // wind_resource: 4.725159645080566
    // wind_resource_suitable: 0
    // wind_sensitivity: 0.5615403056144714
    // wind_sensitivity_rank: 2
    return true;
}

const countryref = { "internal_boundary":       {"id": "", "type": "feature"},
                        "sensitivity_grid":        {"id": "", "type": "feature"},
                        "sensitivity_grid_vector": {"id": "", "type": "vector"},
                        "species": {
                                "wind":                      {"id": "", "type": "table"},
                                "power_distribution":        {"id": "", "type": "table"},
                                "power_transmission":        {"id": "", "type": "table"}
                        },
                        "regions": {
                                "wind":                          {"id": "", "type": "feature"},
                                "solar":                         {"id": "", "type": "feature"},
                                "power_distribution":            {"id": "", "type": "feature"},
                                "power_transmission":            {"id": "", "type": "feature"}
                    }

}
export function buildCountryLayers(items){
    let countries = ["IND","THA","VTN","NPL","KEN", "LAO", 'UZB', 'EGY'];
    let country_layers = {}
    countries.forEach(country => {
        let country_data = { ...countryref}
        let countryitems = items.filter(item => item.tags.includes(`country=${country}`));
        
        let internal_boundary = countryitems.find(item => item.tags.includes(`layertype=internalboundary`));
        if (internal_boundary){ country_data["internal_boundary"] = internal_boundary };

        //let sensivitygrids = countryitems.filter(item => item.tags.includes(`layertype=sensitivitygrid`));
        //if (sensivitygrids){ country_data["sensitivity_grid"] = sensivitygrids }

        //let species_wind = countryitems.filter(item => item.tags.includes(`layertype=species`) && item.tags.includes(`enery=wind`));
        //if (species_wind){ country_data["species"]["wind"] = species_wind }

        //let species_powerd = countryitems.filter(item => item.tags.includes(`layertype=species`) && item.tags.includes(`enery=power_distribution`));
        //if (species_powerd){ country_data["species"]["power_distribution"] = species_powerd }

        //let species_powert = countryitems.filter(item => item.tags.includes(`layertype=species`) && item.tags.includes(`enery=power_transmission`));
        //if (species_powert){ country_data["species"]["power_transmission"] = species_powert }

        //let regions_wind = countryitems.filter(item => item.tags.includes(`layertype=regions`) && item.tags.includes(`enery=wind`));
        //if (regions_wind){ country_data["regions"]["wind"] = regions_wind }

        //let regions_solar = countryitems.filter(item => item.tags.includes(`layertype=regions`) && item.tags.includes(`enery=solar`));
        //if (regions_solar){ country_data["regions"]["solar"] = regions_solar }

        //let regions_powert = countryitems.filter(item => item.tags.includes(`layertype=regions`) && item.tags.includes(`enery=power_transmission`));
        //if (regions_powert){ country_data["regions"]["power_transmission"] = regions_powert }

        //let regions_powerd = countryitems.filter(item => item.tags.includes(`layertype=regions`) && item.tags.includes(`enery=power_distribution`));
        //if (regions_powerd){ country_data["regions"]["power_distribution"] = regions_powerd }
        country_layers[country] = country_data;
    })
    
    return country_layers;
}

