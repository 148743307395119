import React, { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation, useNavigate  } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Menu from "./Menu";
import LeftHeader from "./LeftHeader";
import Breadcrumbs from "./Breadcrumbs";
import CountrySelection from "./CountrySelection";
import EnergySelection from "./EnergySelection";
import SensitivitySelection from "./SensitivitySelection";
import './LeftPanel.css';



const useStyles = makeStyles((theme) => {
    return {
        root: {
            zIndex: "3",
            width: "295px",
            minWidth: "220px",
            position: "absolute",
            top: "1vh",
            bottom: "2vh",
            left: "1vh",
            height: "97%",
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            borderRadius: "16px"
        }
    }
})

const LeftPanel = (props) => {
    const classes = useStyles();
    const mapStateRef = useRef({});
    let location = useLocation();
    const navigate = useNavigate ();
    const [menuVisible, setMenuVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [mode, setMode] = useState("country-select") // country-select , energy-select, sensitivity-select
    const mapState = useSelector(state => state.map.mapState);

    const toggleMenu = useCallback(() => {
        setMenuVisible(!menuVisible);
    });

    const toggleDropdown = useCallback((value) => {
        setDropdownVisible(value);
    });

    useEffect(() => {

    }, [])


    useEffect(() => {
        mapStateRef.current = mapState;
        if (mapState.country == null){
            setMode("country-select");
        } else if (mapState.country && !mapState.energy) {
            setMode("energy-select");
        } else if (mapState.country && mapState.energy){
            setMode("sensitivity-select");
        }

    }, [mapState])


    const selectCountry = (country) => {
        toggleDropdown(false);
        let newparam = {"country": country.code};
        if (mapStateRef.current.energy){
            newparam["energy"] = mapStateRef.current.energy.code;
        }
        const params = new URLSearchParams(newparam);
        props.mapView.graphics.removeAll()
        navigate({ pathname: location.pathname, search: params.toString(), replace: true });
    };

    const selectEnergy = useCallback((energy) => {
        let newparam =  {"country": mapStateRef.current.country, "energy":  energy.code};
        if (mapStateRef.current.region){newparam["region"] = mapStateRef.current.region} 
        if (mapStateRef.current.lat){
            newparam["lat"] = mapStateRef.current.lat
            newparam["long"] = mapStateRef.current.long
        } 
        const params = new URLSearchParams(newparam);
        navigate({ pathname: location.pathname, search: params.toString(), replace: true });
    },[mapState]);


    return (
        <div className="left-root">
            {menuVisible && <Menu closeEvent={toggleMenu}/>}
            {dropdownVisible && <div className="dropdown-backdrop" />}
            {!menuVisible && <div className="left-main" >
                <LeftHeader openMenu={toggleMenu} className="left-header" />
                {mode != "country-select" && <Breadcrumbs countrySelect={selectCountry} toggleDropdown={toggleDropdown}/>}
                <div className="left-content" >
                    { mode == "country-select" &&
                        <CountrySelection onClick={selectCountry} />
                    }
                    { mode == "energy-select" && 
                        <EnergySelection onClick={selectEnergy}/>                   
                    }
                    { mode == "sensitivity-select" && 
                        <SensitivitySelection />                   
                    }
                </div>
            </div>}
            
        </div>
    )

}

export default LeftPanel;

