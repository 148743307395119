import React from "react";
import image1 from '../../styles/images/about_img1.png';
import image2 from '../../styles/images/about_img2.png';

const General = () => {
  return (
    <div className="general">
      <div className="image-set-1">
        <div className="intro-pragraph">
          <h1>ABOUT AVISTEP</h1>
          <p className="about-p">
            Tackling the climate emergency requires a rapid, worldwide transition to renewable energy technologies. However, such a comprehensive reshaping of our global energy infrastructure will pose significant threats to biodiversity if not planned responsibly. To address this, BirdLife International has developed AVISTEP - the Avian Sensitivity Tool for Energy Planning to identify where renewable energy could impact birds and should therefore be avoided. Equipped with this information, those involved in planning renewable energy will be able to ensure that facilities are developed in the most appropriate locations.
          </p>
        </div>
        <div className="image-container">
          <img className="image-1" alt="Image 1" src={image1}/>
          <div className="image-caption">Photo Credit: Nick Upton </div>
        </div>
      </div>
      <p>
        Climate change is no longer a distant threat on the horizon, but an unfolding catastrophe. One critical action is the rapid transition from fossil fuels to renewable sources of energy. The coming decades will see millions of square kilometres across the globe earmarked for renewable energy development. This, in turn, will also necessitate an almost doubly of global power line infrastructure.
      </p>
      <p>
        As vital as the clean energy transition is, it is important to remember that concurrent to the climate crisis, we are facing an equally perilous biodiversity crisis. Indeed, these are deeply interlinked emergencies that must be addressed in unison. Poorly sited renewable energy facilities can have a considerable impact on wildlife. A recent study concluded that if renewable energy developments were sited solely to maximize energy production, then millions of hectares of natural habitat could be lost globally and the ranges of many hundreds of threatened species could be impacted. Perversely, this loss of natural habitat would result in the release of almost 415 million tons of stored carbon, massively undermining the climate change benefits associated with a transition to renewable energy<span className="super-script">1</span>.
      </p>
      <div className="foot-note">
        <span className="super-script">1</span> Kiesecker, J., Baruch-Mordo, S., Kennedy, C. M., Oakleaf, J. R., Baccini, A. and Griscom, B. W. (2019) Hitting the Target but Missing the Mark: Unintended Environmental Consequences of the Paris Climate Agreement. Front. Environ. Sci. 7:151.doi: 10.3389/fenvs.2019.00151
      </div>
      <div className="image-set-2">
        <div className="image-container">
          <img className="image-2" alt="Image 2" src={image2}/>
          <div className="image-caption">Photo Credit: Jaap Schelvis</div>
        </div>
      </div>
      <div>
        <p className="subsection-header">“RENEWABLE ENERGY IS NOT TRULY 'GREEN' UNLESS 
        EFFORTS HAVE BEEN MADE TO LIMIT NEGATIVE REPERCUSSIONS FOR BIODIVERSITY.”</p>
        <p>
          One of the wildlife groups most directly impacted by renewable energy expansion is birds. Not only can inappropriately sited developments destroy important bird habitat, birds can also be impacted through collision with energy infrastructure such as power lines and turbine blades and through displacement from their favoured habitat, key flight paths and migration routes. At its most serious, poorly planned renewable energy could even contribute to global extinctions. For example, the deserts of western Rajasthan in India have experienced rapid growth of wind and solar energy. This has resulted in hundreds of kilometres of new power lines. Yet, this region also contains some of the last, dwindling populations of Great Indian Bustard—an iconic species and the planet’s heaviest flying bird. Sadly, collision with power lines is now the principal cause of mortality for the Great Indian Bustard. 
        </p>
        <p>
          Fortunately, wind and solar radiation are widespread resources, and there is considerable scope to choose locations for development where the impact on birds and other wildlife will be minimal. With careful, strategic and proactive planning, it is possible to meet renewable energy targets without adversely affecting wildlife. To inform such planning, AVISTEP provides a detailed spatial assessment of avian sensitivity in relation to different types of energy infrastructure — wind (both on- and offshore), photovoltaic (PV) solar, and overhead power lines (transition and distribution).
        </p>
      </div>
      <div className="group-set-3">
        <div className="relative">
          <a href="/AVISTEPBrochure.pdf" target="_blank"><img className="avistep-borchure" alt="AviStep Brochure" src="/AVISTEPBrochure.png"></img></a>
          <p className="avistep-brochure-label">AVISTEP Promotional Brochure</p>
        </div>
        <div>
          <iframe src="https://player.vimeo.com/video/733569195?h=ebb63dd585&amp;dnt=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" ></iframe>
          <p className="avistep-brochure-label">AVISTEP Promotional Video</p>
        </div>
      </div>
    </div>
  );
};

export default General;