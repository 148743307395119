import React, { useRef, useEffect, useState } from "react";
import './Legal.css';

function Terms() {

  return (
    <div className="terms">
      <h1>TERMS OF USE</h1>
      <p className="sub-title">Please read these terms of use carefully before using this service.</p>
      <h3>What is in these terms?</h3>
      <p>
      These terms tell you the rules for using the spatial planning tool AVISTEP.birdlife.org (our "Service").
      </p>
      <h3>Who we are &amp; how to contact us</h3>
      <p>
        AVISTEP is a spatial planning tool to support bird-safe renewable energy expansion operated by BirdLife International ("We"), a company limited by guarantee (company number 2985746) and a registered charity in England and Wales (charity number 1042125) whose registered office is The David Attenborough Building, Pembroke Street, Cambridge CB2 3QZ. Our VAT number is GB 311 5379 26.
      </p>
      <p>
        To contact us, please email <a href="mailto:avistep@birdlife.org">avistep@birdlife.org</a>.
      </p>
      <h3>By using our Service, you accept these terms</h3>
      <p>
        By using our Service, you confirm that you accept these terms of use and that you agree to comply with them.
      </p>
      <p>
        If you do not agree to these terms, you must not use this Service.
      </p>
      <p>
        We recommend that you print a copy of these terms for future reference.
      </p>
      <h3>There are other terms that may apply to you</h3>
      <p>
        These terms of use refer to the following additional terms, which also apply to your use of our Service (collectively, the ‘’Terms’’):
      </p>
      <ul>
        <li>Our Privacy Policy <a target="#" href="https://www.birdlife.org/privacy-policy/">https://www.birdlife.org/privacy-policy/</a></li>
        <li>Our Cookie Settings <a target="#" href="https://www.birdlife.org/cookie-settings/">https://www.birdlife.org/cookie-settings/</a>, which sets out information about the cookies on our platform.</li>
      </ul>
      <h3>We may make changes to these terms</h3>
      <p>
        We amend these terms from time to time. Every time you wish to use our Service, please check these terms to ensure you understand the terms that apply at that time. 
      </p>
      <p>
        These terms were most recently updated on July 20th, 2022.
      </p>
      <h3>We may make changes to our Service</h3>
      <p>
        We may update and change our Service from time to time to reflect changes to our product, our users' needs and our priorities. 
      </p>
      <h3>We may suspend or withdraw our Service</h3>
      <p>
      Our Service is made available free of charge.
      </p>
      <p>
        We do not guarantee that our Service, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Service for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
      </p>
      <p>
        You are also responsible for ensuring that all persons who access our Service through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
      </p>
      <h3>How you may use our Service</h3>
      <p>
        The Service is designed for governments, businesses and organisations to help them avoid areas of high avian sensitivity when planning the location of renewable energy infrastructure and powerlines.
      </p>
      <p>
        We are the owner or the licensee of all intellectual property rights in our Service, and in the material accessible through it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
      </p>
      <p>
        You may print off copies, and may download extracts, of any page(s) from our Service for your internal use and you may draw the attention of others within your organisation to content available through our Service.
      </p>
      <p>
        You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
      </p>
      <p>
        You also agree not to adapt, alter or create derivative work from any content available through the Service except for your own internal, non-commercial use. 
      </p>
      <p>
        Our status (and that of any identified contributors) as the authors of content on our Service must always be acknowledged.
      </p>
      <p>
        You must not use any part of the content on our Service for commercial purposes (other than as necessary for the purpose of assessing the risk to birds and the environment in the course of business).
      </p>
      <p>
        If you print off, copy, download, share or repost any part of our website or Service in breach of these terms of use, your right to use our Service will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
      </p>
      <p>
        If you wish to use our Service in any other way, please contact us to seek permission. (Please note that we are generally happy to provide use of material in instances where the conservation of birds or the work of our organisation is helped or promoted.)
      </p>
      <p>
        We reserve any rights not expressly granted.
      </p>
      <h3>Prohibited Uses</h3>
      <p>
        You may not use our Service and website:
      </p>
      <ul>
        <li>In any way that breaches any applicable local, national or international law or regulation, including copyright or trademark laws, export control or sanctions laws, as applicable.</li>
        <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
        <li>For the purpose of harming or attempting to harm minors in any way.</li>
        <li>To bully, insult, intimidate or humiliate any person.</li>
        <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
        <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
        <li>To upload terrorist content.</li>
      </ul>
      <p>
        You also agree:
      </p>
      <ul>
        <li>Not to reproduce, duplicate, copy or re-sell any part of our website or the Service in contravention of the provisions of these Terms.</li>
        <li>Not to access without authority, interfere with, damage or disrupt:</li>
        <li className="no-bullet">
          <ul>
            <li>any part of our website or the Service;</li>
            <li>any equipment or network on which our website and the Service are stored; </li>
            <li>any software used in the provision of our Service or our website; or </li>
            <li>any equipment or network or software owned or used by any third party.</li>
          </ul>
        </li>
      </ul>
      <p>
        You may not use our Service and website in any way which may result in a negative effect on our reputation. Activities considered to be prejudicial to BirdLife International include, without limitation: 
      </p>
      <ul>
        <li>the expression of any political views in a context that could give rise to an association with the work undertaken by BirdLife International; </li>
        <li>the involvement or other association with illegal drugs, modern slavery and human trafficking, terrorism and organised crime, act or war or violence.</li>
      </ul>
      <h3>No text or data mining, or web scraping</h3>
      <p>
        You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our Service or website. This includes using (or permitting, authorising or attempting the use of):
      </p>
      <ul>
        <li>Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the website, the Service or any data, content, information accessed via the same.</li>
        <li>Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</li>
      </ul>
      <p>
        The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive ((EU) 2019/790).
      </p>
      <p>
        This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.
      </p>
      <h3>Do not rely on information available through the Service</h3>
      <p>
        The content on our Service is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our website and accessible though the Service.
      </p>
      <p>
        The presentation of material on this website and the geographical designations employed do not imply the expression of any opinion whatsoever on the part of BirdLife international concerning the legal status of any country, territory or area, or concerning the delimitation of its frontiers or boundaries.
      </p>
      <p>
        Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.
      </p>
      <h3>We are not responsible for websites we link to</h3>
      <p>
        Where our Service or website contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.
      </p>
      <p>
        We have no control over the contents of those websites or resources.
      </p>
      <h3>Our responsibility for loss or damage suffered by you</h3>
      <ul>
        <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so under the applicable law. This includes liability for death or personal injury caused by our negligence and for fraud or fraudulent misrepresentation.</li>
        <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</li>
        <li>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</li>
        <li className="no-bullet">
          <ul>
            <li>use of, or inability to use, our website and/or the Service; or</li>
            <li>use of or reliance on any content displayed on our website and/or the Service.</li>
          </ul>
        </li>
      </ul>
      <p></p>
      <ul>
        <li>In particular, we will not be liable for:</li>
        <li className="no-bullet">
          <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
        </li>
      </ul>
      <h3>How we may use your personal information</h3>
      <p>
        We will only use your personal information as set out in our <a target="#" href="https://www.birdlife.org/privacy-policy/">Privacy Policy</a>.
      </p>
      <h3>We are not responsible for viruses and you must not introduce them</h3>
      <p>
        We do not guarantee that our website and Service will be secure or free from bugs or viruses.
      </p>
      <p>
        You are responsible for configuring your information technology, computer programmes and platform to access our Service. You should use your own virus protection software.
      </p>
      <p>
        You must not misuse our Service by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Service, the server on which our Service is stored or any server, computer or database connected to our Service. You must not attack our Service or website via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Service will cease immediately.
      </p>
      <h3>Rules about linking to our Service</h3>
      <p>
        You may link to our home page as long as appropriate credit is given in the link title and provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.
      </p>
      <p>
        You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
      </p>
      <p>
        You may not use the BirdLife International logo to link to our site or in other ways represent BirdLife International on other websites without our written consent. The only exceptions to this rule are for associated BirdLife Partners and Affiliates.
      </p>
      <p>
        You must not establish a link to our home page in any website that is not owned by you.
      </p>
      <p>
        Our Service must not be framed on any other website, nor may you create a link to any part of our website, Service or our images or other downloadable other than the home page.
      </p>
      <p>
        We reserve the right to withdraw linking permission without notice.
      </p>
      <p>
        The website to which you are linking must comply in all respects with the content set out in our Prohibited Uses.
      </p>
      <p>
        If you wish to link to or make any use of content available through our Service other than that set out above, please contact <a href="mailto:avistep@birdlife.org">avistep@birdlife.org</a>.
      </p>
      <h3>Indemnification</h3>
      <p>
      You agree to indemnify, defend and hold us, our employees, directors, officers, agents, licensors and suppliers to the Service harmless from and against all claims, losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any infringement of these Terms by you.’’
      </p>
      <h3>Third party terms</h3>
      <p>
        While these Terms represent our entire agreement with you, please note that other parties' terms might also govern your use of the Service. This is because our Service might be integrated with third party applications.
      </p>
      <p>
        AVISTEP uses Esri's GIS mapping software.
      </p>
      <h3>Which country's laws apply to any disputes?</h3>
      <p>
        These terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. You agree to the exclusive jurisdiction of the courts of England and Wales.
      </p>
      <h3>What happens if any term becomes unenforceable?</h3>
      <p>
        If any term of this agreement is or becomes invalid, illegal or unenforceable, in whole or in part, under the applicable law, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of the Terms under the applicable law. 
      </p>
      <h3>Waiver</h3>
      <p>
        If you breach these Terms, our delay or failure to exercise, or the single or partial exercise of, any right or remedy shall not waive that or any other right or remedy that we may be entitled to, nor shall it prevent or restrict the further exercise of that or any other right or remedy.
      </p>
      <h3>Events beyond our control</h3>
      <p>
        We will not be liable for any failure or delay in the performance of our obligations if such delay or failure results from events, circumstances or causes beyond our reasonable control. 
      </p>
      <h3>Survival</h3>
      <p>
        Any terms which, by their nature are intended to survive termination or expiration of these Terms, will survive any such termination or expiration, including without limitation the provisions in 
        <i>How you may use our Service , Do not rely on information available through the Service and Our responsibility for loss or damage suffered by you.</i>
      </p>
      <h3>Our trade marks are registered</h3>
      <p>
        Our logo is a registered trade mark of BirdLife International. You are not permitted to use it without our approval, unless it is part of material you are using as permitted under <i>How you may use our Service.</i>
      </p>
      <div className="disclaimer">
        <h3 className="disc-heading">Disclaimer</h3>
        <p>
        The geographical designations employed on this website and on the maps that are made available through it do not imply the expression of any opinion whatsoever on the part of BirdLife International, or contributory organisations, concerning the legal status of any country, territory or area, or concerning the delimitation of its frontiers or boundaries.
        </p>
      </div>
    </div>
  );
}



export default Terms;
