
// ACTION TYPES //
export const types = {
    MAP_LOADED: "MAP_LOADED",
    MAP_ERROR: "MAP_ERROR",
    ZOOM_RECORD: "ZOOM_RECORD",
    CHECK_MAP_STATE: "CHECK_MAP_STATE",
    SET_MAP_STATE: "SET_MAP_STATE",
    SET_BASEMAPS: "SET_BASEMAPS",
    SET_ENERGY_OPTIONS: "SET_ENERGY_OPTIONS",
    SET_TRANSPARENCY: "SET_TRANSPARENCY",
    SET_SUITABLE_FILTER: "SET_SUITABLE_FILTER",
    SET_SENSITIVITY_RANKS: "SET_SENSITIVITY_RANKS",
    SET_RANK_AREAS: "SET_RANK_AREAS",
    SELECT_GRID_CELL: "SELECT_GRID_CELL",
    SET_SPECIES_DATA: "SET_SPECIES_DATA",
    LOAD_SPECIES_DATA: "LOAD_SPECIES_DATA",
    LOAD_SITES_DATA: "LOAD_SITES_DATA",
    SET_SITES_DATA: "SET_SITES_DATA",
    COMPLETE_SITES_DATA: "COMPLETE_SITES_DATA",
    CLEAR_GRID_CELL: "CLEAR_GRID_CELL",
    CLEAR_MAP_STATE: "CLEAR_MAP_STATE",
    START_COUNTRY_REPORT: "START_COUNTRY_REPORT",
    SET_COUNTRY_REPORT: "SET_COUNTRY_REPORT",
    START_CELL_REPORT: "START_CELL_REPORT",
    SET_CELL_REPORT: "SET_CELL_REPORT",
    CLEAR_REPORT: "CLEAR_REPORT",

    SET_COUNTRY_MAPFILTER: "SET_COUNTRY_MAPFILTER",
    SET_REGION_MAPFILTER: "SET_REGION_MAPFILTER",
    CLEAR_COUNTRY_MAPFILTER: "CLEAR_COUNTRY_MAPFILTER",
    CLEAR_REGION_MAPFILTER: "CLEAR_REGION_MAPFILTER"
};

export const initMapState = {
    "country": null, "region": null, "energy": null, "sensitivity_ranks": {"1":true,"2":true,"3":true,"4":true}, "cell": null, "lat": null, "long": null
}


// REDUCERS //
export const initialState = {
    loaded: false,
    mapState: {...initMapState},
    rankAreas: { "1": {"area": 0, "percentage": 0}, 
                 "2": {"area": 0, "percentage": 0}, 
                 "3": {"area": 0, "percentage": 0}, 
                 "4": {"area": 0, "percentage": 0}},
    basemaps: [],
    transparency: 100,
    suitable: false,
    sensitivity_ranks: {"1":true,"2":true,"3":true,"4":true},
    countryEnergyOptions: [],
    map: null,
    error: "",
    msg: "",
    cellSelected: false,
    cellData: {},
    cellSpecies: [],
    cellSites: {},
    loadingSpeciesData: false,
    loadingSitesData: false,
    showCountryReport: false,
    showCellReport: false,
    countryReportStatus: "new",
    cellReportStatus: "new",
    countryReportData: null,
    cellReportData: null,
    countryFilter: null,
    regionFilter: null
};


export default (state = initialState, action) => {
    switch (action.type) {
        case types.MAP_LOADED:
            return {
                ...state,
                loaded: true
            };
        case types.MAP_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case types.SET_MAP_STATE:
            return {
                ...state,
                mapState: {
                    ...state.mapState,
                    ...action.payload
                }
            };
        case types.SET_BASEMAPS:
            return {
                ...state,
                basemaps: action.payload.basemaps
            };
        case types.SET_ENERGY_OPTIONS:
            return {
                ...state,
                countryEnergyOptions: action.payload.countryEnergyOptions
            };
        case types.SET_TRANSPARENCY:
            return {
                ...state,
                transparency: action.payload.transparency
            }
        case types.SET_SUITABLE_FILTER:
            return {
                ...state,
                suitable: action.payload.suitable
            };
        case types.SET_SENSITIVITY_RANKS:
            return {
                ...state,
                sensitivity_ranks: {
                    ...state.sensitivity_ranks,
                    ...action.payload
                }
            };
        case types.SET_RANK_AREAS:
            return {
                ...state,
                rankAreas: {
                    ...action.payload
                }
            };
        case types.SELECT_GRID_CELL:
            return {
                ...state,
                cellSelected: true,
                cellData: {
                    ...state.cellData,
                    ...action.payload.cellData
                },
                loadingSpeciesData: true,
                loadingSitesData: true
            };
        case types.SET_SPECIES_DATA:
            return {
                ...state,
                cellSpecies: [
                    ...action.payload.species
                ],
                loadingSpeciesData: false
            };
        case types.SET_SITES_DATA:
            return {
                ...state,
                cellSites: {
                    ...state.cellSites,
                    [action.payload.sitetype]: action.payload.sitedata
                }
            };
        case types.COMPLETE_SITES_DATA:
            return {
                ...state,
                loadingSitesData: false
            };
        case types.CLEAR_GRID_CELL:
            return {
                ...state,
                cellSelected: false,
                cellData: {},
                cellSpecies: [],
                cellSites: {},
                loadingSpeciesData: false,
                loadingSitesData: false
            };
        case types.CLEAR_MAP_STATE:
            return {
                ...state,
                mapState: {
                    ...state.mapState,
                    ...initMapState
                },
                transparency: 100,
                suitable: false,
                sensitivity_ranks: {"1":true,"2":true,"3":true,"4":true},
                cellSelected: false,
                cellData: {},
                cellSpecies: [],
                cellSites: {},
                loadingSpeciesData: false,
                loadingSitesData: false,
                showCountryReport: false,
                showCellReport: false,
                countryReportStatus: "new",
                cellReportStatus: "new",
                countryReportData: null,
                cellReportData: null,
            };
        case types.START_COUNTRY_REPORT:
            return {
                ...state,
                showCountryReport: true,
                showCellReport: false,
                countryReportStatus: "pending",
                cellReportStatus: "new",
                countryReportData: null,
                cellReportData: null,
            };
        case types.SET_COUNTRY_REPORT:
            return {
                ...state,
                showCountryReport: true,
                countryReportStatus: "complete",
                countryReportData: {
                    ...action.payload
                },
                cellReportData: null,
            };
        case types.START_CELL_REPORT:
            return {
                ...state,
                showCountryReport: false,
                showCellReport: true,
                countryReportStatus: "new",
                cellReportStatus: "pending",
                countryReportData: null,
                cellReportData: null,
            };
        case types.SET_CELL_REPORT:
            return {
                ...state,
                showCellReport: true,
                cellReportStatus: "complete",
                cellReportData: {
                    ...action.payload
                },
                countryReportData: null,
            };
        case types.CLEAR_REPORT:
            return {
                ...state,
                showCountryReport: false,
                showCellReport: false,
                countryReportStatus: "new",
                cellReportStatus: "new",
                countryReportData: null,
                cellReportData: null,
            };

        case types.SET_COUNTRY_MAPFILTER:
            return {
                ...state,
                countryFilter: action.payload.country,
                regionFilter: null
            };
        case types.SET_REGION_MAPFILTER:
            return {
                ...state,
                regionFilter: action.payload.region
            };
        case types.CLEAR_COUNTRY_MAPFILTER:
            return {
                ...state,
                countryFilter: null
            };
        case types.CLEAR_REGION_MAPFILTER:
            return {
                ...state,
                regionFilter: null
            };
        
        default:
            return state;
    }
};

// ACTIONS //
export const mapLoaded = () => ({ type: types.MAP_LOADED, payload: {} });
export const updateMapState = (payload) => ({ type: types.CHECK_MAP_STATE, payload});
export const setLayerTransparency = (transparency) => ({ type: types.SET_TRANSPARENCY, payload: { transparency } });
export const setSuitableFilter = (suitable) => ({ type: types.SET_SUITABLE_FILTER, payload: { suitable } });
export const setSensitivityRanks = (ranks) => ({ type: types.SET_SENSITIVITY_RANKS, payload: ranks  });
export const setRankAreas = (areas) => ({ type: types.SET_RANK_AREAS, payload: areas  });
export const selectGridCell = (payload) => ({ type: types.SELECT_GRID_CELL, payload});
export const setSpecies = (payload) => ({ type: types.LOAD_SPECIES_DATA, payload});
export const loadSites = (payload) => ({ type: types.LOAD_SITES_DATA, payload});
export const clearGridCell = () => ({ type: types.CLEAR_GRID_CELL, payload: {} });
export const viewCellReport = () => ({ type: types.SHOW_CELL_REPORT });
export const startCountryReport = (payload) => ({ type: types.START_COUNTRY_REPORT, payload });
export const setCountryReport = (payload) => ({ type: types.SET_COUNTRY_REPORT, payload });
export const startCellReport = (payload) => ({ type: types.START_CELL_REPORT, payload });
export const setCellReport = (payload) => ({ type: types.SET_CELL_REPORT, payload });
export const clearReports = () => ({ type: types.CLEAR_REPORT });
export const clearMap = () => ({ type: types.CLEAR_MAP_STATE });

export const filterCountry = (country) => ({ type: types.SET_COUNTRY_MAPFILTER, payload: { country } });
export const filterRegion = (region) => ({ type: types.SET_REGION_MAPFILTER, payload: { region } });
export const clearCountry = () => ({ type: types.CLEAR_COUNTRY_MAPFILTER });
export const clearRegion = () => ({ type: types.CLEAR_REGION_MAPFILTER });


