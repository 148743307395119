import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((props) => {
    return {
        
        basemap: {
            width: "3rem",
            height: "3rem",
            float: "left",
            textAlign: "center",
            marginLeft: props => props.expanded
                ? '4px'
                : '0px',
            '&:last-child': {
                marginRight: props => props.expanded
                ? '4px'
                : '0px',
            },
            border: "1px solid rgba(161, 187, 247, 0.5)",
            borderRadius: ".3rem",
            '&:hover': {
                borderColor: "#003ECC",
            },
            "-webkit-transition": "display 3s ease-in-out",
            "-moz-transition": "display 3s ease-in-out",
            "-o-transition": "display 3s ease-in-out",
            "transition": "display 3s ease-in-out"
        },
        thumb: {
            height: "100%",
            borderRadius: ".3rem",
        }


    }
})

const BaseMapItem = (props) => {
    const classes = useStyles(props);
    const [show, setShow] = useState(props.basemap.title == props.current.title);

    useEffect(() => {
        let visible = (props.expanded == true || props.basemap.title == props.current.title) ? true : false;
        setShow(visible)
    }, [props]);


    return <div className={classes.basemap} title={props.basemap.title} onClick={props.onClick} style={{ display: show ? 'block' : 'none',backgroundImage: `url(${props.basemap.thumbnailUrl})`}} >
                
            </div>
        
        
}

export default BaseMapItem;
