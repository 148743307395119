import React, { useRef, useEffect, useState } from "react";
import './Legal.css';

function Cookies() {

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.id = "CookieDeclaration"
    scriptTag.src = "https://consent.cookiebot.com/86698107-17ff-4125-a3fd-71d16547341c/cd.js";
    scriptTag.type = "text/javascript";
    scriptTag.async = true;

    let cookieContainer = document.getElementById("cookieDiv");  
    cookieContainer.appendChild(scriptTag);
    return () => {
      cookieContainer.removeChild(scriptTag);
    }
}, []);

  return (
    <div id="cookieDiv" className="cookies">
      <h1>COOKIE SETTINGS</h1>
    </div>
  );
}



export default Cookies;
