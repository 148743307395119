import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { IconSource } from "../../../../utils/imagesource";
import { startCountryReport, startCellReport } from "../../../../redux/reducers/map";
import { setRightPanelState } from "../../../../redux/reducers/config";

const useStyles = makeStyles((props) => {
    return {
        actionsmenu: {
            position: "absolute",
            top: "1rem",
            right: props => `${props.right}`,
            zIndex: "4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.50rem"
        },
        share: {
            position: "relative",
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            height: '1.5rem',
            width: '1.5rem',
            borderRadius: '0.75rem',
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
        },
        print: {
            position: "relative",
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            height: '1.5rem',
            width: '1.5rem',
            borderRadius: '0.75rem',
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
        },
        img: {
            height: "0.7rem",
            marginTop: "auto",
            marginBottom: "auto",
            position: "absolute",
            top: "0",
            bottom: "0",
            margin: "auto",
            left: "0",
            right: "0",
        },
        collapse_img: {
            height: "0.6rem",
            marginTop: "auto",
            marginBottom: "auto",
            position: "absolute",
            top: "0",
            bottom: "0",
            margin: "auto",
            left: "0",
            right: "0",
        },
        expand_img: {
            height: "0.6rem",
            marginTop: "auto",
            marginBottom: "auto",
            position: "absolute",
            top: "0",
            bottom: "0",
            margin: "auto",
            left: "0",
            right: "0",
            transform: "scaleX(-1)"
        },
        collapse: {
            position: "relative",
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            height: '1.5rem',
            width: '1.5rem',
            borderRadius: '0.75rem',
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
        },

    }
})

const MapActions = (props) => {
    const mapStateRef = useRef({});
    const dispatch = useDispatch();
    const [right, setRightPosition] = useState('2rem');
    const [showPrint, setShowPrint] = useState(false);
    
    const [printMode, setPrintMode] = useState(false);
    const classes = useStyles({ right });

    const mapState = useSelector(state => state.map.mapState);
    const cellSelected = useSelector(state => state.map.cellSelected);
    const rightPanelMinimized = useSelector(state => state.config.rightPanelMinimized);
    const [expanded, setExpanded] = useState(!rightPanelMinimized);
    const [cellMode, setCellMode] = useState(false);

    const shareMap = () => {
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        document.getElementById('shareIconPopup').classList.add('showShareIconPopup');
        const timeId = setTimeout(() => {
            // After 3 seconds hide the pop-up
            document.getElementById('shareIconPopup').classList.remove('showShareIconPopup');
          }, 3000)
    };

    const printMap = useCallback(
        () => generateReport(printMode),
        [printMode]
    );

    const generateReport = (printmode) => {
        if (printmode == 'country'){
            dispatch(startCountryReport({}));
        } else if (printmode == 'cell'){
            dispatch(startCellReport({}));
        }
    };

    const collapseRightPanel = () => {
        dispatch(setRightPanelState(true));
    };

    const expandRightPanel = () => {
        dispatch(setRightPanelState(false));
    };

    useEffect(() => {
        setCellMode(cellSelected);
        if (cellSelected) {
            setRightPosition("1.5rem");
        } else {
            setRightPosition("1.5rem");
        }
    }, [cellSelected])

    useEffect(() => {
        mapStateRef.current = mapState;
        if (mapState.country && mapState.energy != null){
            setShowPrint(true);
            if (cellSelected){
                setPrintMode("cell");
            } else {
                setPrintMode("country");
            }
        } else{
            setShowPrint(false);
            setPrintMode(false);
        }
        setExpanded(cellSelected && !rightPanelMinimized);
    }, [mapState, cellSelected, rightPanelMinimized]);

    return <div className={classes.actionsmenu}>
                <div title="Share" className={classes.share} onClick={shareMap}><img className={classes.img} alt="Share" src={IconSource['SHARE']} /></div>
                <div id="shareIconPopup" className="shareIconPopup">Copied to Clipboard</div>
                {showPrint && <div title="Print" className={classes.print} onClick={printMap}><img className={classes.img} alt="Print" src={IconSource['PRINT']} /></div>}
                {!expanded && cellMode && <div title="Expand" className={classes.collapse} onClick={expandRightPanel}><img className={classes.expand_img} alt="Expand" src={IconSource['COLLAPSE']} /></div>}
                {expanded && cellMode && <div title="Collapse" className={classes.collapse} onClick={collapseRightPanel}><img className={classes.collapse_img} alt="Collapse" src={IconSource['COLLAPSE']} /></div>}
            </div>
}

export default MapActions;
