import React, { useRef, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import HindMaduraiBold from "../../../styles/fonts/HindMadurai-Bold.ttf";
import WorkSans from "../../../styles/fonts/WorkSans-Regular.ttf";
import { ImageReference, IconSource, BirdSpeciesImageSource } from "../../../utils/imagesource";


Font.register({
  family: 'HindMaduraiBold',
  format: "truetype",
  src: HindMaduraiBold
});
Font.register({
  family: 'Work Sans',
  format: "truetype",
  src: WorkSans
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    height:'100%',
    paddingBottom: '75pt'
  },
  pageview: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  headerfix:{
    height: '2.10in',
    backgroundColor: '#102646',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '25pt'
  },
  header1: {
    height:'2.10in',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    paddingTop: '15pt',
    paddingBottom: '15pt',
    paddingLeft: '20pt',
    gap: '10pt'
  },
  header2: {
    height:'2.10in',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15pt',
    paddingBottom: '15pt',
    paddingRight: '20pt',
    gap: '10pt'
  },
  avistep: {
    fontFamily: 'HindMaduraiBold',
    fontWeight: 700,
    fontSize: '14pt',
    paddingRight: '5pt'
  },
  logo: {
    width: '42pt',
    height: '34pt',
    marginRight: '1pt',
    marginLeft: 'auto'
  },
  pinicon: {
    height: '8pt',
    paddingRight: '5pt'
  },
  titlesection: {
    paddingTop: '10pt',
    marginLeft: '20pt',
    marginRight: '20pt',
    height: '50pt',
    paddingBottom: '5pt'
  },
  mapsection: {
    margin: '15pt',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20pt',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500pt',
    position: 'relative'
  },
  mapview: {
    width: '100%',
    paddingBottom: '10pt',
    position: 'relative',
    zIndex: '1',
  },
  mapimage: {  
    width: '90%',
    height: 'auto',
    display: 'block',
    position: 'relative',
    zIndex: '1',
    paddingBottom: '2pt',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  lcsection: {
    marginTop: '2pt',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5pt',
    justifyItems: 'start',
    alignItems: 'center',
  },
  lcimage: {
    width: 'auto',
    height: '95%',
    display: 'block'
  },
  mainsection: {
    margin: '15pt',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '20pt',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'green',
  },
  sitessection: {
    paddingLeft: '20pt',
    paddingRight: '20pt',
    paddingBottom: '100pt' 
  },
  speciessection: {
    paddingLeft: '20pt',
    paddingRight: '20pt',
  },
  sectiontitle: { 
    fontSize: '16pt', 
    fontFamily:'Work Sans', 
    fontWeight: 400, 
    color: 'black',
  },
  lctitle: { 
    fontSize: '16pt', 
    fontFamily:'Work Sans', 
    fontWeight: 400, 
    color: 'black',
    paddingBottom: '5pt'
  },
  sitesheader: {
    width: '100%',
    height: '30pt',
    flexDirection: 'row',
    marginTop: '20pt'
  },
  speciesheader: {
    width: '100%',
    height: '30pt',
    flexDirection: 'row',
    marginTop: '20pt'
  },
  sitesrow: {
    width: '100%',
    height: '30pt',
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '0.75pt',
    borderTopColor: 'rgba(0, 0, 0, .1)',
    borderTopStyle: 'solid',
  },
  speciesrow: {
    width: '100%',
    height: '1in',
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '0.75pt',
    borderTopColor: 'rgba(0, 0, 0, .1)',
    borderTopStyle: 'solid',
  },
  footer: {
    height: '10%'
  },
  pagenum: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    paddingBottom: '10pt',
    textAlign: 'center',
    height: '50pt',
    marginTop: '25pt',
    fontSize: '9pt', 
    fontFamily:'Work Sans', 
    fontWeight: 400
  },
});


let logoref = ImageReference['LOGO_TRANSPARENT'] || null;

const sensitivityDescriptions = {
  1: ['Development is considered to pose a ','low', ' risk to bird populations. However, comprehensive site-level assessment is necessary to confirm the absence of significant risk.'],
  2: ['Development is considered to pose a ','moderate',' risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk.'],
  3: ['Development is considered to pose a ','high',' risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk.'],
  4: ['Development is considered to pose a ','very high',' risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk.'],
}

// Create Document Component
const CellReport = (props) => {

  const score = `Sensitivity Score: ${props.data.sensitivity_score}%`;
  const assessmentTitle = props.data.energy ? `${props.data.energy.label} Assessment` : "";
  const description = sensitivityDescriptions[props.data.sensitivity_rank] || "";
  const [sortedCellSpecies, setSortedCellSpecies] = useState([]);
  const confidenceName = {
   /*  '1': 'High',
    '2': 'Medium',
    '3': 'Low' */
    //fix report species confidence values
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Very High',
  }

  const getStatus = {
    "EN": "Endangered (EN)",
    "CR": "Critically Endangered (CR)",
    "VU": "Vulnerable (VU)",
    "EX": "Extinct (EX)",
    "NT": "Nearly Threatened (NT)",
    "LC": "Least Concern (LC)",
    "EW": "Extinct in the Wild (EW)"
  }

  const getPercent = (sensitivity) => {
      if (!isNaN(sensitivity)){
        return sensitivity * 100
      }else{
        return 0
      }
  }

  // Sort Species
  const sortSpecies = (criteria, tempCellSpecies, changeSortOrder) => {
    var tempSpeciesSortOrder = 'DESC';
    if (tempCellSpecies) {
      if (!changeSortOrder || speciesSortCriteria != criteria) {
        tempSpeciesSortOrder = 'DESC';
      } else {
        if (speciesSortOrder == 'ASC') {
          tempSpeciesSortOrder = 'DESC';
        } else {
          tempSpeciesSortOrder = 'ASC';
        }
      }
      if (tempSpeciesSortOrder == 'DESC') {
        if (criteria == 'common_name') {
          tempCellSpecies.sort((a, b) =>
            a.common_name < b.common_name ? 1 : -1
          );
        } else if (criteria == 'scientific_name') {
          tempCellSpecies.sort((a, b) =>
            a.scientific_name < b.scientific_name ? 1 : -1
          );
        } else if (criteria == 'sensitivity_offshore_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_collision < b.sensitivity_offshore_collision
              ? 1
              : -1
          );
        } else if (criteria == 'sensitivity_offshore_displaceme') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_displaceme <
            b.sensitivity_offshore_displaceme
              ? 1
              : -1
          );
        } else if (criteria == 'sensitivity_pw_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_collision < b.sensitivity_pw_collision ? 1 : -1
          );
        } else if (criteria == 'sensitivity_pw_elec') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_elec < b.sensitivity_pw_elec ? 1 : -1
          );
        } else if (criteria == 'sensitivity_wind') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_wind < b.sensitivity_wind ? 1 : -1
          );
        } else if (criteria == 'sps_confidence') {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? 1 : -1
          );
        } else {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? 1 : -1
          );
        }
      } else if (tempSpeciesSortOrder == 'ASC') {
        if (criteria == 'common_name') {
          tempCellSpecies.sort((a, b) =>
            a.common_name < b.common_name ? -1 : 1
          );
        } else if (criteria == 'scientific_name') {
          tempCellSpecies.sort((a, b) =>
            a.scientific_name < b.scientific_name ? -1 : 1
          );
        } else if (criteria == 'sensitivity_offshore_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_collision < b.sensitivity_offshore_collision
              ? -1
              : 1
          );
        } else if (criteria == 'sensitivity_offshore_displaceme') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_displaceme <
            b.sensitivity_offshore_displaceme
              ? -1
              : 1
          );
        } else if (criteria == 'sensitivity_pw_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_collision < b.sensitivity_pw_collision ? -1 : 1
          );
        } else if (criteria == 'sensitivity_pw_elec') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_elec < b.sensitivity_pw_elec ? -1 : 1
          );
        } else if (criteria == 'sensitivity_wind') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_wind < b.sensitivity_wind ? -1 : 1
          );
        } else if (criteria == 'sps_confidence') {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? -1 : 1
          );
        } else {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? -1 : 1
          );
        }
      }
    }
    //setSpeciesSortOrder(tempSpeciesSortOrder);
    setSortedCellSpecies([...tempCellSpecies]);
    //setSpeciesSortCriteria(criteria);
  };

  useEffect(() => {
    if (props.data.energy != null && props.data.energy) {
        switch (props.data.energy.code) {
        case 'OFFSHORE':
          sortSpecies('sensitivity_offshore_collision', props.data.species, false);
          break;
        case 'ONSHORE':
          sortSpecies('sensitivity_wind', props.data.species, false);
          break;
        case 'PWDIST':
          sortSpecies('sensitivity_pw_collision', props.data.species, false);
          break;
        case 'PWTRAN':
          sortSpecies('sensitivity_pw_collision', props.data.species, false);
          break;
        default:
          break;
      }
    }
  }, [props.data.species]);

    
return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
              <View style={styles.headerfix} fixed>
                <View style={styles.header1}>
                  <View style={{ height: '40%', textAlign: 'left', display: 'flex',flexDirection: 'row', gap: '10pt' }}>
                      <Text style={styles.avistep}>AVISTEP </Text><Text style={{ fontSize: '14pt', fontFamily:'Work Sans', fontWeight: 300 }}> {props.data.country}</Text>
                  </View>
                  <View style={{ height: '25%', textAlign: 'left' }}>
                      <Text style={{ fontSize: '16pt', fontFamily:'Work Sans', fontWeight: 400 }}>{assessmentTitle}</Text>
                  </View>
                  <View style={{ height: '25%', textAlign: 'left', display: 'flex',flexDirection: 'row', gap: '10px' }}>
                      <Image src={IconSource['LOCATIONPIN_PNG']} style={styles.pinicon}/><Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400 }}>{props.data.coordinates}</Text>
                  </View>
                </View>
                <View style={styles.header2}>
                  <View style={{ height: '40%', textAlign: 'right' }}>
                        <Image src={logoref} style={styles.logo}/>
                  </View>
                  <View style={{ height: '25%', textAlign: 'right' }}>
                      
                  </View>
                  <View style={{ height: '25%', textAlign: 'right' }}>
                    <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400 }}>{score}</Text>
                  </View>
                </View>
                  
              </View>
              <View key='titieSection' style={styles.titlesection}>
                  <View style={styles.sectiontitle}>
                    <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 300, color: 'black' }}>
                      {description[0]}<Text style={{ fontFamily: 'Helvetica-Bold', color: 'black' }}>{description[1]}</Text>{description[2]}
                    </Text>

                  </View>
              </View>
              <View key='mapSection' style={styles.mapsection}>
                  <View key='mapRegionSection' style={styles.mapview}>
                    <Image key='mapRegionSectionImg'src={props.data.region_map.dataUrl} style={styles.mapimage}/>                   
                  </View>
                  <View key='mapCellSection' style={styles.mapview}>
                    <Image key='mapCellSectionImg' src={props.data.cell_map.dataUrl} style={styles.mapimage}/>
                  </View>
                  <Text key='mapDescSection' style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 200, color: 'black' }}>
                         Grid cells represent approximately 5km x 5km area
                    </Text>
              </View>            
            
              {props.data.sites.length > 0 && <View style={styles.sitessection} break>
                  <Text style={styles.sectiontitle}>Sensitive Sites</Text>
                  <View style={styles.sitesheader}>
                      <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10pt'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Site</Text>
                      </View>
                      <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Type</Text>
                      </View>
                  </View>

                  {props.data.sites && props.data.sites.map((site, index) => {
                      return (
                        <View style={styles.sitesrow}>
                          <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10pt'}}>
                            <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{site.site}</Text>
                          </View>
                          <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                            <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{site.type}</Text>
                          </View>
                      </View>
                      )
                  })}

              </View>}
              {props.data.energy.code != 'SOLAR' && props.data.species.length > 0 && <View style={styles.speciessection} wrap break>
                  <Text style={styles.sectiontitle} fixed>Sensitive Species</Text>
                  <View style={styles.speciesheader} fixed>
                      <View style={{width: '30%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '5pt'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Species</Text>
                      </View>
                      {props.data.energy.code != 'PWDIST' && <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Scientific Name</Text>
                      </View>}
                      {props.data.energy.code != 'PWDIST' && <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Status</Text>
                      </View>}
                      {props.data.energy.code == 'PWDIST' && <View style={{width: '20%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Scientific Name</Text>
                      </View>}
                      {props.data.energy.code == 'PWDIST' && <View style={{width: '20%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Status</Text>
                      </View>}
                      <View style={{width: '10%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        {props.data.energy.code == 'SOLAR' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Sensitivity</Text>}
                        {props.data.energy.code == 'ONSHORE' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Sensitivity</Text>}
                        {props.data.energy.code == 'OFFSHORE' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Collision</Text>}
                        {props.data.energy.code == 'PWDIST' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Collision</Text>}
                        {props.data.energy.code == 'PWTRAN' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Collision</Text>}
                        
                      </View>
                      <View style={{width: '10%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        {props.data.energy.code == 'SOLAR' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Occurence Certainty</Text>}
                        {props.data.energy.code == 'ONSHORE' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Occurence Certainty</Text>}
                        {props.data.energy.code == 'OFFSHORE' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Displacement</Text>}
                        {props.data.energy.code == 'PWDIST' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Electrocution</Text>}
                        {props.data.energy.code == 'PWTRAN' && <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Occurence Certainty</Text>}
                      </View>
                      {props.data.energy.code == 'PWDIST' && <View style={{width: '10%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Occurence Certainty</Text>
                      </View>}
                  </View>
                  
                  {props.data.energy.code != 'SOLAR' && props.data.species && props.data.species.map((bird, index) => {
                      return (
                        <View style={styles.speciesrow} wrap={false}>
                            <View style={{width: '30%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '5pt', display: 'flex', flexDirection: 'row'}}>
                              {BirdSpeciesImageSource[bird.scientific_name] && <Image src={BirdSpeciesImageSource[bird.scientific_name]} style={{ height: '.5in', width: '.5in', borderColor: '#000000',borderWidth: '1pt', borderRadius: '.25in'}}/>}
                              {!BirdSpeciesImageSource[bird.scientific_name] && <View  style={{ height: '.3in', width: '.3in', borderColor: '#000000',borderWidth: '1pt', borderRadius: '.25in', backgroundColor: '#FFFFFF' }}/>}
                              <Text style={{ paddingLeft: '4pt', fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424', marginTop: 'auto', marginBottom: 'auto' }}>{bird.common_name}</Text>
                            </View>
                            {props.data.energy.code != 'PWDIST' &&  <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{bird.scientific_name}</Text>
                            </View>}
                            {props.data.energy.code != 'PWDIST' &&  <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{getStatus[bird.rl_category] || bird.rl_category}</Text>
                            </View>}
                            {props.data.energy.code == 'PWDIST' &&  <View style={{width: '20%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{bird.scientific_name}</Text>
                            </View>}
                            {props.data.energy.code == 'PWDIST' &&  <View style={{width: '20%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{getStatus[bird.rl_category] || bird.rl_category}</Text>
                            </View>}
                            <View style={{width: '10%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              {props.data.energy.code == 'ONSHORE' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{bird.sensitivity_wind === null? "-" : getPercent(bird.sensitivity_wind).toFixed(1)}</Text>}
                              {props.data.energy.code == 'OFFSHORE' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{bird.sensitivity_offshore_collision === null? "-" : getPercent(bird.sensitivity_offshore_collision).toFixed(1)}</Text>}
                              {props.data.energy.code == 'PWDIST' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{bird.sensitivity_pw_collision === null? "-" : getPercent(bird.sensitivity_pw_collision).toFixed(1)}</Text>}
                              {props.data.energy.code == 'PWTRAN' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{bird.sensitivity_pw_collision === null? "-" : getPercent(bird.sensitivity_pw_collision).toFixed(1)}</Text>}
                            </View>
                            <View style={{width: '10%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              {props.data.energy.code == 'ONSHORE' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{confidenceName[bird.sps_confidence] || ""}</Text>}

                              {props.data.energy.code == 'OFFSHORE' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{bird.sensitivity_offshore_displaceme === null? "-" : getPercent(bird.sensitivity_offshore_displaceme).toFixed(1)}</Text>}

                              {props.data.energy.code == 'PWDIST' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{bird.sensitivity_pw_elec === null? "-": getPercent(bird.sensitivity_pw_elec).toFixed(1)}</Text>}
                              {props.data.energy.code == 'PWTRAN' && <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{confidenceName[bird.sps_confidence] || ""}</Text>}
                              
                            </View>
                            {props.data.energy.code == 'PWDIST' &&  <View style={{width: '10%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '7pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{confidenceName[bird.sps_confidence] || ""}</Text>
                            </View>}
                        </View>
                      )
                  })}
              </View>}
              {props.data.energy.code != 'OFFSHORE' && props.data.energy.code != 'PWDIST' && props.data.energy.code != 'PWTRAN' && <View style={styles.sitessection} break>
                  <Text style={styles.lctitle}>Landcover Sensitivity</Text>
                  <View style={styles.lcsection}>
                    <Image src={props.data.chartImage.dataUrl} style={styles.lcimage}/>
                  </View> 
              </View>}
                  
              <View style={styles.pagenum} fixed>
                <Text render={({ pageNumber, totalPages }) => (
                `${pageNumber} of ${totalPages}`
                  )}  />
              </View>
            </Page>
        </Document>
    )
};


export default CellReport;