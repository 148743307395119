import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Cookies from "./Cookies";
import ButtonGroup from "../ui/ButtonGroup";
import Footer from "./Footer";
import avistep_logo_blue from '../../styles/images/avistep_logo_blue.png';
import './Legal.css';

const sectionRef = {"Terms of Use": "terms", "Privacy Policy": "privacy", "Cookie Settings": "cookies"}

function Legal(props) {
  const [isScrolled, setScrolled] = useState(false);
  const [selected, setSelected] = useState("Terms of Use");
  const termsRef = useRef(null);
  const privacyRef = useRef(null);
  const cookiesRef = useRef(null);
  let navigate = useNavigate(); 
  
  const navMap = () => {
    let path = "/map"; 
    navigate(path);
  };

  const navHome = () => {
    let path = "/home"; 
    navigate(path);
  };

  const navToSection = (section) => {
    switch (section) {
      case 'terms':
        termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'privacy':
        privacyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'cookies':
        cookiesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      default:
        termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } 
  };

  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])


  useEffect(() => {   
    if (["terms","privacy","cookies"].includes(props.currentState)) {
      navToSection(props.currentState);    
    }
    //TODO: Change this to index of list or -1
    //setSelected("terms");
  }, [props.currentState])


  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (privacyRef && privacyRef.current && cookiesRef && cookiesRef.current) {
      if (winScroll < privacyRef.current.offsetTop - 200) { 
        setSelected("Terms of Use");
      } else if (winScroll < cookiesRef.current.offsetTop - 400) { 
        setSelected("Privacy Policy");
      } else {
        setSelected("Cookie Settings");
      }
    }

    if (isScrolled && winScroll < 1200) { 
      setScrolled(false);    // to limit setting state only the first time         
    } else if (!isScrolled && winScroll > 1200) {
      setScrolled(true);
    }  
  };

  const selectSection = (event) => {
    let section = sectionRef[event.target.name]
    navToSection(section);
  };

  return (
    <div>
    <div className="small-screen" >
      <div className="title"><img className="avilogo" alt="AviStep logo" src="/static/media/avistep_logo_white.212b8f29.png"/></div>
      <p className="sub-title">
        AviStep, the Avian Sensitivity Tool for Energy Planning, is designed to be viewed on a screen size of 768 px wide or larger. Please increase the width of your browser to access the tool.
      </p>
      <p className="sub-title">
        For additional help, contact <a href="mailto:avistep@birdlife.org">avistep@birdlife.org</a>
      </p>
    </div>

    <div className="large-screen" >
    <div className="legal">
      <div className="head">
      <div className="logocontainer" onClick={navHome}>
        <img className="avistep-logo" alt="AviStep logo" src={avistep_logo_blue}/>
      </div>
        <div className="button-group">
          <ButtonGroup 
            selected={selected} 
            buttons={["Terms of Use", "Privacy Policy", "Cookie Settings"]} 
            selectHandler={selectSection} />
        </div>
        <button className="btn-map" onClick={navMap}>Avian Mapping Tool</button>
      </div>
      <div className="info-container">
        <div ref={termsRef} className="terms-panel">
            <Terms />
        </div>
        <div ref={privacyRef} className="privacy-panel">
            <Privacy />
        </div>
        <div ref={cookiesRef} className="cookies-panel">
            <Cookies />
        </div>
      </div>
      <Footer></Footer>
    </div>
    </div>
    </div>
  );
}



export default Legal;
