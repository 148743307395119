import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import logo from '../../styles/images/bli_full_logo.png';
import "./Footer.css";

function Footer() {

  const {register, handleSubmit, formState: { errors }} = useForm();

  return (
    <div className="footer">
      <div className="row">
        <div className="col-3">
            <img className="bli-logo" alt="BLI Logo" src={logo}/>
        </div>
        <div className="col-6">
        </div>
        <div className="col-3 legal-links">
          <ul className="no-bullets">
            <li className="legal-links-header">LEGAL</li>
            <li><a href="terms">Terms of Use</a></li>
            <li><a href="privacy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="copyright">
          Copyright Language 2022
        </div>
      </div>
    </div>
  );
}



export default Footer;
