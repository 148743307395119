import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import ZoomViewModel from "@arcgis/core/widgets/Zoom/ZoomViewModel";
//import * as watchUtils from "@arcgis/core/core/watchUtils";
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import AddIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/RemoveSharp';

const useStyles = makeStyles((props) => {
    return {
        zoom: {
            position: "absolute",
            bottom: "6rem",
            right: props => `${props.right}`,
            width: "1.5rem",
            boxShadow: "0px 0px 5px 0px rgb(68,68,68,0.25)",
            borderWidth: "0px !important",
            display: "grid",
            gridTemplateRows: "auto",
            rowGap: "0.5rem",
            pointerEvents: "auto",
            zIndex: "3"
        },

        zoomin: {
            borderRadius: ".2rem",
            backgroundColor: "rgba(1, 16, 51, 1)",
            width: "1.5rem",
            height: "1.5rem",
            cursor: "pointer",
            textAlign: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
            '&:hover': {
                background: "rgba(0, 131, 205, 1)",
            },
        }
    }
})

const ZoomControl = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [viewModel, setViewModel] = useState(new ZoomViewModel());
    const [maxZoomed, setMax] = useState(false);
    const [minZoomed, setMin] = useState(false);
    const [right, setRightPosition] = useState('1rem');
    const classes = useStyles({ right });

    const mapState = useSelector(state => state.map.mapState);
    const cellSelected = useSelector(state => state.map.cellSelected);
    const rightPanelMinimized = useSelector(state => state.config.rightPanelMinimized);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [])


    useEffect(() => {
        if (cellSelected && !rightPanelMinimized) {
            if (window.innerWidth >= 1000)
                setRightPosition("23vw");
            else 
                setRightPosition("230px");
        }else {
            setRightPosition("1rem");
        }
    }, [cellSelected, rightPanelMinimized])


    const onZoomChange = (value) => {
        //setMax(value === props.mapView.constraints.maxZoom)
        //setMin(value === props.mapView.constraints.minZoom)
    };

    const zoomIn = () => {
        if (!maxZoomed) {
            viewModel.zoomIn();
        }
    };

    const zoomOut = () => {
        if (!minZoomed) {
            viewModel.zoomOut();
        }
    }; 

    useEffect(() => {
        viewModel.view = props.mapView;
        //watchUtils.init(props.mapView, "zoom", onZoomChange);
        reactiveUtils.on(
            () => props.mapView,
            "zoom",
            (event) => {
                onZoomChange
            });

    }, []);
    

    return <div className={classes.zoom}>
                <div className={classes.zoomin} onClick={zoomIn}>
                    <AddIcon style={{ color: "#FFFFFF" }} />
                </div>
                <div className={classes.zoomin} onClick={zoomOut}>
                    <RemoveIcon style={{ color: "#FFFFFF" }} />
                </div>
           </div>

}

export default ZoomControl;
