import { put, takeLatest, select } from "redux-saga/effects";
import { types as routeTypes, pages } from "../reducers/route";
import { types as mapTypes } from "../reducers/map";
import { types as configTypes } from "../reducers/config";


// selectors
const configSelector = state => state.config;


// WATCHERS //
export function* watchRoute() {
    // if map cannot grab its own config or general map issues
    yield takeLatest(configTypes.MAPCONFIG_FETCH_ERROR, handleRoute);
    // if we have all needed configs to render Main
    yield takeLatest(configTypes.SET_MAPCONFIG, handleRoute);

}

// WORKER //
function* handleRoute(action) {
    try {
        const configState = yield select(configSelector);

        let error = configState.error || null;

        // if there is a critical error, route to the respective error screen and stop
        if (error && error instanceof Error) {
            var page = pages.ERROR_PAGE__GENERAL
            // TODO: add more custom errors and add them here as logic grows

            yield put({
                type: routeTypes.SET_ROUTE,
                payload: page
            });
        }
        // If config is loaded, display Main so we can start the load of the map     
        else if (configState.loaded) {
            yield put({
                type: routeTypes.SET_ROUTE,
                payload: pages.MAIN_PAGE
            });
        }
        else {           
            return; // Nothing to do
        }

    } catch (e) {
        console.log('E',e)
        yield put({
            type: routeTypes.ROUTE_ERROR,
            payload: {
                error: e,
                msg: "SAGA ERROR: route"
            }
        });
    }
}
