import React from 'react';
import image1 from '../../styles/images/acknowledgements_img1.png';
import image2 from '../../styles/images/acknowledgements_img2.png';
import image3 from '../../styles/images/acknowledgements_img3.png';
import eakpf_logo from '../../styles/images/eakpf_logo.png';
import adb_logo from '../../styles/images/adb_logo.png';
import bnhs_logo from '../../styles/images/bnhs_logo.png';
import bcn_logo from '../../styles/images/bcn_logo.png';
import bcst_logo from '../../styles/images/bcst_logo.png';
import vietnature_logo from '../../styles/images/vietnature_logo.png';
import cms_energytaskforce_logo from '../../styles/images/cms_energytaskforce_logo.png';
import protected_planet_logo from '../../styles/images/protected_planet_logo.png';
import eBird_logo from '../../styles/images/eBird_logo.png';
import ibat_logo from '../../styles/images/ibat_logo.png';
import deg_logo from '../../styles/images/deg_impulse_logo.png';
import egypt_logo from '../../styles/images/egypt_logo.png';
import ken_logo from '../../styles/images/kenya_logo.png';
import uzb_logo from '../../styles/images/uzbekistan_logo.png';

const Acknowledgements = () => {
  return (
    <div className='acknowledgement'>
      <h2>SUPPORTERS</h2>

      <h5>
        <a
          href='https://www.adb.org/what-we-do/funds/e-asia-and-knowledge-partnership-fund'
          target='_blank'
        >
          e-Asia and Knowledge Partnership Fund (EAKPF)
        </a>
      </h5>
      <div>
        The Republic of Korea established the e-Asia and Knowledge Partnership
        Fund (EAKPF) in 2006. By providing grants and supporting technical
        assistance projects, the fund aims to bridge the digital divide and
        improve access to information through information and communication
        technology (ICT) in the Asia and Pacific region.
      </div>
      <br></br>

      <div>
        <h5>
          <a href='https://www.degimpulse.de/en/' target='_blank'>
            DEG Impulse
          </a>
        </h5>
      </div>
      <div>
        DEG Impulse is a subsidiary of the German Development Finance
        Institution DEG that promotes the social and environmental
        transformation of private sector enterprises in emerging markets.
      </div>

      <div className='image-set-1'>
        <div className='image-container'>
          <img className='image-1' alt='Image 1' src={image1} />
          <div className='image-caption'>Photo Credit: Roger Tidman</div>
        </div>
        <div className='image-container'>
          <img className='image-2' alt='Image 2' src={image2} />
          <div className='image-caption'>Photo Credit: Con Foley</div>
        </div>
      </div>
      <h2>PROJECT PARTNERS</h2>
      <h5>
        <a href='https://www.adb.org/' target='_blank'>
          Asian Development Bank
        </a>
      </h5>
      <div>
        The Asian Development Bank (ADB) is committed to achieving a prosperous,
        inclusive, resilient, and sustainable Asia and the Pacific, while
        sustaining its efforts to eradicate extreme poverty. It assists its
        members, and partners, by providing loans, technical assistance, grants,
        and equity investments to promote social and economic development.
      </div>
      <h5>
        <a href='https://www.bnhs.org/' target='_blank'>
          Bombay Natural History Society (BNHS)
        </a>
      </h5>
      <div>
        The Bombay Natural History Society (BNHS), a pan-India wildlife research
        organization, has been promoting the cause of nature conservation since
        1883. Its mission is the conservation of nature, primarily biological
        diversity through action based on research, education and public
        awareness.
      </div>
      <h5>
        <a href='https://www.birdconservationnepal.org/' target='_blank'>
          Bird Conservation Nepal (BCN)
        </a>
      </h5>
      <div>
        Established in 1982, Bird Conservation Nepal (BCN) is the leading
        organization in Nepal, focusing on the conservation of birds, their
        habitats and sites. It seeks to promote interest in birds amongst the
        general public, encourage research on birds and identify major threats
        to birds’ continued survival.
      </div>
      <h5>
        <a href='https://www.bcst.or.th/' target='_blank'>
          Bird Conservation Society of Thailand (BCST)
        </a>
      </h5>
      <div>
        The Bird Conservation Society of Thailand (BCST) is one of the oldest
        organisations conserving birds and nature in Thailand and is the country
        partner of BirdLife International. BCST’s role is to spread awareness
        about birds and reconnect people back to nature.
      </div>
      <h5>
        <a href='https://www.thiennhienviet.org.vn/en/about/' target='_blank'>
          Viet Nature
        </a>
      </h5>
      <div>
        The Viet Nature Conservation Centre is an autonomous national
        conservation NGO in Vietnam that has grown from BirdLife’s involvement
        in over 20 years of programme development and support to civil society
        in the country. Its focuses on conservation action on the ground;
        biodiversity monitoring; linking biodiversity to human well-being and in
        the context of climate change; conservation science capacity building;
        and environmental awareness raising and education.
      </div>
      <h5>
        <a
          href='https://www.birdlife.org/partners/uzbekistan-uzbekistan-society-for-the-protection-of-birds-uzspb/#:~:text=Uzbekistan%20%E2%80%93%20Uzbekistan%20Society%20for%20the,Birds%20(UzSPB)%20%2D%20BirdLife%20International'
          target='_blank'
        >
          Uzbekistan Society for the Protection of Birds (UzSPB)
        </a>
      </h5>
      <div>
        The mission of UzSPB is the conservation of birds, their habitats and
        other elements of biodiversity through close cooperation with state,
        non-governmental and local organizations.
      </div>
      <h5>
        <a href='https://naturekenya.org/' target='_blank'>
          Nature Kenya
        </a>
      </h5>
      <div>
        Established in 1909 to promote the study and conservation of nature in
        eastern Africa, Nature Kenya – the East Africa Natural History Society –
        is Africa’s oldest environmental society. Its mission is to connect
        people with nature and take action for biodiversity conservation.
      </div>
      <h5>
        <a href='https://www.natureegypt.org/' target='_blank'>
          Nature Conservation Egypt
        </a>
      </h5>
      <div>
        Nature Conservation Egypt (NCE) is an Egyptian NGO working towards the
        conservation of Egypt’s natural heritage for the benefit of present and
        future generations. Established in 2005, NCE specialises in scientific
        research, advocacy, education and outreach to support species, their
        habitats, and local communities.
      </div>
      <div className='image-set-2'>
        <div className='image-container'>
          <img className='image-3' alt='Image 3' src={image3} />
          <div className='image-caption'>Photo Credit: Vladimir Melnik</div>
        </div>
      </div>
      <h2>AFFILIATES &amp; DATA PROVIDERS</h2>
      <h5>
        <a
          href='https://www.cms.int/en/taskforce/energy-task-force'
          target='_blank'
        >
          CMS Energy Task Force
        </a>
      </h5>
      <div>
        The CMS Energy Task Force is a multi-stakeholder platform that works
        towards reconciling renewable energy developments with the conservation
        of migratory species. The Task Force brings together governments,
        multilateral environmental agreements, investors, the private sector and
        non-governmental organizations with an aim of avoiding and minimising
        negative impacts of energy developments on migratory species.
      </div>
      <h5>
        <a href='https://ebird.org/home' target='_blank'>
          eBird
        </a>
      </h5>
      <div>
        eBird is among the world’s largest biodiversity-related science
        projects, with more than 100 million bird sightings contributed annually
        by eBirders around the world and an average participation growth rate of
        approximately 20% year over year. A collaborative enterprise with
        hundreds of partner organizations, thousands of regional experts, and
        hundreds of thousands of users, eBird is managed by the Cornell Lab of
        Ornithology.
      </div>
      <h5>
        <a href='https://www.protectedplanet.net/en' target='_blank'>
          Protected Planet
        </a>
      </h5>
      <div>
        Protected Planet is the most up to date and complete source of data on
        protected areas and other effective area-based conservation measures
        (OECMs), updated monthly with submissions from governments,
        non-governmental organizations, landowners and communities.
      </div>
      <h5>
        <a href='https://www.ibat-alliance.org/' target='_blank'>
          Integrated Biodiversity Assessment Tool (IBAT)
        </a>
      </h5>
      <div>
        The Integrated Biodiversity Assessment Tool (IBAT) provides the world's
        most authoritative biodiversity data for those seeking information to
        inform planning decisions. Over US$6.5 million is invested each year to
        update and maintain these databases.
      </div>
      <div className='logo-grid'>
        <div className='row'>
          <div>
            <a
              href='https://www.adb.org/what-we-do/funds/e-asia-and-knowledge-partnership-fund'
              target='_blank'
            >
              <img
                className='partner-logo'
                alt='e-Asia and Knowledge Partnership Fund'
                src={eakpf_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://www.adb.org/' target='_blank'>
              <img
                className='partner-logo'
                alt='Asian Development Bank'
                src={adb_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://www.degimpulse.de/en/' target='_blank'>
              <img className='partner-logo' alt='DEG Impulse' src={deg_logo} />
            </a>
          </div>
          <div>
            <a href='https://www.bnhs.org/' target='_blank'>
              <img
                className='partner-logo'
                alt='The Bombay Natural History Society'
                src={bnhs_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://www.birdconservationnepal.org/' target='_blank'>
              <img
                className='partner-logo'
                alt='Bird Conservation Nepal'
                src={bcn_logo}
              />
            </a>
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div>
            <a
              href='https://www.thiennhienviet.org.vn/en/about/'
              target='_blank'
            >
              <img
                className='partner-logo'
                alt='Viet Nature'
                src={vietnature_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://www.natureegypt.org/' target='_blank'>
              <img
                className='partner-logo'
                alt='Nature Conservation Egypt'
                src={egypt_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://naturekenya.org/' target='_blank'>
              <img className='partner-logo' alt='Nature Kenya' src={ken_logo} />
            </a>
          </div>
          <div>
            <a
              href='https://www.birdlife.org/partners/uzbekistan-uzbekistan-society-for-the-protection-of-birds-uzspb/#:~:text=Uzbekistan%20%E2%80%93%20Uzbekistan%20Society%20for%20the,Birds%20(UzSPB)%20%2D%20BirdLife%20International'
              target='_blank'
            >
              <img
                className='partner-logo'
                alt='Uzbekistan Society for the Protection of Birds (UzSPB)'
                src={uzb_logo}
              />
            </a>
          </div>
          <div>
            <a
              href='https://www.cms.int/en/taskforce/energy-task-force'
              target='_blank'
            >
              <img
                className='partner-logo'
                alt='CMS Energy Task Force'
                src={cms_energytaskforce_logo}
              />
            </a>
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div>
            <a href='https://ebird.org/home' target='_blank'>
              <img className='partner-logo' alt='eBird' src={eBird_logo} />
            </a>
          </div>
          <div>
            <a href='https://www.ibat-alliance.org/' target='_blank'>
              <img
                className='partner-logo'
                alt='Integrated Biodiversity Assessment Tool'
                src={ibat_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://www.bcst.or.th/' target='_blank'>
              <img
                className='partner-logo'
                alt='Bird Conservation Society of Thailand'
                src={bcst_logo}
              />
            </a>
          </div>
          <div>
            <a href='https://www.protectedplanet.net/en' target='_blank'>
              <img
                className='partner-logo'
                alt='Protected Planet'
                src={protected_planet_logo}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acknowledgements;
