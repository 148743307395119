
var assert = require('assert')

// ACTION TYPES //
export const types = {
    SET_ROUTE: "SET_ROUTE",
    ROUTE_ERROR: "ROUTE_ERROR",
    RESET_ROUTE: "RESET_ROUTE",
    INJECT_ROUTE: "INJECT_ROUTE",
};


// CONSTANTS
export const pages = {
    ERROR_PAGE__GENERAL: "ERROR_PAGE__GENERAL",
    MAIN_PAGE: "MAIN_PAGE"
}

// REDUCERS //
export const initialState = {
    page: "",
    error: "",
    routeInfo: {
        route: "",
        type: "",
        query: ""
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ROUTE:
            assert(action.payload in pages);
            return {
                ...state,
                page: action.payload,
            };
        case types.ROUTE_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case types.RESET_ROUTE:
            return {
                ...state,
                routeInfo: initialState.routeInfo
            };
        case types.INJECT_ROUTE:
            return {
                ...state,
                routeInfo: action.payload.routeInfo
            };
        default:
            return state;
    }
};

export const resetRoute = () => ({ type: types.RESET_ROUTE });
export const injectRoute = (payload) => ({ type: types.INJECT_ROUTE, payload });
