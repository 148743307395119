import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

//top: props => `${props.mouseY}`,
//left: props => `${props.mouseX}`,

const useStyles = makeStyles((props) => {
    return {
        root: {
            position: "absolute",
            display: "flex",
            top: "1vh",
            left: "45vw",
            minWidth: "10%",
            zIndex: '3',
            borderRadius: '4px',
            minHeight: '2rem',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        textbox: {
            fontWeight: '400',
            color: 'white',
            backgroundColor: 'rgba(15,26,53,0.85)',
            zIndex: '3',
            padding: '0.5rem',
            borderRadius: '4px',
            maxWidth: '200px',
            minHeight: '20px',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '1rem',
            visibility: props => `${props.visibility}`,
        }
       
    }
});



const MapTooltip = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [visibility, setVisibility] = useState('hidden');
    const [mouseX, setX] = useState('20px');
    const [mouseY, setY] = useState('40vw');
    const classes = useStyles({ visibility, mouseX, mouseY });
    const pointMap = useRef(null);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);           
        }
        return () => { 
            if (pointMap.current){
                pointMap.current.remove();
            }
        };
    }, [])

    useEffect(() => {
        if (props.isOpen) {
            pointMap.current = props.mapView.on('pointer-move', function (event) {
                setX(event.x - 55 + 'px');
                setY(event.y - 90 + 'px');
            });   
            setVisibility('visible');
        } else {
            if (pointMap.current){
                pointMap.current.remove();
                pointMap.current = null;
            }
            setVisibility('hidden');
        }
    }, [props])


    return <div className={classes.root}>
                <div className={classes.textbox}>{props.displaytext}</div>
            </div>
}

export default MapTooltip;
