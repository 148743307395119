import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import BaseMapItem from './BaseMapItem';



const useStyles = makeStyles((props) => {
    return {
        picker: {
            position: "absolute",
            bottom: "25px",
            right: props => `${props.right}`,
            height: "3.2rem",
            minWidth: "3.2rem",
            borderRadius: ".3rem",
            boxShadow: "0px 0px 5px 0px rgb(68,68,68,0.25)",
            borderWidth: "0px !important",
            pointerEvents: "auto",
            backgroundColor: "rgba(1, 16, 51, 1)",
            zIndex: "3",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            "-webkit-transition": "width 3s ease-in-out",
            "-moz-transition": "width 3s ease-in-out",
            "-o-transition": "width 3s ease-in-out",
            "transition": "flex 0.3s ease-out"
        }
    }
})

const BaseMapPicker = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [currentBM, setCurrent] = useState(props.mapView.map.basemap);
    const [loaded, setLoaded] = useState(false)
    const [right, setRightPosition] = useState('1rem');
    const classes = useStyles({ right });
    const cellSelected = useSelector(state => state.map.cellSelected);
    const basemaps = useSelector(state => state.map.basemaps);
    const rightPanelMinimized = useSelector(state => state.config.rightPanelMinimized);

    const switchMap = (map) => {
        if (expanded) {
            setCurrent(map);
            props.mapView.map.basemap = map;
            setExpanded(false);
        } else {
            setExpanded(true);
        }

    };

    const setOnEnter = () => {
        setExpanded(true);
    };

    const setOnLeave = () => {
        setExpanded(false);
    };

    useEffect(() => {
        if (basemaps && !loaded) {
            let satellitemap = basemaps.find(bm => bm.id == "satellite");
            if (satellitemap){
                setCurrent(satellitemap);
            }
            setLoaded(true);
        }
    }, [basemaps])

    useEffect(() => {
        if (cellSelected && !rightPanelMinimized) {
            if (window.innerWidth >= 1000)
                setRightPosition("23vw");
            else 
                setRightPosition("230px");
        }else {
            setRightPosition("1rem");
        }
    }, [cellSelected, rightPanelMinimized])



    return <div className={classes.picker} onMouseEnter={setOnEnter} onMouseLeave={setOnLeave}>

            {basemaps.map(function (item) {
                return <BaseMapItem key={item.id} basemap={item} expanded={expanded} current={currentBM} onClick={e => switchMap(item)}></BaseMapItem>
                    })
                    }
            </div>
            }

export default BaseMapPicker;
