import React, { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
//import * as watchUtils from "@arcgis/core/core/watchUtils";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { setRankAreas } from "../../../../redux/reducers/map";
import { buildAreaStats } from "../../../../utils/map";

/*
This component is associated with and is used to control the behavior/visibility of the 
Regional energy sensitivity data feature layers in the AviStep webmap (published in AGO)

This component listens to map state, when a specific country and energy have been selected, this component
will make the according feature layer visible in the map, and filter it by region if selected.

Regional energy sensitivity data feature layers are titled according to a specific format using the Country and Energy
code so that they can be selected in this component. 
Example: 
When selecting India and Solar energy, this component will make the "IND_SOLAR_REGIONS" layer visible.

*/




const RegionalEnergy = (props) => {
    const sensitivity_ranks = useSelector(state => state.map.sensitivity_ranks);
    const sensitivityRankRef = useRef(sensitivity_ranks);

    // Ref for Layer
    const energyLayerRef = useRef(null);
    const mapStateRef = useRef({});
    const suitableRef = useRef(false);
    const withinScale = useRef(false);

    // Layer Loaded state
    let [lyrLoaded, setLyrLoaded] = useState(false);
    let [lyrView, setLyrView] = useState(null);

    const config = useSelector(state => state.config);
    const mapState = useSelector(state => state.map.mapState);
    const suitable = useSelector(state => state.map.suitable);
    const transparency = useSelector(state => state.map.transparency);
    const dispatch = useDispatch();
    

    const updateStatistics = async (statisticsfilters) => {
        if (mapStateRef.current.country && mapStateRef.current.energy && !mapStateRef.current.lat){
            let clause = statisticsfilters.length == 0 ? '1=1' : statisticsfilters.join(` AND `);
            let query = {
                where: clause,
                returnGeometry: false,
                outFields: ["*"]
            }
            let results = await energyLayerRef.current.queryFeatures(query);
            if (results.features.length > 0){
                let stats = buildAreaStats(results.features);
                dispatch(setRankAreas(stats));
            }
        }
    };

    const updateVisibility = async () => {
        if (mapStateRef.current.country && mapStateRef.current.energy && withinScale.current){
            energyLayerRef.current.visible = true;
        }else{
            energyLayerRef.current.visible = false;
        }
    }

    const updateDefinitionQuery = async () => {
        if (mapStateRef.current.country && mapStateRef.current.energy){
            let regionfilter = false;
            let suitablefilter = false;
            let ranksfilter = false;
            let filters = [];
            let statisticsfilters = [];
            if (mapStateRef.current.region){
                // Add filter for region
                let regionfield = "GID_1";
                regionfilter = `${regionfield} = '${mapStateRef.current.region}'`;
                filters.push(regionfilter);
                statisticsfilters.push(regionfilter);
            }
            if (suitableRef.current){
                // Add filter for suitable areas
                let suitablefield = "suitable"
                suitablefilter = `${suitablefield} = 1`;
                filters.push(suitablefilter);
                statisticsfilters.push(suitablefilter);
            }
            if (sensitivityRankRef.current){
                // Add sensitivity ranks to be filtered
                let nonvisibleRanks = Object.keys(sensitivityRankRef.current).filter(key => sensitivityRankRef.current[key] === false);
                if (nonvisibleRanks.length > 0){
                    let sensitivityfield = "sensitivity_rank";
                    let vals = nonvisibleRanks.join(",");
                    ranksfilter = `${sensitivityfield} NOT IN (${vals})`;
                    filters.push(ranksfilter);
                }
            }
            
            // Combine all filters into definitionExpression clause
            let clause = !regionfilter && !suitablefilter && !ranksfilter ? null : filters.join(` AND `);
            energyLayerRef.current.definitionExpression = clause;
            updateStatistics(statisticsfilters);
            //console.log("REFRESH ENERGY")
            //energyLayerRef.current.refresh();
        }
    }

    const updateTransparency = (trans) => {
        if (energyLayerRef.current){
            let opacity = trans / 100;
            energyLayerRef.current.opacity = opacity;
        }
    }



    useEffect(() => {
        let scaleWatch = props.mapView.watch("scale", (scale) => {
            let inScaleRange = scale > 1155583 ? true : false;
            if (inScaleRange != withinScale.current){
                withinScale.current = inScaleRange;
                if (energyLayerRef.current) { energyLayerRef.current.visible = inScaleRange;}
            }
        });
        if (props.mapView && !lyrLoaded) {
            mapStateRef.current = mapState;
            if (mapState.country && mapState.energy && !mapState.lat){
                
                let layertitle = `avistep_${mapState.country.toLowerCase()}_${mapState.energy.code.toLowerCase()}_areas`
                let regionLyr = props.mapView.map.layers.find(layer => layer.title === layertitle);
                if (regionLyr) { // If regionlyr is present, update its visibility
                    energyLayerRef.current = regionLyr;
                    updateDefinitionQuery();
                    updateVisibility();         
                } 
            }
        }
        return () => { 
            scaleWatch.remove(); 
        };
    }, [])

    useEffect(() => {
        mapStateRef.current = mapState;
        if (props.mapView){
            if (mapState.country && mapState.energy){
                let layertitle = `avistep_${mapState.country.toLowerCase()}_${mapState.energy.code.toLowerCase()}_areas`;
                if (layertitle != energyLayerRef.current?.title){
                    if (energyLayerRef.current){ energyLayerRef.current.visible = false};
                    let regionLyr = props.mapView.map.layers.find(layer => layer.title === layertitle);
                    if (regionLyr) { // If regionlyr is present, update its visibility
                        energyLayerRef.current = regionLyr;
                        updateDefinitionQuery();
                        updateVisibility(); 
                    }
                }else{
                    updateDefinitionQuery();
                    updateVisibility(); 
                }
                 
            }else{
                if (energyLayerRef.current){ energyLayerRef.current.visible = false};
            }
            
        }
          
    }, [mapState]);


    useEffect(() => {
        updateTransparency(transparency);
    }, [transparency]);

    useEffect(() => {
        suitableRef.current = suitable;
        updateDefinitionQuery();
    }, [suitable]);

    useEffect(() => {
        sensitivityRankRef.current = sensitivity_ranks;
        updateDefinitionQuery();
    }, [sensitivity_ranks]);


    return null;

}

export default RegionalEnergy;