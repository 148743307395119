import React from 'react'
import styled from "styled-components";

const Body = styled.div`
    background-color: #EAF2FD;
    height: 100%;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    padding-top: 100px
`

const A = styled.a`
    padding-left: 5px
    padding-right: 5px
`

const ApplicationError = () => {
    return (
        <Body>
            <Container>
                <div className='oval-icon'>
                </div>
                <div className='header-message'>
                    <h1>Application error</h1>
                </div>
                <div className='main-message'>
                    <p>There was an error with this application.</p>
                </div>
            </Container>
        </Body>
     )
}

export default ApplicationError
