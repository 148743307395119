import { PinDropSharp } from "@material-ui/icons";
import React, { useState } from "react";
import "./Toggle.css";

function uniqueId(prefix='id') {
    return `${prefix}${Math.random().toString(16).slice(2)}`;
}

const Toggle = ({ enabled, onClick }) => {
  const [ id ] = useState(() => uniqueId('toggle-'));
  const [checked, setChecked] = useState(enabled); 

  const handleChange = () => {
    onClick(!checked); 
    setChecked(!checked); 
  }; 

  return (
    <>
      <input className="tog" type="checkbox" id={id} onChange={handleChange} checked={checked}/><label className="toglabel" htmlFor={id}></label>
    </>
  );
};

export default Toggle;
