import React, { useRef, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { IconSource } from '../../../../utils/imagesource';
import {
  RightPanelImageSource,
  BirdSpeciesImageSource,
  Icon,
} from '../../../../utils/imagesource';
import { formatLatitude, formatLongitude } from '../../../../utils/map';
import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

import './RightPanel.css';

Chart.register(ArcElement);

export const chartOptions = {
  layout: {
    padding: 20,
  },
  cutout: '85%',
  borderRadius: 85,
  spacing: 7,
};

const RightPanel = (props) => {
  const dispatch = useDispatch();
  const mapStateRef = useRef({});

  const down = Icon('EXPANDMORE');
  const [downArrow, setDownArrow] = useState(down);
  const up = Icon('EXPANDLESS');
  const [upArrow, setUpArrow] = useState(up);

  // Controls to show/hide the panel or sections of the panel
  const [panelVisible, setPanelVisibility] = useState(false);
  const [minimizePanel, setMinimizePanel] = useState(false);

  const [showSensitiveSpeciesPanel, setShowSensitiveSpeciesPanel] =
    React.useState(true);
  const [showSensitiveFeaturesPanel, setShowSensitiveFeaturesPanel] =
    React.useState(false);
  const [showLandCoverPanel, setShowLandCoverPanel] = React.useState(false);
  const [showSensitiveSitesPanel, setShowSensitiveSitesPanel] =
    React.useState(false);
  const [containsSensitiveSites, setContainsSensitiveSites] =
    React.useState(false);

  // Data to display
  const [latitude, setLatitude] = React.useState(false);
  const [longitude, setLongitude] = React.useState(false);
  const [chartData, setChartData] = React.useState(false);
  const [landCover, setLandCover] = React.useState(false);
  const [energy, setEnergy] = useState(false);
  const [imageName, setImageName] = useState(false);
  const [image, setImage] = useState(false);
  const [title, setTitle] = useState(false);
  const [sensitivityScore, setSensitivityScore] = useState(false);
  const [sensitivityName, setSensitivityName] = useState(false);
  const [sensitivityDescription, setSensitivityDescription] = useState(false);
  const [sortedCellSpecies, setSortedCellSpecies] = useState([]);

  // Data passed to the panel
  const mapState = useSelector((state) => state.map.mapState);
  const cellSelected = useSelector((state) => state.map.cellSelected);
  const cellData = useSelector((state) => state.map.cellData);
  const cellSpecies = useSelector((state) => state.map.cellSpecies);
  //const [sortedCellSpecies, setSortedCellSpecies] = useState([]);
  const [speciesSortOrder, setSpeciesSortOrder] = useState('DESC');
  const [speciesSortCriteria, setSpeciesSortCriteria] = useState('sensitivity');
  const cellSites = useSelector((state) => state.map.cellSites);
  const loadingSitesData = useSelector((state) => state.map.loadingSitesData);
  const loadingSpeciesData = useSelector(
    (state) => state.map.loadingSpeciesData
  );
  const rightPanelMinimized = useSelector(
    (state) => state.config.rightPanelMinimized
  );

  // Hardcoded values related to sensitivity rank
  const sensitivityNames = {
    1: 'Low',
    2: 'Moderate',
    3: 'High',
    4: 'Very High',
  };

  const endangerCategory = {
    EX: 'Extinct (EX)',
    EW: 'Extinct in the Wild (EW)',
    CR: 'Critically Endangered (CR)',
    EN: 'Endangered (EN)',
    VU: 'Vulnerable (VU)',
    NT: 'Near Threatened (NT)',
    LC: 'Least Concern (LC)',
  };

  const endangerMessage = {
    EX: 'Facing extremely high risk of extinction in the wild.',
    EW: 'Known only to survive in captivity, cultivation or well outside its natural range.',
    CR: 'Facing extremely high risk of extinction in the wild.',
    EN: 'Facing a very high risk of extinction in the wild.',
    VU: 'Facing a high risk of extinction in the wild.',
    NT: 'Close to qualifying, or likely to qualify for a threatened category in the near future.',
    LC: 'Population is stable enough that is it unlikely to face extinction in the near future.',
  };

  const confidenceName = {
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Very High',
  };

  const sensitivityDescriptions = {
    1: 'Development is considered to pose a low risk to bird populations. However, comprehensive site-level assessment is necessary to confirm the absence of significant risk.',
    2: 'Development is considered to pose a moderate risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk.',
    3: 'Development is considered to pose a high risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk. ',
    4: 'Development is considered to pose a very high risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk. ',
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  // Set the Right Hand Panel visibility
  useEffect(() => {
    setPanelVisibility(cellSelected);
  }, [cellSelected]);

  useEffect(() => {
    setMinimizePanel(rightPanelMinimized);
  }, [rightPanelMinimized]);

  useEffect(() => {
    if (mapState.energy != null && mapState.energy) {
      setEnergy(mapState.energy.code);
      setSpeciesSortOrder('DESC');
      switch (mapState.energy.code) {
        case 'OFFSHORE':
          sortSpecies('sensitivity_offshore_collision', cellSpecies, false);
          break;
        case 'ONSHORE':
          sortSpecies('sensitivity_wind', cellSpecies, false);
          break;
        case 'PWDIST':
          sortSpecies('sensitivity_pw_collision', cellSpecies, false);
          break;
        case 'PWTRAN':
          sortSpecies('sensitivity_pw_collision', cellSpecies, false);
          break;
        default:
          break;
      }
    }
  }, [cellSpecies]);

  useEffect(() => {
    mapStateRef.current = mapState;
    if (mapStateRef.current.country && mapStateRef.current.energy) {
      switch (mapStateRef.current.country) {
        case 'IND':
          setImage(
            RightPanelImageSource['INDIA' + mapStateRef.current.energy.code]
          );
          break;
        case 'NPL':
          setImage(
            RightPanelImageSource['NEPAL' + mapStateRef.current.energy.code]
          );
          break;
        case 'THA':
          setImage(
            RightPanelImageSource['THAILAND' + mapStateRef.current.energy.code]
          );
          break;
        case 'VNM':
          setImage(
            RightPanelImageSource['VIETNAM' + mapStateRef.current.energy.code]
          );
          break;
        //added new countires
        case 'KEN':
          setImage(
            RightPanelImageSource['KENYA' + mapStateRef.current.energy.code]
          );
          break;
        case 'LAO':
          setImage(
            RightPanelImageSource['LAOS' + mapStateRef.current.energy.code]
          );
          break;
        case 'UZB':
          setImage(
            RightPanelImageSource[
              'UZBEKISTAN' + mapStateRef.current.energy.code
            ]
          );
          break;
        case 'EGY':
          setImage(
            RightPanelImageSource['EGYPT' + mapStateRef.current.energy.code]
          );
          break;
        default:
          break;
      }
    }
  }, [mapState]);

  // Parse all the available data
  useEffect(() => {
    if (cellData != null) {
      setLatitude(formatLatitude(cellData.lat));
      setLongitude(formatLongitude(cellData.long));
      // sort landcover
      let tempLandCover = [
        {
          name: 'Bare or Sand',
          value: cellData.landcover_bare,
          class_name: 'landcover-bare',
        },
        {
          name: 'Cultivated',
          value: cellData.landcover_cultivated,
          class_name: 'landcover-agricultural',
        },
        {
          name: 'Forest',
          value: cellData.landcover_forest,
          class_name: 'landcover-forest',
        },
        {
          name: 'Herbaceous',
          value: cellData.landcover_herbaceous,
          class_name: 'landcover-herbaceous',
        },
        {
          name: 'Wetlands',
          value: cellData.landcover_herbaceous_wetlands,
          class_name: 'landcover-wetlands',
        },
        {
          name: 'Moss',
          value: cellData.landcover_moss_lichen,
          class_name: 'landcover-moss',
        },
        {
          name: 'Water',
          value: cellData.lc_permanent_waterbodies,
          class_name: 'landcover-water',
        },
        {
          name: 'Shrub',
          value: cellData.landcover_shrub,
          class_name: 'landcover-shrub',
        },
        {
          name: 'Snow and Ice',
          value: cellData.landcover_snowice,
          class_name: 'landcover-snow',
        },
        {
          name: 'Urban',
          value: cellData.landcover_urban,
          class_name: 'landcover-urban',
        },
      ];
      if (tempLandCover)
        tempLandCover.sort((a, b) => (a.value < b.value ? 1 : -1));

      setLandCover(tempLandCover);

      setChartData({
        datasets: [
          {
            data: [
              cellData.landcover_urban,
              cellData.landcover_cultivated,
              cellData.landcover_shrub,
              cellData.landcover_herbaceous,
              cellData.landcover_bare,
              cellData.landcover_forest,
              cellData.landcover_moss_lichen,
              cellData.landcover_herbaceous_wetlands,
              cellData.lc_permanent_waterbodies,
              cellData.landcover_snowice,
            ],
            backgroundColor: [
              '#D73027', // Urban
              '#F46D43', // Agricultural
              '#FDAE61', // Shrubs
              '#FEE090', // Herbaceous
              '#FFFFBF', // Bare
              '#8AD293', // Forest
              '#E0F3F8', // Moss
              '#ABD9E9', // Wetlands
              '#74ADD1', // Water
              '#5692E1', // Snow and Ice
            ],
            borderWidth: 0,
          },
        ],
      });

      if (mapState.energy != null && mapState.energy) {
        setEnergy(mapState.energy.code);
        switch (mapState.energy.code) {
          case 'OFFSHORE':
            setImageName('Offshore Windmills');
            setTitle('OFFSHORE WIND');
            if (cellData != null) {
              if (cellData.wind_sensitivity) {
                setSensitivityScore(cellData.wind_sensitivity);
              }
              if (cellData.wind_sensitivity_rank) {
                setSensitivityName(
                  sensitivityNames[cellData.wind_sensitivity_rank]
                );
                setSensitivityDescription(
                  sensitivityDescriptions[cellData.wind_sensitivity_rank]
                );
              }
            }
            break;
          case 'ONSHORE':
            setImageName('Onshore Windmills');
            setTitle('ONSHORE WIND');
            if (cellData != null) {
              if (cellData.wind_sensitivity) {
                setSensitivityScore(cellData.wind_sensitivity);
              }
              if (cellData.wind_sensitivity_rank) {
                setSensitivityName(
                  sensitivityNames[cellData.wind_sensitivity_rank]
                );
                setSensitivityDescription(
                  sensitivityDescriptions[cellData.wind_sensitivity_rank]
                );
              }
            }
            break;
          case 'PWDIST':
            setImageName('Distribution Power Lines');
            setTitle('POWER DISTRIBUTION');
            if (cellData != null) {
              if (cellData.pw_distr_sensitivity) {
                setSensitivityScore(cellData.pw_distr_sensitivity);
              }
              if (cellData.pw_distr_sensitivity_rank) {
                setSensitivityName(
                  sensitivityNames[cellData.pw_distr_sensitivity_rank]
                );
                setSensitivityDescription(
                  sensitivityDescriptions[cellData.pw_distr_sensitivity_rank]
                );
              }
            }
            break;
          case 'PWTRAN':
            setImageName('Transmission Power Lines');
            setTitle('POWER TRANSMISSION');
            if (cellData != null) {
              if (cellData.pw_trans_sensitivity) {
                setSensitivityScore(cellData.pw_trans_sensitivity);
              }
              if (cellData.pw_trans_sensitivity_rank) {
                setSensitivityName(
                  sensitivityNames[cellData.pw_trans_sensitivity_rank]
                );
                setSensitivityDescription(
                  sensitivityDescriptions[cellData.pw_trans_sensitivity_rank]
                );
              }
            }
            break;
          case 'SOLAR':
            setImageName('Photovoltaic Solar Panels');
            setTitle('SOLAR PHOTOVOLTAIC');
            if (cellData != null) {
              if (cellData.solar_sensitivity) {
                setSensitivityScore(cellData.solar_sensitivity);
              }
              if (cellData.solar_sensitivity_rank) {
                setSensitivityName(
                  sensitivityNames[cellData.solar_sensitivity_rank]
                );
                setSensitivityDescription(
                  sensitivityDescriptions[cellData.solar_sensitivity_rank]
                );
              }
            }
            break;
          default:
            break;
        }
      }
    }

    if (
      cellSites &&
      ((cellSites.iba_terrestrial &&
        cellSites.iba_terrestrial.length > 0 &&
        energy != 'OFFSHORE') ||
        (cellSites.protected_terrestrial &&
          cellSites.protected_terrestrial.length > 0 &&
          energy != 'OFFSHORE') ||
        (cellSites.iba_marinecoastal &&
          cellSites.iba_marinecoastal.length > 0) ||
        (cellSites.protected_marinecoastal &&
          cellSites.protected_marinecoastal.length > 0) ||
        (cellSites.seabird_colonies && cellSites.seabird_colonies.length > 0))
    ) {
      setContainsSensitiveSites(true);
      showSenitiveSites();
    } else {
      setContainsSensitiveSites(false);
      showSensitiveSpecies();
      if (energy == 'SOLAR') {
        showLandCover();
      }
    }
  }, [cellData, mapState, loadingSitesData, loadingSpeciesData]);

  // Sort Species
  const sortSpecies = (criteria, tempCellSpecies, changeSortOrder) => {
    var tempSpeciesSortOrder = 'DESC';
    if (tempCellSpecies) {
      if (!changeSortOrder || speciesSortCriteria != criteria) {
        tempSpeciesSortOrder = 'DESC';
      } else {
        if (speciesSortOrder == 'ASC') {
          tempSpeciesSortOrder = 'DESC';
        } else {
          tempSpeciesSortOrder = 'ASC';
        }
      }
      if (tempSpeciesSortOrder == 'DESC') {
        if (criteria == 'common_name') {
          tempCellSpecies.sort((a, b) =>
            a.common_name < b.common_name ? 1 : -1
          );
        } else if (criteria == 'scientific_name') {
          tempCellSpecies.sort((a, b) =>
            a.scientific_name < b.scientific_name ? 1 : -1
          );
        } else if (criteria == 'sensitivity_offshore_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_collision < b.sensitivity_offshore_collision
              ? 1
              : -1
          );
        } else if (criteria == 'sensitivity_offshore_displaceme') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_displaceme <
            b.sensitivity_offshore_displaceme
              ? 1
              : -1
          );
        } else if (criteria == 'sensitivity_pw_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_collision < b.sensitivity_pw_collision ? 1 : -1
          );
        } else if (criteria == 'sensitivity_pw_elec') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_elec < b.sensitivity_pw_elec ? 1 : -1
          );
        } else if (criteria == 'sensitivity_wind') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_wind < b.sensitivity_wind ? 1 : -1
          );
        } else if (criteria == 'sps_confidence') {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? 1 : -1
          );
        } else {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? 1 : -1
          );
        }
      } else if (tempSpeciesSortOrder == 'ASC') {
        if (criteria == 'common_name') {
          tempCellSpecies.sort((a, b) =>
            a.common_name < b.common_name ? -1 : 1
          );
        } else if (criteria == 'scientific_name') {
          tempCellSpecies.sort((a, b) =>
            a.scientific_name < b.scientific_name ? -1 : 1
          );
        } else if (criteria == 'sensitivity_offshore_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_collision < b.sensitivity_offshore_collision
              ? -1
              : 1
          );
        } else if (criteria == 'sensitivity_offshore_displaceme') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_offshore_displaceme <
            b.sensitivity_offshore_displaceme
              ? -1
              : 1
          );
        } else if (criteria == 'sensitivity_pw_collision') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_collision < b.sensitivity_pw_collision ? -1 : 1
          );
        } else if (criteria == 'sensitivity_pw_elec') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_pw_elec < b.sensitivity_pw_elec ? -1 : 1
          );
        } else if (criteria == 'sensitivity_wind') {
          tempCellSpecies.sort((a, b) =>
            a.sensitivity_wind < b.sensitivity_wind ? -1 : 1
          );
        } else if (criteria == 'sps_confidence') {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? -1 : 1
          );
        } else {
          tempCellSpecies.sort((a, b) =>
            a.sps_confidence < b.sps_confidence ? -1 : 1
          );
        }
      }
    }
    setSpeciesSortOrder(tempSpeciesSortOrder);
    setSortedCellSpecies([...tempCellSpecies]);
    setSpeciesSortCriteria(criteria);
  };

  // Show Sensitive Sites Panel
  const showSenitiveSites = () => {
    setShowSensitiveFeaturesPanel(false);
    setShowSensitiveSpeciesPanel(false);
    setShowLandCoverPanel(false);
    setShowSensitiveSitesPanel(true);
  };

  // Show Sensitive Species Panel
  const showSensitiveSpecies = () => {
    setShowSensitiveFeaturesPanel(false);
    setShowLandCoverPanel(false);
    setShowSensitiveSitesPanel(false);
    setShowSensitiveSpeciesPanel(true);
  };

  // Show Land Cover Panel
  const showLandCover = () => {
    setShowSensitiveFeaturesPanel(false);
    setShowSensitiveSpeciesPanel(false);
    setShowSensitiveSitesPanel(false);
    setShowLandCoverPanel(true);
  };

  // Show Sensitive Features Panel
  const showSensitiveFeatures = () => {
    setShowSensitiveSpeciesPanel(false);
    setShowLandCoverPanel(false);
    setShowSensitiveSitesPanel(false);
    setShowSensitiveFeaturesPanel(true);
  };

  const handleMouseOverSpecieTab = (e) => {
    if (e.currentTarget.className.includes('species-row'))
      e.currentTarget.classList.add('hide-image');
  };

  const handleMouseOutFromSpecieTab = (e) => {
    if (e.currentTarget.className.includes('species-row'))
      e.currentTarget.classList.remove('hide-image');
  };

  const handleMouseOverSpecieImage = (e) => {
    e.currentTarget.classList.add('show');
  };

  const handleMouseOutFromSpecieImage = (e) => {
    e.currentTarget.classList.remove('show');
  };

  // Build the HTML
  return (
    <>
      {panelVisible && !minimizePanel && (
        <div className='right-panel'>
          <div className='top-section'>
            <img
              className='top-image'
              alt='{imageName}'
              src={image !== false ? image : undefined}
            />
            <div className='location vertical-center'>
              <div className='location-icon'>
                <img
                  className='location-icon icon vertical-center'
                  alt='Location'
                  src={
                    IconSource['LOCATIONPIN']
                      ? IconSource['LOCATIONPIN']
                      : undefined
                  }
                />
              </div>
              <div>
                {latitude}, {longitude}
              </div>
            </div>

            <div className='title'>{title}</div>
            <div className='sub-title'>Energy Assessment</div>
          </div>
          <div className='sensitivity-score'>
            <div className='sentitivity-score-title'>Sensitivity Score</div>
            <div className='sentitivity-score-value'>
              <NumberFormat
                suffix={'%'}
                decimalScale={0}
                displayType='text'
                value={sensitivityScore * 100}
              />
            </div>
          </div>
          <div className='sensitiviy-description'>
            Sensitivity assessed as {sensitivityName}. {sensitivityDescription}
          </div>

          {!containsSensitiveSites && (
            <div className='tabs row'>
              {energy == 'OFFSHORE' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Species
                </div>
              )}
              {energy == 'PWDIST' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Sensitive Species
                </div>
              )}
              {energy == 'PWTRAN' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Sensitive Species
                </div>
              )}
              {energy == 'ONSHORE' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Sensitive Species
                </div>
              )}

              {energy == 'ONSHORE' && (
                <div
                  className={
                    showLandCoverPanel ? 'tab-header active' : 'tab-header'
                  }
                  onClick={showLandCover}
                >
                  Landcover Sensitivity
                </div>
              )}
              {energy == 'SOLAR' && (
                <div className={'tab-header active'} onClick={showLandCover}>
                  Landcover Sensitivity
                </div>
              )}
              {energy == 'OFFSHORE' &&
                cellSites &&
                ((cellSites.habitat_mangrove &&
                  cellSites.habitat_mangrove.length > 0) ||
                  (cellSites.habitat_seagrass &&
                    cellSites.habitat_seagrass.length > 0) ||
                  (cellSites.habitat_coralreef &&
                    cellSites.habitat_coralreef.length > 0)) && (
                  <div
                    className={
                      showSensitiveFeaturesPanel
                        ? 'tab-header active'
                        : 'tab-header'
                    }
                    onClick={showSensitiveFeatures}
                  >
                    Sensitive Habitats
                  </div>
                )}
            </div>
          )}
          {containsSensitiveSites && (
            <div className='tabs3 row'>
              <div
                className={
                  showSensitiveSitesPanel
                    ? 'tab-header sensitive-site-tab'
                    : 'tab-header'
                }
                onClick={showSenitiveSites}
              >
                Sensitive Sites
              </div>

              {energy == 'OFFSHORE' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Species
                </div>
              )}
              {energy == 'PWDIST' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Sensitive Species
                </div>
              )}
              {energy == 'PWTRAN' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Sensitive Species
                </div>
              )}
              {energy == 'ONSHORE' && (
                <div
                  className={
                    showSensitiveSpeciesPanel
                      ? 'tab-header active'
                      : 'tab-header'
                  }
                  onClick={showSensitiveSpecies}
                >
                  Sensitive Species
                </div>
              )}

              {energy == 'ONSHORE' && (
                <div
                  className={
                    showLandCoverPanel ? 'tab-header active' : 'tab-header'
                  }
                  onClick={showLandCover}
                >
                  Landcover Sensitivity
                </div>
              )}
              {energy == 'SOLAR' && (
                <div
                  className={
                    showLandCoverPanel ? 'tab-header active' : 'tab-header'
                  }
                  onClick={showLandCover}
                >
                  Landcover Sensitivity
                </div>
              )}
              {energy == 'OFFSHORE' &&
                cellSites &&
                ((cellSites.habitat_mangrove &&
                  cellSites.habitat_mangrove.length > 0) ||
                  (cellSites.habitat_seagrass &&
                    cellSites.habitat_seagrass.length > 0) ||
                  (cellSites.habitat_coralreef &&
                    cellSites.habitat_coralreef.length > 0)) && (
                  <div
                    className={
                      showSensitiveFeaturesPanel
                        ? 'tab-header active'
                        : 'tab-header'
                    }
                    onClick={showSensitiveFeatures}
                  >
                    Sensitive Habitats
                  </div>
                )}
            </div>
          )}
          {showSensitiveSitesPanel && !loadingSitesData && (
            <div className='sites-tab'>
              <div className='sites-tab-header'>
                <div className='row sites-header'>
                  <div className='sites-title'>
                    Sites (
                    {(cellSites.iba_terrestrial
                      ? cellSites.iba_terrestrial.length && energy != 'OFFSHORE' //Fix for offshore site count
                      : 0) +
                      (cellSites.iba_marinecoastal && energy == 'OFFSHORE'
                        ? cellSites.iba_marinecoastal.length
                        : 0) +
                      (cellSites.protected_terrestrial && energy != 'OFFSHORE'
                        ? cellSites.protected_terrestrial.length
                        : 0) +
                      (cellSites.protected_marinecoastal && energy == 'OFFSHORE'
                        ? cellSites.protected_marinecoastal.length
                        : 0) +
                      (cellSites.seabird_colonies
                        ? cellSites.seabird_colonies.length
                        : 0)}
                    )
                  </div>
                  <div className='sites-title'>Type</div>
                </div>
              </div>
              <div className='sites-tab-body'>
                {cellSites &&
                  cellSites.iba_terrestrial &&
                  energy != 'OFFSHORE' &&
                  cellSites.iba_terrestrial.map((site, index) => {
                    return (
                      <React.Fragment key={'iba_terrestrial' + index}>
                        {site.HYPERLINK && (
                          <div
                            key={site.SitRecID + 'iba_terrestrial' + index}
                            className='sites-row pointer'
                            onClick={() => openInNewTab(site.HYPERLINK)}
                          >
                            <div
                              key={site.IntName + 'iba_terrestrial' + index}
                              className='site-text'
                            >
                              {site.IntName}
                            </div>
                            <div
                              key={
                                site.SitRecID +
                                'iba_terrestrial' +
                                site.IntName +
                                '_' +
                                index
                              }
                              className='site-text'
                            >
                              Important Bird Area
                            </div>
                          </div>
                        )}
                        {!site.HYPERLINK && (
                          <div
                            key={site.SitRecID + 'iba_terrestrial' + index}
                            className='sites-row'
                          >
                            <div
                              key={site.IntName + 'iba_terrestrial' + index}
                              className='site-text'
                            >
                              {site.IntName}
                            </div>
                            <div
                              key={
                                site.SitRecID +
                                'iba_terrestrial' +
                                site.IntName +
                                '_' +
                                index
                              }
                              className='site-text'
                            >
                              Important Bird Area
                            </div>
                          </div>
                        )}
                        <div
                          key={site.SitRecID + 'iba_terrestrial_row' + index}
                          className='separation-row'
                        >
                          &nbsp;
                        </div>
                      </React.Fragment>
                    );
                  })}
                {cellSites &&
                  cellSites.iba_marinecoastal &&
                  cellSites.iba_marinecoastal.length > 0 &&
                  energy == 'OFFSHORE' &&
                  cellSites.iba_marinecoastal.map((site, index) => {
                    return (
                      <React.Fragment key={'iba_marinecoastal' + index}>
                        {site.HYPERLINK && (
                          <div
                            key={site.IntName + 'iba_marinecoastal' + index}
                            className='sites-row pointer'
                            onClick={() => openInNewTab(site.HYPERLINK)}
                          >
                            <div
                              key={
                                site.IntName + 'iba_marinecoastal_name' + index
                              }
                              className='site-text'
                            >
                              {site.IntName}
                            </div>
                            <div
                              key={
                                site.IntName + 'iba_marinecoastal_area' + index
                              }
                              className='site-text'
                            >
                              Important Bird Area
                            </div>
                          </div>
                        )}
                        {!site.HYPERLINK && (
                          <div
                            key={site.IntName + 'iba_marinecoastal' + index}
                            className='sites-row'
                          >
                            <div
                              key={
                                site.IntName + 'iba_marinecoastal_name' + index
                              }
                              className='site-text'
                            >
                              {site.IntName}
                            </div>
                            <div
                              key={
                                site.IntName + 'iba_marinecoastal_area' + index
                              }
                              className='site-text'
                            >
                              Important Bird Area
                            </div>
                          </div>
                        )}
                        <div
                          key={
                            site.IntName + 'iba_marinecoastal_arearow' + index
                          }
                          className='separation-row'
                        >
                          &nbsp;
                        </div>
                      </React.Fragment>
                    );
                  })}
                {cellSites &&
                  cellSites.protected_terrestrial &&
                  energy != 'OFFSHORE' &&
                  cellSites.protected_terrestrial.map((site, index) => {
                    return (
                      <React.Fragment key={'protected_terrestrial' + index}>
                        {site.HYPERLINK && (
                          <div
                            key={site.NAME + 'protected_terrestrial' + index}
                            className='sites-row pointer'
                            onClick={() => openInNewTab(site.HYPERLINK)}
                          >
                            <div
                              key={
                                site.IntName +
                                'protected_terrestrial_name' +
                                index
                              }
                              className='site-text'
                            >
                              {site.NAME}
                            </div>
                            <div
                              key={
                                site.IntName +
                                'protected_terrestrial_area' +
                                index
                              }
                              className='site-text'
                            >
                              {site.DESIG_ENG}
                            </div>
                          </div>
                        )}
                        {!site.HYPERLINK && (
                          <div
                            key={site.NAME + '_' + index}
                            className='sites-row'
                          >
                            <div
                              key={
                                site.IntName +
                                'protected_terrestrial_name' +
                                index
                              }
                              className='site-text'
                            >
                              {site.NAME}
                            </div>
                            <div
                              key={
                                site.IntName +
                                'protected_terrestrial_area' +
                                index
                              }
                              className='site-text'
                            >
                              {site.DESIG_ENG}
                            </div>
                          </div>
                        )}
                        <div
                          key={
                            site.IntName +
                            'protected_terrestrial_area_row' +
                            index
                          }
                          className='separation-row'
                        >
                          &nbsp;
                        </div>
                      </React.Fragment>
                    );
                  })}
                {cellSites &&
                  cellSites.protected_marinecoastal &&
                  cellSites.protected_marinecoastal.length > 0 &&
                  energy == 'OFFSHORE' &&
                  cellSites.protected_marinecoastal.map((site, index) => {
                    return (
                      <React.Fragment key={'protected_marinecoastal' + index}>
                        {site.HYPERLINK && (
                          <div
                            key={site.NAME + 'protected_marinecoastal' + index}
                            className='sites-row pointer'
                            onClick={() => openInNewTab(site.HYPERLINK)}
                          >
                            <div
                              key={
                                site.IntName +
                                'protected_marinecoastal_name' +
                                index
                              }
                              className='site-text'
                            >
                              {site.NAME}
                            </div>
                            <div
                              key={
                                site.IntName +
                                'protected_marinecoastal_name' +
                                index
                              }
                              className='site-text'
                            >
                              {site.DESIG_ENG}
                            </div>
                          </div>
                        )}
                        {!site.HYPERLINK && (
                          <div
                            key={site.NAME + 'protected_marinecoastal' + index}
                            className='sites-row'
                          >
                            <div
                              key={
                                site.IntName +
                                'protected_marinecoastal_name' +
                                index
                              }
                              className='site-text'
                            >
                              {site.NAME}
                            </div>
                            <div
                              key={
                                site.IntName +
                                'protected_marinecoastal_name' +
                                index
                              }
                              className='site-text'
                            >
                              {site.DESIG_ENG}
                            </div>
                          </div>
                        )}
                        <div
                          key={
                            site.IntName +
                            'protected_marinecoastal_name_row' +
                            index
                          }
                          className='separation-row'
                        >
                          &nbsp;
                        </div>
                      </React.Fragment>
                    );
                  })}
                {cellSites &&
                  cellSites.seabird_colonies &&
                  cellSites.seabird_colonies.length > 0 &&
                  cellSites.seabird_colonies.map((colony, index) => {
                    return (
                      <React.Fragment key={'seabird_colonies' + index}>
                        {colony.HYPERLINK && (
                          <div
                            key={
                              colony.common_name + 'seabird_colonies' + index
                            }
                            className='sites-row pointer'
                            onClick={() => openInNewTab(colony.HYPERLINK)}
                          >
                            <div
                              key={
                                colony.common_name +
                                'seabird_colonies_name' +
                                index
                              }
                              className='site-text'
                            >
                              {colony.common_name}
                            </div>
                            <div
                              key={
                                colony.common_name +
                                'seabird_colonies_area' +
                                index
                              }
                              className='site-text'
                            >
                              Searbird Colony &amp; Foraging Radius
                            </div>
                          </div>
                        )}
                        {!colony.HYPERLINK && (
                          <div
                            key={
                              colony.common_name + 'seabird_colonies' + index
                            }
                            className='sites-row'
                          >
                            <div
                              key={
                                colony.common_name +
                                'seabird_colonies_name' +
                                index
                              }
                              className='site-text'
                            >
                              {colony.common_name}
                            </div>
                            <div
                              key={
                                colony.common_name +
                                'seabird_colonies_area' +
                                index
                              }
                              className='site-text'
                            >
                              Searbird Colony &amp; Foraging Radius
                            </div>
                          </div>
                        )}
                        <div
                          key={
                            colony.common_name +
                            'seabird_colonies_area_row' +
                            index
                          }
                          className='separation-row'
                        >
                          &nbsp;
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          )}
          {showSensitiveSpeciesPanel &&
            energy != 'SOLAR' &&
            !loadingSpeciesData && (
              <div className='species-tab'>
                <div className='species-tab-header'>
                  {energy == 'PWDIST' && (
                    <div className='row species-header species-header-pwdist'>
                      <div
                        onClick={() =>
                          sortSpecies('common_name', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Species ({cellSpecies ? cellSpecies.length : 0})
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies(
                            'sensitivity_pw_collision',
                            sortedCellSpecies,
                            true
                          )
                        }
                        className='pointer'
                      >
                        Collision
                        {speciesSortCriteria == 'sensitivity_pw_collision' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sensitivity_pw_collision' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies(
                            'sensitivity_pw_elec',
                            sortedCellSpecies,
                            true
                          )
                        }
                        className='pointer'
                      >
                        Electrocution
                        {speciesSortCriteria == 'sensitivity_pw_elec' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sensitivity_pw_elec' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies('sps_confidence', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Occurrence Certainty
                        {speciesSortCriteria == 'sps_confidence' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sps_confidence' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                    </div>
                  )}
                  {energy == 'PWTRAN' && (
                    <div className='row species-header species-header-pwtran'>
                      <div
                        onClick={() =>
                          sortSpecies('common_name', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Species ({cellSpecies ? cellSpecies.length : 0})
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies(
                            'sensitivity_pw_collision',
                            sortedCellSpecies,
                            true
                          )
                        }
                        className='pointer'
                      >
                        Collision
                        {speciesSortCriteria == 'sensitivity_pw_collision' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sensitivity_pw_collision' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies('sps_confidence', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Occurrence Certainty
                        {speciesSortCriteria == 'sps_confidence' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sps_confidence' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                    </div>
                  )}
                  {energy == 'ONSHORE' && (
                    <div className='row species-header species-header-onshore'>
                      <div
                        onClick={() =>
                          sortSpecies('common_name', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Species ({cellSpecies ? cellSpecies.length : 0})
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies(
                            'sensitivity_wind',
                            sortedCellSpecies,
                            true
                          )
                        }
                        className='pointer'
                      >
                        Sensitivity
                        {speciesSortCriteria == 'sensitivity_wind' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sensitivity_wind' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies('sps_confidence', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Occurrence Certainty
                        {speciesSortCriteria == 'sps_confidence' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'sps_confidence' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                    </div>
                  )}
                  {energy == 'OFFSHORE' && (
                    <div className='row species-header species-header-offshore'>
                      <div
                        onClick={() =>
                          sortSpecies('common_name', sortedCellSpecies, true)
                        }
                        className='pointer'
                      >
                        Species ({cellSpecies ? cellSpecies.length : 0})
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria == 'common_name' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies(
                            'sensitivity_offshore_collision',
                            sortedCellSpecies,
                            true
                          )
                        }
                        className='pointer'
                      >
                        Collision
                        {speciesSortCriteria ==
                          'sensitivity_offshore_collision' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria ==
                          'sensitivity_offshore_collision' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                      <div
                        onClick={() =>
                          sortSpecies(
                            'sensitivity_offshore_displaceme',
                            sortedCellSpecies,
                            true
                          )
                        }
                        className='pointer'
                      >
                        Displacement
                        {speciesSortCriteria ==
                          'sensitivity_offshore_displaceme' &&
                          speciesSortOrder == 'ASC' && (
                            <img src={upArrow} className='sort-arrow' />
                          )}
                        {speciesSortCriteria ==
                          'sensitivity_offshore_displaceme' &&
                          speciesSortOrder == 'DESC' && (
                            <img src={downArrow} className='sort-arrow' />
                          )}
                      </div>
                    </div>
                  )}
                </div>
                <div className='species-tab-body'>
                  {sortedCellSpecies &&
                    energy == 'PWDIST' &&
                    sortedCellSpecies.map((specie, index) => {
                      return (
                        <div
                          key={specie.sps_code + '_' + index}
                          className='row species-row species-pwdist'
                          onClick={() => openInNewTab(specie.bl_factsheet)}
                          onMouseOver={handleMouseOverSpecieTab}
                          onMouseOut={handleMouseOutFromSpecieTab}
                        >
                          {BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <img
                                className='species'
                                alt='{specie.common_name}'
                                src={
                                  BirdSpeciesImageSource[specie.scientific_name]
                                }
                              />
                              <div
                                className='species-endanger-classification endanger-classification'
                                onMouseOver={handleMouseOverSpecieImage}
                                onMouseOut={handleMouseOutFromSpecieImage}
                              >
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>

                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          {!BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <div className='species-endanger-classification'>
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>
                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className='spec-name'>
                            <div className='common-name'>
                              {specie.common_name}
                            </div>
                            <div className='latin-name'>
                              {specie.scientific_name}
                            </div>
                          </div>

                          {specie.sensitivity_pw_collision > 0 && (
                            <div className=''>
                              <NumberFormat
                                decimalScale={0}
                                displayType='text'
                                value={specie.sensitivity_pw_collision * 100}
                              />
                            </div>
                          )}
                          {specie.sensitivity_pw_collision <= 0 && (
                            <div className=''> &ndash; </div>
                          )}

                          {specie.sensitivity_pw_elec > 0 && (
                            <div className=''>
                              <NumberFormat
                                decimalScale={0}
                                displayType='text'
                                value={specie.sensitivity_pw_elec * 100}
                              />
                            </div>
                          )}
                          {specie.sensitivity_pw_elec <= 0 && (
                            <div className=''> &ndash; </div>
                          )}

                          <div className=''>
                            {confidenceName[specie.sps_confidence]}
                          </div>
                        </div>
                      );
                    })}
                  {sortedCellSpecies &&
                    energy == 'PWTRAN' &&
                    sortedCellSpecies.map((specie, index) => {
                      return (
                        <div
                          key={specie.sps_code + '_' + index}
                          className='row species-row species-pwtran'
                          onClick={() => openInNewTab(specie.bl_factsheet)}
                          onMouseOver={handleMouseOverSpecieTab}
                          onMouseOut={handleMouseOutFromSpecieTab}
                        >
                          {BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <img
                                className='species'
                                alt='{specie.common_name}'
                                src={
                                  BirdSpeciesImageSource[specie.scientific_name]
                                }
                              />
                              <div
                                className='species-endanger-classification endanger-classification'
                                onMouseOver={handleMouseOverSpecieImage}
                                onMouseOut={handleMouseOutFromSpecieImage}
                              >
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>

                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          {!BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <div className='species-endanger-classification'>
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>
                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className='spec-name'>
                            <div className='common-name'>
                              {specie.common_name}
                            </div>
                            <div className='latin-name'>
                              {specie.scientific_name}
                            </div>
                          </div>

                          {specie.sensitivity_pw_collision > 0 && (
                            <div className=''>
                              <NumberFormat
                                decimalScale={0}
                                displayType='text'
                                value={specie.sensitivity_pw_collision * 100}
                              />
                            </div>
                          )}
                          {specie.sensitivity_pw_collision <= 0 && (
                            <div className=''> &ndash; </div>
                          )}

                          <div className=''>
                            {confidenceName[specie.sps_confidence]}
                          </div>
                        </div>
                      );
                    })}
                  {sortedCellSpecies &&
                    energy == 'ONSHORE' &&
                    sortedCellSpecies.map((specie, index) => {
                      return (
                        <div
                          key={specie.sps_code + '_' + index}
                          className='row species-row species-onshore'
                          onClick={() => openInNewTab(specie.bl_factsheet)}
                          onMouseOver={handleMouseOverSpecieTab}
                          onMouseOut={handleMouseOutFromSpecieTab}
                        >
                          {BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <img
                                className='species'
                                alt='{specie.common_name}'
                                src={
                                  BirdSpeciesImageSource[specie.scientific_name]
                                }
                              />
                              <div
                                className='species-endanger-classification endanger-classification'
                                onMouseOver={handleMouseOverSpecieImage}
                                onMouseOut={handleMouseOutFromSpecieImage}
                              >
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>

                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          {!BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <div className='species-endanger-classification'>
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>
                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className='spec-name'>
                            <div className='common-name'>
                              {specie.common_name}
                            </div>
                            <div className='latin-name'>
                              {specie.scientific_name}
                            </div>
                          </div>

                          <div className=''>
                            <NumberFormat
                              decimalScale={0}
                              displayType='text'
                              value={specie.sensitivity_wind * 100}
                            />
                          </div>

                          <div className=''>
                            {confidenceName[specie.sps_confidence]}
                          </div>
                        </div>
                      );
                    })}
                  {sortedCellSpecies &&
                    energy == 'OFFSHORE' &&
                    sortedCellSpecies.map((specie, index) => {
                      return (
                        <div
                          key={specie.sps_code + '_' + index}
                          className='row species-row species-offshore'
                          onClick={() => openInNewTab(specie.bl_factsheet)}
                          onMouseOver={handleMouseOverSpecieTab}
                          onMouseOut={handleMouseOutFromSpecieTab}
                        >
                          {BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <img
                                className='species'
                                alt='{specie.common_name}'
                                src={
                                  BirdSpeciesImageSource[specie.scientific_name]
                                }
                              />
                              <div
                                className='species-endanger-classification endanger-classification'
                                onMouseOver={handleMouseOverSpecieImage}
                                onMouseOut={handleMouseOutFromSpecieImage}
                              >
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>

                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          {!BirdSpeciesImageSource[specie.scientific_name] && (
                            <div
                              style={{ display: 'flex', height: '3.6rem' }}
                              onMouseOver={handleMouseOverSpecieImage}
                              onMouseOut={handleMouseOutFromSpecieImage}
                            >
                              <div className='species-endanger-classification'>
                                <div style={{ margin: 'auto' }}>
                                  {specie.rl_category}
                                </div>
                              </div>
                              <div className='popuptext'>
                                <span className='endanger-header'>
                                  {endangerCategory[specie.rl_category]}
                                </span>
                                <br />
                                <span className='endanger-body'>
                                  {endangerMessage[specie.rl_category]}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className='spec-name font9'>
                            <div className='common-name'>
                              {specie.common_name}
                            </div>
                            <div className='latin-name'>
                              {specie.scientific_name}
                            </div>
                          </div>

                          <div className=''>
                            <NumberFormat
                              decimalScale={0}
                              displayType='text'
                              value={
                                specie.sensitivity_offshore_collision * 100
                              }
                            />
                          </div>

                          <div className=''>
                            <NumberFormat
                              decimalScale={0}
                              displayType='text'
                              value={
                                specie.sensitivity_offshore_displaceme * 100
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          {showLandCoverPanel && (energy == 'ONSHORE' || energy == 'SOLAR') && (
            <div className='land-cover-panel'>
              <Doughnut data={chartData} options={chartOptions} />
              <div className='land-cover-labels'>
                {landCover.map((land, index) => {
                  if (land.value > 1)
                    return (
                      land.value > 0 && (
                        <p
                          key={land.name + '_' + index}
                          className={land.class_name}
                        >
                          {land.name}:
                          <NumberFormat
                            suffix={'%'}
                            decimalScale={0}
                            displayType='text'
                            value={land.value}
                          />
                        </p>
                      )
                    );
                  else
                    return (
                      land.value > 0 && (
                        <p
                          key={land.name + '_' + index}
                          className={land.class_name}
                        >
                          {land.name}: &lt; 1%
                        </p>
                      )
                    );
                })}
              </div>
            </div>
          )}
          {showSensitiveFeaturesPanel && energy == 'OFFSHORE' && (
            <div className='sensitive-features-panel'>
              {(!cellSites ||
                ((!cellSites.habitat_mangrove ||
                  cellSites.habitat_mangrove.length == 0) &&
                  (!cellSites.habitat_seagrass ||
                    cellSites.habitat_seagrass.length == 0) &&
                  (!cellSites.habitat_coralreef ||
                    cellSites.habitat_coralreef.length == 0))) && (
                <div>
                  No sensitive features have been documented in this given area.
                </div>
              )}
              {cellSites &&
                ((cellSites.habitat_mangrove &&
                  cellSites.habitat_mangrove.length > 0) ||
                  (cellSites.habitat_seagrass &&
                    cellSites.habitat_seagrass.length > 0) ||
                  (cellSites.habitat_coralreef &&
                    cellSites.habitat_coralreef.length > 0)) && (
                  <div className='sensitive-features-tab-header'>
                    Geographic Features (
                    {(cellSites.habitat_mangrove &&
                    cellSites.habitat_mangrove.length
                      ? 1
                      : 0) +
                      (cellSites.habitat_seagrass &&
                      cellSites.habitat_seagrass.length
                        ? 1
                        : 0) +
                      (cellSites.habitat_coralreef &&
                      cellSites.habitat_coralreef.length
                        ? 1
                        : 0)}
                    )
                  </div>
                )}
              {cellSites &&
                cellSites.habitat_mangrove &&
                cellSites.habitat_mangrove.length > 0 && (
                  <>
                    <div className='features-row'>
                      <div className='feature-text'>
                        <img
                          className='species'
                          alt='Mangrove'
                          src={RightPanelImageSource['MANGROVE']}
                        />
                      </div>
                      <div className='feature-text'>Mangrove</div>
                    </div>
                    <div className='separation-row'>&nbsp;</div>
                  </>
                )}
              {cellSites &&
                cellSites.habitat_seagrass &&
                cellSites.habitat_seagrass.length > 0 && (
                  <>
                    <div className='features-row'>
                      <div className='feature-text'>
                        <img
                          className='species'
                          alt='Seagrass'
                          src={RightPanelImageSource['SEAGRASS']}
                        />
                      </div>
                      <div className='feature-text'>Seagrass</div>
                    </div>
                    <div className='separation-row'>&nbsp;</div>
                  </>
                )}
              {cellSites &&
                cellSites.habitat_coralreef &&
                cellSites.habitat_coralreef.length > 0 && (
                  <>
                    <div className='features-row'>
                      <div className='feature-text'>
                        <img
                          className='species'
                          alt='Coral Reef'
                          src={RightPanelImageSource['CORALREEF']}
                        />
                      </div>
                      <div className='feature-text'>Coral Reef</div>
                    </div>
                    <div className='separation-row'>&nbsp;</div>
                  </>
                )}
            </div>
          )}
          {(loadingSpeciesData || loadingSitesData) && (
            <div className='progress'>
              <CircularProgress color='inherit' size='4rem' />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RightPanel;
