import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import DropDown from "../../../ui/DropDown";
import { FlagIconSource } from "../../../../utils/imagesource";
import { formatLatitude, formatLongitude } from "../../../../utils/map";
import './Breadcrumbs.css';


const Breadcrumbs = (props) => {
    const mapStateRef = useRef({});
    const [country, setCountry] = useState(null);
    const [region, setRegion] = useState(false);
    const [cell, setCell] = useState(false);
    const mapState = useSelector(state => state.map.mapState);
    const countries = useSelector(state => state.config.countries);
    const regions = useSelector(state => state.config.regions);
    const cellSelected = useSelector(state => state.map.cellSelected);
    let countryData = countries.map((country) =>{
        country.iconref = FlagIconSource[country?.code] || null;
        return country
    })
    const [countryOptions, setCountryOptions] = useState(countryData);

    useEffect(() => {

    }, [])

    useEffect(() => {
        mapStateRef.current = mapState;
        if (mapStateRef.current.country){
            let countrycurrent = countries.find(countryitem => countryitem.code == mapStateRef.current.country);
            if (countrycurrent){ setCountry(countrycurrent)}
        }
        if (mapStateRef.current.region != null && !mapStateRef.current.lat){
            let regiondata = regions[mapStateRef.current.region];
            setRegion({label: regions[mapStateRef.current.region], regioncode: mapStateRef.current.region})
            setCell(false);
        } else if (mapStateRef.current.lat) {
            setRegion(false);
            let lat = Number(mapStateRef.current.lat);
            let long = Number(mapStateRef.current.long);
            setCell(`${formatLatitude(lat)}, ${formatLongitude(long)}`)
        } else{
            setRegion(false);
            setCell(false);
        }

    }, [mapState])

    const countryChange = (value) => {
        setCountry(value);
        props.countrySelect(value);
      };

    
    return (
            <div className="breadcrumbs-root mapcategory2">
                <div className="breadcrumbs-country">
                    {country && <DropDown options={countryOptions} selected={country} onChange={countryChange} onExpand={() => props.toggleDropdown(true)} onClose={() => props.toggleDropdown(false)}/>
                    }
                </div>
                {region && !cell && <div className="breadcrumbs-region"> {region.label} </div>}
                {cell && <div className="breadcrumbs-cell"> {cell} </div>}
            </div>

    )

}

export default Breadcrumbs;

