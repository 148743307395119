import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import * as watchUtils from '@arcgis/core/core/watchUtils';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter';
import FeatureEffect from '@arcgis/core/layers/support/FeatureEffect';

import MapTooltip from '../../../ui/MapTooltip';
import { filterCountry, clearCountry } from '../../../../redux/reducers/map';

function changeCursor(response) {
  if (response.results.length > 0) {
    document.getElementById('map-view-container').style.cursor = 'pointer';
  } else {
    document.getElementById('map-view-container').style.cursor = 'default';
  }
}

const CountryLayer = (props) => {
  // Ref for Layer
  const layerTitle = 'avistep_countries';
  const lyrRef = useRef(null);
  const highlightGraphic = useRef(null);
  const watchHover = useRef(null);
  const mapStateRef = useRef({});

  // Layer Loaded state
  let [lyrLoaded, setLyrLoaded] = useState(false);
  let [countries, setCountries] = useState(null);
  let [showPopup, setShowPopup] = useState(false);
  let [countryLabel, setCountryLabel] = useState('');

  const config = useSelector((state) => state.config);
  const mapState = useSelector((state) => state.map.mapState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addHighlight = (graphic) => {
    let highlightID = highlightGraphic.current
      ? highlightGraphic.current.attributes?.OBJECTID
      : null;
    if (highlightID != graphic.attributes.OBJECTID) {
      props.mapView.graphics.removeAll();
      let sym = {
        type: 'simple-fill',
        outline: { width: 1.75, color: [255, 255, 255, 1] },
        color: [0, 0, 0, 0],
      };
      graphic.symbol = sym;
      highlightGraphic.current = graphic;
      props.mapView.graphics.add(highlightGraphic.current);
    }
  };

  const updateVisibility = () => {
    if (!mapStateRef.current.country) {
      lyrRef.current.visible = true;
    } else {
      lyrRef.current.visible = false;
    }
  };

  const selectCountry = useCallback(
    async (graphic) => {
      if (!mapState.region) {
        let query = {
          where: `objectid = ${graphic.getObjectId()}`,
          returnGeometry: false,
          outFields: ['*'],
        };
        let results = await lyrRef.current.queryFeatures(query);
        if (results.features.length > 0) {
          let countrycode = results.features[0].attributes['GID_0'];
          const params = new URLSearchParams({ country: countrycode });
          navigate({
            pathname: location.pathname,
            search: params.toString(),
            replace: true,
          });
        }
      }
    },
    [mapState]
  );

  const updateWatchers = useCallback(() => {
    if (!mapStateRef.current.country) {
      if (watchHover.current == null) {
        watchHover.current = props.mapView.on('pointer-move', (evt) => {
          var screenPoint = { x: evt.x, y: evt.y };
          props.mapView
            .hitTest(screenPoint, {
              include: lyrRef.current,
            })
            .then((response) => {
              changeCursor(response);
              if (!mapStateRef.current.country && response.results.length > 0) {
                addHighlight(response.results[0].graphic.clone());
                setCountryLabel(
                  response.results[0].graphic.attributes['NAME_0']
                );
                setShowPopup(true);
              } else {
                setShowPopup(false);
                setCountryLabel('');
                highlightGraphic.current = null;
                props.mapView.graphics.removeAll();
              }
            });
        });
      }
    } else {
      if (watchHover.current) {
        watchHover.current.remove();
        watchHover.current = null;
        setShowPopup(false);
      }
    }
  });

  useEffect(() => {
    mapStateRef.current = mapState;
    (async () => {
      if (props.mapView && !lyrLoaded) {
        mapStateRef.current = mapState;
        let countryLyr = props.mapView.map.layers.find(
          (layer) => layer.title === layerTitle
        );

        if (countryLyr) {
          // If country boundary layer is present, update its visibility
          lyrRef.current = countryLyr;
          let query = {};
          query.where = `1=1`;
          query.returnGeometry = true;
          query.outFields = ['*'];
          let results = await countryLyr.queryFeatures(query);
          let countryref = {};
          results.features.map((item) => {
            if (countryref[item.attributes['GID_0']]) {
              countryref[item.attributes['GID_0']].push(item);
            } else {
              countryref[item.attributes['GID_0']] = [item];
            }
          }, {});
          setCountries(countryref);
          setLyrLoaded(true);
          if (mapState.country && !mapState.region && !mapState.lat) {
            let countryGraphics = countryref[mapState.country];
            let ext = countryGraphics[0].geometry.extent;
            let cloneExt = ext.clone();

            props.mapView.goTo({
              target: countryGraphics,
              extent: cloneExt.expand(1.2),
              
              duration: 4000,
              easing: 'ease-out',
            });

            dispatch(filterCountry(countryGraphics));
            lyrRef.current.visible = false;
          }
          updateWatchers();
          updateVisibility();
        }
      }
    })();
  }, []);

  useEffect(() => {
    mapStateRef.current = mapState;
    (async () => {
      if (lyrLoaded) {
        updateVisibility();
        if (
          countries &&
          mapState.country &&
          !mapState.region &&
          !mapState.lat
        ) {
          let countryGraphics = countries[mapState.country];

          let ext = countryGraphics[0].geometry.extent;
          let cloneExt = ext.clone();

          props.mapView.goTo({
            target: countryGraphics,
            extent: cloneExt.expand(1.2),
            duration: 4000,
            easing: 'ease-out',
          });
          dispatch(filterCountry(countryGraphics));
          // const featureFilter = new FeatureFilter({
          //     where: `ID_0='${mapState.country}'`
          //   });
          // lyrRef.current.featureEffect = new FeatureEffect({
          // filter: featureFilter,
          // includedEffect: "grayscale(100%) opacity(0%)",
          // excludedEffect: "drop-shadow(3px, 3px, 3px, black)"
          //});
        } else if (countries && !mapState.country) {
          lyrRef.current.featureEffect = null;
          dispatch(clearCountry());
        }
        updateWatchers();
      }
    })();
  }, [mapState]);

  useEffect(() => {
    if (lyrLoaded) {
      props.layerStatus(true);
      props.mapView.whenLayerView(lyrRef.current).then( (lview)=> {
        /* watchUtils.whenFalseOnce(lview, 'updating', function () {
          props.mapView.on('click', async (evt) => {
            var screenPoint = { x: evt.x, y: evt.y };
            let response = await props.mapView.hitTest(screenPoint, {
              include: lyrRef.current,
            });
            if (response.results.length > 0) {
              selectCountry(response.results[0].graphic);
              highlightGraphic.current = null;
              props.mapView.graphics.removeAll();
            }
          });
        }); */
        reactiveUtils.when(
          () => !lview.updating,
          () => {

            props.mapView.on(
              'click',
              async (evt) => {
                var screenPoint = { x: evt.x, y: evt.y };
                let response = await props.mapView.hitTest(screenPoint, {
                  include: lyrRef.current,
                });
                if (response.results.length > 0) {
                  selectCountry(response.results[0].graphic);
                  highlightGraphic.current = null;
                  props.mapView.graphics.removeAll();
                }
              },
              {
                once: true,
              }
            );
          }
        );
      });
    }
  }, [lyrLoaded]);

  return (
    <>
      {
        <MapTooltip
          isOpen={showPopup}
          displaytext={countryLabel}
          mapView={props.mapView}
        />
      }
    </>
  );
};

export default CountryLayer;
