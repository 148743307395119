import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from '../../utils/imagesource';



const DropDownContainer = styled("div")`
  margin: 0 auto;
  color: inherit;
`;

const DropDownHeader = styled("div")`
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: inherit;
  background: none;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 9px;
  justify-items: start;
  justify-content: start;
`;

const DropDownListContainer = styled("div")`
    padding-top: 5px;
    z-index: 5;
    position: fixed;
    width: 165px;
    background: rgba(35, 62, 111, 1);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
`;

const DropDownList = styled("ul")`
  padding: 6px;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  justify-items: center;
  padding-top: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
  
`;

const Img = styled("img")`
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0.3rem;
`;


const DropDown = (props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.selected);
  const down = Icon("EXPANDMORE");
  const [downArrow, setDownArrow] = useState(down);
  const up = Icon("EXPANDLESS");
  const [upArrow, setUpArrow] = useState(up);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    //setSelectedOption(value);
    setIsOpen(false);
    props.onChange(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };
    if (isOpen){
      document.addEventListener('click', handleClickOutside, true);
      props.onExpand();
    }else { 
      props.onClose();
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen])


  return (
      <DropDownContainer ref={ref}>
        <DropDownHeader onClick={toggling}>
          {!isOpen && <Img src={downArrow} />}
          {isOpen && <Img src={upArrow} />}
          {props.selected.label || ""}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
            {props.options.map((option, index) => {
                return <ListItem onClick={onOptionClicked(option)} key={index}>
                  {option.label}
                  {option.iconref && <Img src={option.iconref} height="15px" />}
                  </ListItem>
            })}             
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
  );
}

export default DropDown;