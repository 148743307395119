import React, { useState } from "react";
import "./ButtonGroup.css";

const ButtonGroup = ({ selected, buttons, selectHandler }) => {
  const [clickedId, setClickedId] = useState(-1);

  const handleClick = (event, id) => {
    selected = buttons[id];
    setClickedId(id);
    selectHandler(event);
  };

  return (
    <>
      {buttons.map((buttonLabel, i) => (
        <button
          key={i}
          name={buttonLabel}
          onClick={(event) => handleClick(event, i)}
          className={selected === buttonLabel ? "customButton active" : "customButton"}
        >
          {buttonLabel}
        </button>
      ))}
    </>
  );
};

export default ButtonGroup;
