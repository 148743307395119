import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

//Redux Imports
import {
  setMapReferenceData,
  setSpeciesReference,
} from '../../../../redux/reducers/config';
import {
  updateMapState,
  clearMap,
  filterRegion,
} from '../../../../redux/reducers/map';

import alert_icon from '../../../../styles/icons/alert_icon.png';
// Esri imports
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
//import SceneView from "@arcgis/core/views/SceneView";
import WebMap from '@arcgis/core/WebMap';
import esriConfig from '@arcgis/core/config';
//import * as watchUtils from "@arcgis/core/core/watchUtils";
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

// Map Components
import SensitivityGridLayer from '../layers/SensitivityGridLayer';
import CountryLayer from '../layers/CountryLayer';
import RegionalBoundaries from '../layers/RegionalBoundaries';
import RegionalEnergy from '../layers/RegionalEnergy';

// Styled Components
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
`;

const useStyles = makeStyles((props) => {
  return {
    scaleB: {
      position: 'absolute',
      bottom: '30px',
      right: (props) => `${props.right}`,
      zIndex: '3',
    },
    maptoast: {
      position: 'absolute',
      display: 'grid',
      top: '1.5rem',
      left: '35vw',
      width: '30%',
      zIndex: '3',
      borderRadius: '4px',
      minHeight: '2rem',
      alignItems: 'center',
      textAlign: 'left',
      justifyContent: 'left',
      flexDirection: 'row',
      backgroundColor: 'white',
      borderRadius: '0.2rem',
      gridTemplateAreas: `"icon message"
                            "icon message"`,
    },
    toasticon: {
      gridArea: 'icon',
      height: '100%',
      width: '2.5rem',
      display: 'flex',
    },
    img: {
      height: '0.9rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      margin: 'auto',
    },
    toastmessage: {
      gridArea: 'message',
      display: 'flex',
      flexDirection: 'column',
    },
    toasttitle: {
      paddingTop: '0.4rem',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '0.55rem',
      lineHeight: '100%',
      color: '#0C1B3D',
      letterSpacing: '0.07em',
      textTransform: 'uppercase',
    },
    toastdesc: {
      paddingBottom: '0.4rem',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.6rem',
      lineHeight: '120%',
      color: '#0C1B3D',
    },
  };
});

// Component
const Map = (props) => {
  const containerID = 'map-view-container';
  const mapDiv = useRef(null);
  const mapViewRef = useRef(null);
  const scalebarDiv = useRef(null);
  const mapStateRef = useRef({});
  const filterLayerRef = useRef(null);
  let location = useLocation();
  const navigate = useNavigate();
  const [mapViewLoaded, setMapViewLoaded] = useState(false);
  const [mapDataLoaded, setMapDataLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [right, setRightPosition] = useState('28vw');
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const dataloaded = useSelector((state) => state.config.reference_data_loaded);
  const cellSelected = useSelector((state) => state.map.cellSelected);
  const mapState = useSelector((state) => state.map.mapState);
  const countryFilter = useSelector((state) => state.map.countryFilter);
  const regionFilter = useSelector((state) => state.map.regionFilter);
  const rightPanelMinimized = useSelector(
    (state) => state.config.rightPanelMinimized
  );
  const classes = useStyles({ right });
  let animation;

  function addWorldFilter(world) {
    world = new Graphic({
      geometry: {
        type: 'extent',
        xmin: -180,
        xmax: 180,
        ymin: -90,
        ymax: 90,
      },
      symbol: {
        type: 'simple-fill',
        color: 'rgba(255, 255, 255, 1)',
        outline: null,
      },
    });
    filterLayerRef.current.graphics.add(world);
    return world;
  }

  function fadeWorld(world) {
    let timer;
    // requestAnimationFrame method specifies "frame" function
    // to perform an animation where the opacity of the world polygon filter
    // decreased by 0.05 until it is at .15 opacity
    // then the animation is cancelled
    function frame() {
      const symbol = world.symbol.clone();
      symbol.color.a = Math.max(0, symbol.color.a - 0.05);
      world.symbol = symbol;
      if (symbol.color.a > 0.1) {
        timer = requestAnimationFrame(frame);
      }
    }
    frame();
    return {
      remove() {
        cancelAnimationFrame(timer);
      },
    };
  }

  function filterView(graphic) {
    if (filterLayerRef.current) {
      filterLayerRef.current.graphics.removeAll();
      animation && animation.remove();
      let world = addWorldFilter();
      if (graphic) {
        filterLayerRef.current.graphics.add(graphic);
        animation = fadeWorld(world);
      }
    }
  }

  useEffect(() => {
    let loaded = false;
    let scaleWatch = false;
    (async () => {
      try {
        if (true) {
          let webmapID = config.webmapID;
          var mapConfig = {
            portalItem: { id: webmapID, portal: { url: config.portal } },
            center: [79.48189730121511, 23.49926361971663],
            zoom: 4,
          };

          // load the webmap
          const map = new WebMap({
            ...mapConfig,
          });

          map.when(async () => {});

          // eslint-disable-next-line
          const view = new MapView({
            map,
            container: mapDiv.current,
            center: [79.48189730121511, 23.49926361971663],
            zoom: 4,
            constraints: { minZoom: 3, snapToZoom: false },
          });
          //padding for the left panel
          let paddingWidth = document.getElementsByClassName("left-main")[0].clientWidth;
          view.padding.left = paddingWidth;

          view.ui._removeComponents(['zoom']);
          view.when(async () => {
            mapViewRef.current = view;
            view.popup = null;
            // Override portal layer settings
            view.map.layers.forEach((layer) => {
              layer.visible = false;
              layer.outFields = ['*'];
              if (layer.type === 'feature') {
                layer.popupEnabled = false;
              }
              // set showSitesLayers to true to see all sites layers on the map
              if (config.showSitesLayers) {
                if (config.sitesLayers.includes(layer.title)) {
                  layer.visible = true;
                }
              }
            });
            const filterViewLayer = new GraphicsLayer({
              id: 'filter-layer',
              blendMode: 'destination-in',
              effect: 'bloom(200%)',
            });

            let world = new Graphic({
              geometry: {
                type: 'extent',
                xmin: -180,
                xmax: 180,
                ymin: -90,
                ymax: 90,
              },
              symbol: {
                type: 'simple-fill',
                color: 'rgba(255, 255, 255, 1)',
                outline: null,
              },
            });
            filterViewLayer.graphics.add(world);
            view.map.basemap.baseLayers.getItemAt(0).blendMode = 'multiply';
            view.map.basemap.baseLayers.add(filterViewLayer);
            filterLayerRef.current = filterViewLayer;

            let basemapWatch = view.map.watch('basemap', (basemap) => {
              // When a basemap is changed, add the masking filterLayer to it
              view.map.basemap.when(async () => {
                view.map.basemap.baseLayers.getItemAt(0).blendMode = 'multiply';
                view.map.basemap.baseLayers.add(filterViewLayer);
              });
            });

            let scaleBar = new ScaleBar({
              view: view,
              container: scalebarDiv.current,
              unit: 'dual',
            });

            scaleWatch = view.watch('scale', (scale) => {
              let inScaleRange = scale < 1155583 ? true : false;
              if (
                !inScaleRange &&
                mapStateRef.current.country &&
                mapStateRef.current.energy
              ) {
                setShowToast(true);
              } else if (
                inScaleRange &&
                mapStateRef.current.country &&
                mapStateRef.current.energy
              ) {
                setShowToast(false);
              }
            });

            if (!loaded) {
              let regionslyr = view.map.layers.find(
                (layer) => layer.title === 'avistep_internal_boundaries'
              );
              let regions = {};
              if (regionslyr) {
                let results = await regionslyr.queryFeatures({
                  where: `1=1`,
                  returnGeometry: false,
                  outFields: ['GID_1', 'NAME_1'],
                });
                regions = results.features.reduce(
                  (prevObj, feature) => ({
                    ...prevObj,
                    [feature.attributes['GID_1']]: feature.attributes['NAME_1'],
                  }),
                  {}
                );
              }

              //SPECIES REFERENCE
              let species_reference = {};
              const buildSpeciesReference = async () => {
                let species_lookup_tables = view.map.tables.filter((layer) =>
                  layer.title.includes('_lookup_species')
                );
                species_lookup_tables.forEach(async (lookuptable) => {
                  var country = lookuptable.title.split('_')[1];
                  var speciesresults = await lookuptable.queryFeatures({
                    where: `1=1`,
                    returnGeometry: false,
                    outFields: ['*'],
                  });
                  var species = speciesresults.features.reduce(
                    (prevObj, feature) => ({
                      ...prevObj,
                      [`${feature.attributes['sps_code']}`]: feature.attributes,
                    }),
                    {}
                  );
                  species_reference[country] = { ...species };
                  dispatch(setSpeciesReference(country, species));
                });
              };
              await buildSpeciesReference();

              dispatch(setMapReferenceData(regions));
              setMapViewLoaded(true);
            }
          });
        }
      } catch (err) {
        console.log('ERR', err);
        dispatch({
          payload: {
            error: err,
            msg: 'map failed to load',
          },
          type: 'MAP_ERROR',
        });
      }
    })();
    return () => {
      dispatch(clearMap());
      loaded = false;
      if (scaleWatch) {
        scaleWatch.remove();
      }
    };
  }, []);

  useEffect(() => {
    mapStateRef.current = mapState;
    if (mapViewRef.current) {
      let inScaleRange = mapViewRef.current.scale < 1155583 ? true : false;
      if (
        !inScaleRange &&
        mapStateRef.current.country &&
        mapStateRef.current.region &&
        mapStateRef.current.energy
      ) {
        setShowToast(true);
      } else if (
        inScaleRange &&
        mapStateRef.current.country &&
        mapStateRef.current.region &&
        mapStateRef.current.energy
      ) {
        setShowToast(false);
      }
    }
  }, [mapState]);

  useEffect(() => {
    if (dataloaded) {
      // Whenever url changes (query parameters), grab param and update map state
      let param = Object.fromEntries([...new URLSearchParams(location.search)]);

      dispatch(
        updateMapState({
          param: param,
          path: location.pathname,
          navigate: navigate,
        })
      );
    }
  }, [dataloaded, location]);

  useEffect(() => {
    if (mapViewLoaded) {
      props.mapViewReady(mapViewRef.current);
    }
  }, [mapViewLoaded]);

  useEffect(() => {
    if (cellSelected && !rightPanelMinimized) {
      if (window.innerWidth >= 1000) setRightPosition('28vw');
      else setRightPosition('280px');
    } else {
      setRightPosition('6rem');
    }
  }, [cellSelected, rightPanelMinimized]);

  useEffect(() => {
    if (regionFilter) {
      filterView(regionFilter);
    } else if (countryFilter) {
      filterView(countryFilter);
    } else {
      filterView(null);
    }
  }, [countryFilter, regionFilter]);

  // Map Container
  return (
    <Container>
      <div ref={scalebarDiv} className={classes.scaleB} />
      <Container id={containerID} ref={mapDiv}>
        {mapViewLoaded && (
          <CountryLayer
            mapView={mapViewRef.current}
            layerStatus={props.layersStatus}
          />
        )}
        {mapViewLoaded && dataloaded && (
          <RegionalBoundaries
            mapView={mapViewRef.current}
            layerStatus={props.layersStatus}
          />
        )}
        {mapViewLoaded && (
          <RegionalEnergy
            mapView={mapViewRef.current}
            layerStatus={props.layersStatus}
          />
        )}
        {mapViewLoaded && <SensitivityGridLayer mapView={mapViewRef.current} />}
      </Container>
      {showToast && (
        <div className={classes.maptoast}>
          <div className={classes.toasticon}>
            <img
              className={classes.img}
              alt='alert'
              src={alert_icon ? alert_icon : undefined}
            ></img>
          </div>
          <div className={classes.toastmessage}>
            <div className={classes.toasttitle}>WHERE TO NEXT?</div>
            <div className={classes.toastdesc}>
              Zoom in to view a spatial assessment of avian sensitivity at a
              local level, provided at a resolution of 5km x 5km.
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Map;
