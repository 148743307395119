

import styled from "styled-components";

const Caption = styled.div`
    font-family: 'Hind Madurai';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03em;
`;

export default Caption;