import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import homepage from "../package.json";
import App from './components/App';
import reportWebVitals from './reportWebVitals';
//import '@arcgis/core/assets/esri/themes/light/main.css';

// Redux //
import { Provider } from "react-redux";
import { initStore } from "./redux/store";

// React Router //
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';


// App runs at the root locally, but under /{homepage} in production
let basename;
process.env.NODE_ENV !== "production" ? (basename = "") : (basename = homepage.homepage);


var disableTelem = !(document.location.hostname.search("avistep.birdlife.org") !== -1 || document.location.hostname.search("avistep.dvgdev.com") !== -1);
var disableAJAX = (document.location.hostname.search("avistep.birdlife.org") !== -1);

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=a0d36274-21c5-401c-9923-e0fbd5bf71d1;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/',
        enableAutoRouteTracking: true,
        disableCookiesUsage: true,
        disableTelemetry: disableTelem,
        disableAjaxTracking: disableAJAX,
        disableFetchTracking: disableAJAX,
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();

export const store = initStore();

function RenderReactDOM() {
  let route = <Route path="/*" element={<App />} />

  return ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
            <BrowserRouter basename={basename}>
              <Routes>
                {route}
              </Routes>  
            </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

RenderReactDOM()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
