import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  PDFViewer } from '@react-pdf/renderer';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { Icon } from '../../utils/imagesource';
import CellReport from "./reports/CellReport";
import CountryReport from "./reports/CountryReport";
import CellMap from "./reports/maps/CellMap";
import CountryMap from "./reports/maps/CountryMap";
import "./PDFModal.css";


const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: "black"
    },
    barColorPrimary: {
      backgroundColor: "#0083CD"
    }
  })(LinearProgress);


const PDFModal = (props) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const mapStateRef = useRef({});
  const [countryDoc, setCountryDoc] = useState(false);
  const [cellDoc, setCellDoc] = useState(false);
  const [generateCountryMap, setGenerateCountryMap] = useState(false);
  const [generateCellMap, setGenerateCellMap] = useState(false);
  const [showCountryReport, setShowCountryReport] = useState(false);
  const [showCellReport, setShowCellReport] = useState(false);
  const mapState = useSelector(state => state.map.mapState);
  const countryReportStatus = useSelector(state => state.map.countryReportStatus);
  const cellReportStatus = useSelector(state => state.map.cellReportStatus);
  const countryReportData = useSelector(state => state.map.countryReportData);
  const cellReportData = useSelector(state => state.map.cellReportData);



  useEffect(() => {
    
  }, [])

  useEffect(() => {
    mapStateRef.current = mapState;
}, [mapState]);

  useEffect(() => {
    if (countryReportStatus == "pending"){
        setGenerateCountryMap(true);
        setGenerateCellMap(false);
    }else{
        setGenerateCountryMap(false);
    }
  }, [countryReportStatus]);

  useEffect(() => {
    if (cellReportStatus == "pending"){
        setGenerateCountryMap(false);
        setGenerateCellMap(true);
    }else{
        setGenerateCellMap(false);
    }
  }, [cellReportStatus]);


  useEffect(() => {
    if (countryReportStatus == "complete" && countryReportData){
        generateCountryReport(countryReportData);
    }else{
        setShowCountryReport(false);
    }
  }, [countryReportStatus,countryReportData]);


  useEffect(() => {
    if (cellReportStatus == "complete" && cellReportData){
        generateCellReport(cellReportData);
    }else{
        setShowCellReport(false);
    }
  }, [cellReportStatus,cellReportData]);


  const generateCountryReport = (data) => {
    setCountryDoc(<CountryReport data={data}/>)
    setShowCountryReport(true);
  };

  const generateCellReport = (data) => {
    setCellDoc(<CellReport data={data}/>)
    setShowCellReport(true);
  };


  return (
    <div className="pdf-modalbackground">
      <div className="pdf-root">
          <CloseSharpIcon className="pdf-close" onClick={() => { props.onClose()}} fontSize="medium" style={{ color: "#FFFFFF", float: "left" }} />            
          <div className="pdf-maps">
              <div></div>
              {generateCountryMap && <div className="pdf-mapcontainer">
                  <CountryMap />
                  </div>
              }
              {generateCellMap && <div className="pdf-mapcontainer">
                  <CellMap />
                  </div>
              }
              <div></div>
          </div>

          <div className="pdf-view">
              <div className="pdf-header"></div>
              {!showCountryReport && !showCellReport && 
                    <div className="pdf-progress">
                        <div>Generating Report...</div>
                        <CircularProgress color="inherit" size="4rem"/>
                    </div>
                }
                {showCountryReport && countryDoc && <PDFViewer className="pdf-viewer">
                    {countryDoc}
                </PDFViewer>}
                {showCellReport && cellDoc && <PDFViewer className="pdf-viewer">
                    {cellDoc}
                </PDFViewer>}
                
            </div>
      </div>
      </div>
  );
}

export default PDFModal;