
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.1) no-repeat center/cover;
  background-blend-mode: screen;
`;

const Wrapper = styled.div`
  position: relative;
  width: 260px;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
`;
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

const LoadScreen = props => {
    const classes = useStyles();
  return (
    <Container>
      <Wrapper>
        <div className={classes.root}>
        <LinearProgress color="primary" />
        </div>
      </Wrapper>
    </Container>
  );
};

export default LoadScreen;
