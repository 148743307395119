import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useHistory, Redirect, Outlet, Link, Navigate } from "react-router-dom";
import './Main.css';

//Component Imports
import ContentContainer from "./ui/ContentContainer";
import Header from "./pages/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Acknowledgements from "./pages/Acknowledgements";
import Contact from "./pages/Contact";
import Avistep from "./pages/avistep/Avistep";
import Legal from "./pages/Legal";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function Base() {
  return (
    <div className="wrapper">
      <section className="content">
        <Outlet />
      </section>
    </div>
  );
}

function Main() {
  const showTool = useSelector(state => state.config.showTool);
  return (
    
    <ContentContainer>
        <Routes>
            <Route path="/*" element={<Base />} >
            <Route path="" element={<Home />}  />
            {showTool && <Route path="about" element={<About currentState='general' />} />}
            {showTool && <Route path="general" element={<About currentState='general' />} />}
            {showTool && <Route path="help" element={<About currentState='help' />} />}
            {showTool && <Route path="acknowledgements" element={<About currentState='acknowledgements' />} />}
            {showTool && <Route path="contact" element={<About currentState='contact' />} />}
            {showTool && <Route path="map" element={<Avistep />} />}
            {showTool && <Route path="legal" element={<Legal />} />}
            {showTool && <Route path="terms" element={<Legal currentState='terms' />} />}
            {showTool && <Route path="privacy" element={<Legal currentState='privacy' />} />}
            {/* Catch-all for URLs that we don't have explicit routes for. */}
            <Route path="*" element={<Navigate to="/" />}/>
            </Route>
        </Routes>
    </ContentContainer>  
  );
}



export default Main;
