
/**
 * Combine Sagas into one output
 */

 import { all, fork } from 'redux-saga/effects';
 import * as mapSagas from './_map';
 import * as configSagas from './_config';
 import * as routeSagas from './_route';

 
 export default function* rootSaga() {
     yield all([
     ...Object.values(mapSagas),
     ...Object.values(configSagas),
     ...Object.values(routeSagas),
     // more sagas from different files
   ].map(fork));
 }
 