import React from "react";
import { IconSource } from "../../utils/imagesource";
import LowSensitivityCard from '../../styles/images/low_sensitivity_card.png';
import ModerateSensitivityCard from '../../styles/images/moderate_sensitivity_card.png';
import HighSensitivityCard from '../../styles/images/high_sensitivity_card.png';
import VeryHighSensitivityCard from '../../styles/images/very_high_sensitivity_card.png';
// TODO: Make the cards, don't use an image

const Help = () => {
  
  return (
    <div className="help">
      <h1>How to Use Avistep</h1>
      <p className="small-paragraph">
      AVISTEP is designed to be intuitive to use, requiring no specialist knowledge; however, we do recommend that first-time users watch the following short tutorial video before exploring the tool.
      </p>
      <div className="instructional-video center">
        <iframe src="https://player.vimeo.com/video/739585448?h=22a751781e&amp;dnt=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" ></iframe>
        <p className="avistep-brochure-label">AVISTEP Instructional Video</p>
      </div>

      <h3>Methodology</h3>
      <div className="container">
        <div className="row">
          <div className="col-6 right-border">
            <div>
              AVISTEP provides users with spatial heat maps depicting potential avian sensitivity in relation to the following types of energy infrastructure:
              <ul>
                <li><img className="icon" alt="Onshore Wind" src={IconSource['ONSHORE']}/>Onshore Wind</li>
                <li><img className="icon" alt="Offshore Wind" src={IconSource['OFFSHORE']}/>Offshore Wind</li>
                <li><img className="icon" alt="Solar Photovoltaic" src={IconSource['SOLAR']}/>Solar Photovoltaic</li>
                <li><img className="icon" alt="Transmission Powerlines" src={IconSource['PWTRAN']}/>Transmission Powerlines (long-distance, high-voltage)</li>
                <li><img className="icon" alt="Distribution Powerlines" src={IconSource['PWDIST']}/>Distribution Powerlines (short-distance, low- and medium-voltage)</li>
              </ul>
            </div>
          </div>
          <div className="col-6">
            <div>
              Each 5km x 5km cell within the heatmaps has a sensitivity score. These scores have been grouped into four categories of sensitivity represented by the following colours:
              <ul>
                <li><img className="sensitivity-card" alt="Low Sensitivity" src={LowSensitivityCard}/></li>
                <li><img className="sensitivity-card" alt="Moderate Sensitivity" src={ModerateSensitivityCard}/></li>
                <li><img className="sensitivity-card" alt="High Sensitivity" src={HighSensitivityCard}/></li>
                <li><img className="sensitivity-card" alt="Very High Sensitivity" src={VeryHighSensitivityCard}/></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="relative">
          A detailed methodology outlining how these scores have been calculated is available in the AVISTEP Technical Manual.
        </div>
        <div className="avistep-technical-manual-container">
          <a href="/AVISTEPTechnicalManual.pdf" target="_blank"><img className="avistep-technical-manual" alt="AviStep Brochure" src="/AVISTEPTechnicalManualCover.jpg"></img></a>
          AVISTEP Technical Manual &amp; <a href="/SupplementaryMaterial.pdf" target="_blank">Supplementary Material</a>
        </div>
      </div>

      <h3>Interpretation &amp; Caveats</h3>
      <p>
      The assessment of avian sensitivity presented in AVISTEP is intended to provide a landscape-scale understanding of the potential risks posed to birds by certain types of energy infrastructure. Such an evaluation of spatial risk will undoubtedly improve early-stage renewable energy planning. However, there is an imperfect knowledge of avian distribution and an incomplete understanding of the factors that make certain bird species more susceptible to impacts from energy infrastructure.
      </p>
      <p>
      By design, the assessments are precautionary and intended to provide an awareness of the at-risk bird species that could be present within an area and what such a species composition might mean for the development of renewable energy and power lines. This information is intended to inform, rather than replace, subsequent site-scale evaluation. It is possible that an area predicted to be of low avian sensitivity could, following further assessment, be shown to have a greater degree of sensitivity. Equally, areas deemed highly sensitive could ultimately be shown to be less sensitive. It is also the case that a highly sensitivity area could still be suitable for development if the correct mitigation measures are implemented. Therefore, those areas depicted as being highly sensitive should not be automatically assumed to be “no-go” areas for development.
      </p>
      <p>
      Instead, the four categories should be interpreted as follows:
      </p>

    <div className="category-card-container">
      <div className="row category-card">
        <div className="col-2">Low</div>
        <div className="col-10">
        Development is considered to pose a low risk to bird populations. However, comprehensive site-level assessment is necessary to confirm the absence of significant risk.
        </div>
      </div>
      <div className="row category-card">
        <div className="col-2">Moderate</div>
        <div className="col-10">
        Development is considered to pose a moderate risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk.
        </div>
      </div>
      <div className="row category-card">
        <div className="col-2">High</div>
        <div className="col-10">
        Development is considered to pose a high risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk. This area may be unsuitable for development and will certainly require mitigation measures.
        </div>
      </div>
      <div className="row category-card">
        <div className="col-2">Very High</div>
        <div className="col-10">
        Development is considered to pose a very high risk to bird populations. However, comprehensive site-level assessment is necessary to confirm this level of risk. This area is likely to be unsuitable for development and will certainly require mitigation measures.
        </div>
      </div>
    </div>

    <div className="row">
    <p>
    Birds are generally regarded as highly indicative of wider biodiversity patterns. In addition, the assessments take account of areas of general importance for nature, such as Protected Areas and wildlife-rich landcover types. Nevertheless, it should be noted that the maps do not necessarily depict areas of sensitivity for other vulnerable wildlife groups such as bats or marine mammals. Further screening and site-based assessment will be needed to adequately assess the risks posed to these and other groups.
    </p>
    <p>
    As well as showing the avian sensitivity across an entire land- or sea- scape, AVISTEP also enables users to see the sensitivity only in those cells that have sufficient wind or solar resource to support the development of wind and solar technologies. The following are well-established industry thresholds for wind and solar resources. 
    </p>
    </div>

    <ul>
      <li><img className="icon" alt="Onshore Wind" src={IconSource['ONSHORE']}/>Onshore Wind - Annual average 100-metre height wind speeds greater than 5 meters/second</li>
      <li><img className="icon" alt="Offshore Wind" src={IconSource['OFFSHORE']}/>Offshore Wind - Annual average 100-metre height wind speeds greater than 7 meters/second</li>
      <li><img className="icon" alt="Solar Photovoltaic" src={IconSource['SOLAR']}/>Solar Photovoltaic - Annual average Global Horizontal Irradiation (GHI) greater than 1,400 kWh/m<span className="super-script">2</span></li>
    </ul>

    <p>
    Clearly, resource potential is only one consideration in assessing the suitability of an area for renewable technologies. Many factors will influence siting, including elevation, topography, grid connectivity, and proximately to dwellings and other human infrastructure. However, there are no internationally agreed thresholds in regard to these constraints and therefore they have not been considered within this assessment. 
    </p>
    </div>
  );
};

export default Help;
