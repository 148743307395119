import React, { useRef, useEffect, useState } from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import HindMaduraiBold from '../../../styles/fonts/HindMadurai-Bold.ttf';
import WorkSans from '../../../styles/fonts/WorkSans-Regular.ttf';
import { ImageReference, IconSource } from '../../../utils/imagesource';

Font.register({
  family: 'HindMaduraiBold',
  format: 'truetype',
  src: HindMaduraiBold,
});
Font.register({
  family: 'Work Sans',
  format: 'truetype',
  src: WorkSans,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  pageview: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignContent: 'space-between',
    height: '100%',
  },
  header: {
    flexGrow: 1,
    height: '18%',
    backgroundColor: '#102646',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  header1: {
    height: '100%',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    aligntContent: 'space-between',
    padding: '20pt',
    gap: '10pt',
  },
  header2: {
    height: '100%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20pt',
    gap: '10pt',
  },
  avistep: {
    fontFamily: 'HindMaduraiBold',
    fontWeight: 700,
    fontSize: '14pt',
    paddingRight: '5pt',
  },
  logo: {
    width: '42pt',
    height: '34pt',
    marginRight: '1pt',
    marginLeft: 'auto',
  },
  pinicon: {
    height: '8pt',
    paddingRight: '5pt',
  },
  tablesection: {
    marginLeft: '20pt',
    marginRight: '20pt',
    flexGrow: 1,
    height: '30%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '25pt',
  },
  tabletitle: {
    textAlign: 'left',
  },
  statstable: {
    width: '100%',
  },
  statsheader: {
    width: '100%',
    height: '20%',
    flexDirection: 'row',
  },
  statsrow: {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '0.75pt',
    borderTopColor: 'rgba(0, 0, 0, .1)',
    borderTopStyle: 'solid',
  },
  statsrowrank: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10pt',
    width: '30%',
    textAlign: 'left',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: '10pt',
  },
  mapsection: {
    marginLeft: '20pt',
    marginRight: '20pt',
    flexGrow: 1,
    height: '40%',
    borderRadius: '3pt',
    paddingTop: '30pt',
  },
  footer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flexGrow: 1,
    height: '12%',
    textAlign: 'center',
  },
  pagenum: {
    color: 'black',
    textAlign: 'center',
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20pt',
    fontSize: '9pt',
    fontFamily: 'Work Sans',
    fontWeight: 400,
  },
});

let logoref = ImageReference['LOGO_TRANSPARENT'] || null;

// Create Document Component
const CountryReport = (props) => {
  const assessmentTitle = () => {
    if (props.data.energy) {
      return `${props.data.energy.label} Assessment`;
    } else {
      return '';
    }
  };
  const suitableTitle = () => {
    if (props.data.energy && props.data.energy.code == 'ONSHORE') {
      return `Avian Sensitivity - Suitable Wind Speed Areas`;
    } else if (props.data.energy && props.data.energy.code == 'SOLAR') {
      return `Avian Sensitivity - Suitable Global Horizontal Irradiance (GHI) Areas Only`;
    } else {
      return '';
    }
  };

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.pageview}>
          <View style={styles.header}>
            <View style={styles.header1}>
              <View
                style={{
                  height: '50%',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10pt',
                }}
              >
                <Text style={styles.avistep}>AVISTEP </Text>
                <Text
                  style={{
                    fontSize: '14pt',
                    fontFamily: 'Work Sans',
                    fontWeight: 300,
                  }}
                >
                  {' '}
                  {props.data.country}
                </Text>
              </View>
              <View style={{ height: '20%', textAlign: 'left' }}>
                <Text
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Work Sans',
                    fontWeight: 400,
                  }}
                >
                  {assessmentTitle()}
                </Text>
              </View>
              <View
                style={{
                  height: '20%',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                {props.data.region && (
                  <Image
                    src={IconSource['LOCATIONPIN_PNG']}
                    style={styles.pinicon}
                  />
                )}
                {props.data.region && (
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                    }}
                  >
                    {props.data.region}, {props.data.country}
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.header2}>
              <View style={{ height: '50%', textAlign: 'right' }}>
                <Image src={logoref} style={styles.logo} />
              </View>
              <View style={{ height: '20%', textAlign: 'right' }}></View>
              <View style={{ height: '20%', textAlign: 'right' }}></View>
            </View>
          </View>
          <View style={styles.tablesection}>
            <View style={styles.tabletitle}>
              <Text
                style={{
                  fontSize: '16pt',
                  fontFamily: 'Work Sans',
                  fontWeight: 400,
                  color: 'black',
                }}
              >
                Avian Sensitivity
              </Text>
            </View>
            <View style={styles.statstable}>
              <View style={styles.statsheader}>
                <View
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    paddingLeft: '10pt',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    Sensitivity Level
                  </Text>
                </View>
                <View
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    Km
                  </Text>
                  <Text
                    style={{
                      fontSize: '6pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      verticalAlign: 'super',
                      paddingBottom: '1pt',
                      paddingRight: '2pt',
                    }}
                  >
                    2
                  </Text>
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    of Country Per Sensitivity Level
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    % of Country
                  </Text>
                </View>
              </View>
              <View style={styles.statsrow}>
                <View style={styles.statsrowrank}>
                  <View
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      height: '15pt',
                      width: '15pt',
                      borderRadius: '2pt',
                      backgroundColor: '#55FF00',
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingLeft: '10pt',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    Low
                  </Text>
                </View>
                <View
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingRight: '2pt',
                    }}
                  >
                    {props.data.page1.stats['1'].area.toLocaleString('en-US')}
                  </Text>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    km
                  </Text>
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingBottom: '1pt',
                    }}
                  >
                    2
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    {props.data.page1.stats['1'].percentage}%
                  </Text>
                </View>
              </View>
              <View style={styles.statsrow}>
                <View style={styles.statsrowrank}>
                  <View
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      height: '15pt',
                      width: '15pt',
                      borderRadius: '2pt',
                      backgroundColor: '#FFFF00',
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingLeft: '10pt',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    Moderate
                  </Text>
                </View>
                <View
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingRight: '2pt',
                    }}
                  >
                    {props.data.page1.stats['2'].area.toLocaleString('en-US')}
                  </Text>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    km
                  </Text>
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingBottom: '1pt',
                    }}
                  >
                    2
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    {props.data.page1.stats['2'].percentage}%
                  </Text>
                </View>
              </View>
              <View style={styles.statsrow}>
                <View style={styles.statsrowrank}>
                  <View
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      height: '15pt',
                      width: '15pt',
                      borderRadius: '2pt',
                      backgroundColor: '#FFAA00',
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingLeft: '10pt',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    High
                  </Text>
                </View>
                <View
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingRight: '2pt',
                    }}
                  >
                    {props.data.page1.stats['3'].area.toLocaleString('en-US')}
                  </Text>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    km
                  </Text>
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingBottom: '1pt',
                    }}
                  >
                    2
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    {props.data.page1.stats['3'].percentage}%
                  </Text>
                </View>
              </View>
              <View style={styles.statsrow}>
                <View style={styles.statsrowrank}>
                  <View
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      height: '15pt',
                      width: '15pt',
                      borderRadius: '2pt',
                      backgroundColor: '#FF0000',
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingLeft: '10pt',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    Very High
                  </Text>
                </View>
                <View
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingRight: '2pt',
                    }}
                  >
                    {props.data.page1.stats['4'].area.toLocaleString('en-US')}
                  </Text>
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    km
                  </Text>
                  <Text
                    style={{
                      fontSize: '8pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                      paddingBottom: '1pt',
                    }}
                  >
                    2
                  </Text>
                </View>
                <View
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                      color: '#242424',
                    }}
                  >
                    {props.data.page1.stats['4'].percentage}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.mapsection}>
            <Image src={props.data.page1.mapimage.dataUrl} />
          </View>
          <View style={styles.footer}>
            <Text
              style={styles.pagenum}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
      {props.data.page2 != null && (
        <Page size='A4' style={styles.page}>
          <View style={styles.pageview}>
            <View style={styles.header}>
              <View style={styles.header1}>
                <View
                  style={{
                    height: '50%',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10pt',
                  }}
                >
                  <Text style={styles.avistep}>AVISTEP </Text>
                  <Text
                    style={{
                      fontSize: '14pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 300,
                    }}
                  >
                    {' '}
                    {props.data.country}
                  </Text>
                </View>
                <View style={{ height: '20%', textAlign: 'left' }}>
                  <Text
                    style={{
                      fontSize: '16pt',
                      fontFamily: 'Work Sans',
                      fontWeight: 400,
                    }}
                  >
                    {assessmentTitle()}
                  </Text>
                </View>
                <View
                  style={{
                    height: '20%',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  {props.data.region && (
                    <Image
                      src={IconSource['LOCATIONPIN_PNG']}
                      style={styles.pinicon}
                    />
                  )}
                  {props.data.region && (
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                      }}
                    >
                      {props.data.region}, {props.data.country}
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.header2}>
                <View style={{ height: '50%', textAlign: 'right' }}>
                  <Image src={logoref} style={styles.logo} />
                </View>
                <View style={{ height: '20%', textAlign: 'right' }}></View>
                <View style={{ height: '20%', textAlign: 'right' }}></View>
              </View>
            </View>
            <View style={styles.tablesection}>
              <View style={styles.tabletitle}>
                <Text
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Work Sans',
                    fontWeight: 400,
                    color: 'black',
                  }}
                >
                  {suitableTitle()}
                </Text>
              </View>
              <View style={styles.statstable}>
                <View style={styles.statsheader}>
                  <View
                    style={{
                      width: '30%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      paddingLeft: '10pt',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      Sensitivity Level
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '40%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      Km
                    </Text>
                    <Text
                      style={{
                        fontSize: '6pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        verticalAlign: 'super',
                        paddingBottom: '1pt',
                        paddingRight: '2pt',
                      }}
                    >
                      2
                    </Text>
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      of Country Per Sensitivity Level
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      % of Country
                    </Text>
                  </View>
                </View>
                <View style={styles.statsrow}>
                  <View style={styles.statsrowrank}>
                    <View
                      style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        height: '15pt',
                        width: '15pt',
                        borderRadius: '2pt',
                        backgroundColor: '#55FF00',
                      }}
                    ></View>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingLeft: '10pt',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      Low
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '40%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingRight: '2pt',
                      }}
                    >
                      {props.data.page2.stats['1'].area.toLocaleString('en-US')}
                    </Text>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      km
                    </Text>
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingBottom: '1pt',
                      }}
                    >
                      2
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      {props.data.page2.stats['1'].percentage}%
                    </Text>
                  </View>
                </View>
                <View style={styles.statsrow}>
                  <View style={styles.statsrowrank}>
                    <View
                      style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        height: '15pt',
                        width: '15pt',
                        borderRadius: '2pt',
                        backgroundColor: '#FFFF00',
                      }}
                    ></View>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingLeft: '10pt',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      Moderate
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '40%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingRight: '2pt',
                      }}
                    >
                      {props.data.page2.stats['2'].area.toLocaleString('en-US')}
                    </Text>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      km
                    </Text>
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingBottom: '1pt',
                      }}
                    >
                      2
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      {props.data.page2.stats['2'].percentage}%
                    </Text>
                  </View>
                </View>
                <View style={styles.statsrow}>
                  <View style={styles.statsrowrank}>
                    <View
                      style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        height: '15pt',
                        width: '15pt',
                        borderRadius: '2pt',
                        backgroundColor: '#FFAA00',
                      }}
                    ></View>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingLeft: '10pt',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      High
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '40%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingRight: '2pt',
                      }}
                    >
                      {props.data.page2.stats['3'].area.toLocaleString('en-US')}
                    </Text>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      km
                    </Text>
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingBottom: '1pt',
                      }}
                    >
                      2
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      {props.data.page2.stats['3'].percentage}%
                    </Text>
                  </View>
                </View>
                <View style={styles.statsrow}>
                  <View style={styles.statsrowrank}>
                    <View
                      style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        height: '15pt',
                        width: '15pt',
                        borderRadius: '2pt',
                        backgroundColor: '#FF0000',
                      }}
                    ></View>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingLeft: '10pt',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      Very High
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '40%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingRight: '2pt',
                      }}
                    >
                      {props.data.page2.stats['4'].area.toLocaleString('en-US')}
                    </Text>
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      km
                    </Text>
                    <Text
                      style={{
                        fontSize: '8pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                        paddingBottom: '1pt',
                      }}
                    >
                      2
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      textAlign: 'left',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Work Sans',
                        fontWeight: 400,
                        color: '#242424',
                      }}
                    >
                      {props.data.page2.stats['4'].percentage}%
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.mapsection}>
              <Image src={props.data.page2.mapimage.dataUrl} />
            </View>
            <View style={styles.footer}>
              <Text
                style={styles.pagenum}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} of ${totalPages}`
                }
                fixed
              />
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default CountryReport;
