import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { IconSource } from "../../../../utils/imagesource";
import './EnergySelection.css';



const EnergyCard = (props) => {
    const iconref = IconSource[props.energy?.code] || null;
    const [iconinfo, setIconInfo] = useState(iconref);

    return (
        <div title={props.energy.label} className="energycard-root" onClick={() => props.onClick(props.energy)}>
            <div className="energycard-label">{props.energy.label}</div>
            <div className="energycard-icon">
                <img src={iconinfo} />
            </div>
        </div>
    )

}


const EnergySelection = (props) => {
    const energyTypes = useSelector(state => state.config.energyTypes);
    const countryEnergyOptions = useSelector(state => state.map.countryEnergyOptions);
    const [energyOptions, setEnergyOptions] = useState(countryEnergyOptions);

    useEffect(() => {
        setEnergyOptions(countryEnergyOptions);
    }, [countryEnergyOptions])


    return (
        <div className="energy-root">
            <div className="energy-label">
               <p>Select an Energy Type</p>
            </div>
            <div className="energy-list">
                {
                    energyOptions.map((energytype, index) => {
                        return <EnergyCard key={index} energy={energytype} onClick={props.onClick} />
                    })
                }
            </div>

        </div>
    )

}

export default EnergySelection;

