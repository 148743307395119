

const allIcons = require.context('../styles/icons', true); 
const flagIcons = require.context('../styles/icons/flags', true); 
const imgReference = require.context('../styles/images', true);
const rightPanelReference = require.context('../styles/images/right_panel', true);

const birdSpeciesReference = require.context('../styles/images/bird_species', true);

//import { ReactComponent as SolarIcon } from '../styles/icons/solar_pv_icon.svg';
//import { ReactComponent as WindOnshoreIcon } from '../styles/icons/onshore_wind_icon.svg';
//import { ReactComponent as PowerTransIcon } from '../styles/icons/powerline_icon.svg';
//import { ReactComponent as PowerDistIcon } from '../styles/icons/powerline_icon.svg';


export const FlagIconSource = {
    'IND': flagIcons('./india_icon.svg'),
    'NPL': flagIcons('./nepal_icon.svg'),
    'THA': flagIcons('./thailand_icon.svg'),
    'VNM': flagIcons('./vietnam_icon.svg'),
	'KEN': flagIcons('./kenya_icon.svg'),
	'LAO': flagIcons('./laos_icon.svg'),
	'UZB': flagIcons('./uzbekistan_icon.svg'),
	'EGY': flagIcons('./egypt_icon.svg')

}

export const IconSource = {
    'SOLAR': allIcons('./solar_pv_icon.svg'),
    'ONSHORE': allIcons('./onshore_wind_icon.svg'),
    'OFFSHORE': allIcons('./offshore_wind_icon.svg'),
    'POWER': allIcons('./powerline_icon.svg'),
    'PWTRAN': allIcons('./transmission_powerline_icon.svg'),
    'PWDIST': allIcons('./distribution_powerline_icon.svg'),
    'EXPANDMORE': allIcons('./expand_more.svg'),
    'EXPANDLESS': allIcons('./expand_less.svg'),
    'NAVBACK': allIcons('./navback_icon.svg'),
    'COLLAPSE': allIcons('./collapse_icon.svg'),
    'LOCATIONPIN': allIcons('./location_pin_icon.svg'),
    'LOCATIONPIN_PNG': allIcons('./location_pin_icon.png'),
    'PRINT': allIcons('./print_icon.svg'),
    'SHARE': allIcons('./share_icon.svg'),
	'CELL_LOCATION_PIN': allIcons('./subtract.png')
}

export const RightPanelImageSource = {
    'INDIAOFFSHORE': rightPanelReference('./india_offshore_wind.png'),
    'INDIAONSHORE': rightPanelReference('./india_onshore_wind.png'),
    'INDIAPWDIST': rightPanelReference('./india_powerline_distribution.png'),
    'INDIAPWTRAN': rightPanelReference('./india_powerline_transmission.png'),
    'INDIASOLAR': rightPanelReference('./india_solar.png'),
    'NEPALONSHORE': rightPanelReference('./nepal_onshore_wind.png'),
    'NEPALPWDIST': rightPanelReference('./nepal_powerline_distribution.png'),
    'NEPALPWTRAN': rightPanelReference('./nepal_powerline_transmission.png'),
    'NEPALSOLAR': rightPanelReference('./nepal_solar.png'),
    'THAILANDOFFSHORE': rightPanelReference('./thailand_offshore_wind.png'),
    'THAILANDONSHORE': rightPanelReference('./thailand_onshore_wind.png'),
    'THAILANDPWDIST': rightPanelReference('./thailand_powerline_distribution.png'),
    'THAILANDPWTRAN': rightPanelReference('./thailand_powerline_transmission.png'),
    'THAILANDSOLAR': rightPanelReference('./thailand_solar.png'),
    'VIETNAMOFFSHORE': rightPanelReference('./vietnam_offshore_wind.png'),
    'VIETNAMONSHORE': rightPanelReference('./vietnam_onshore_wind.png'),
    'VIETNAMPWDIST': rightPanelReference('./vietnam_powerline_distribution.png'),
    'VIETNAMPWTRAN': rightPanelReference('./vietnam_powerline_transmission.png'),
    'VIETNAMSOLAR': rightPanelReference('./vietnam_solar.png'),
	'KENYAOFFSHORE': rightPanelReference('./kenya_offshore_wind.png'),
    'KENYAONSHORE': rightPanelReference('./kenya_onshore_wind.png'),
    'KENYAPWDIST': rightPanelReference('./kenya_powerline_distribution.png'),
    'KENYAPWTRAN': rightPanelReference('./kenya_powerline_transmission.png'),
    'KENYASOLAR': rightPanelReference('./kenya_solar.png'),
	'LAOSONSHORE': rightPanelReference('./laos_onshore_wind.png'),
    'LAOSPWDIST': rightPanelReference('./laos_powerline_distribution.png'),
    'LAOSPWTRAN': rightPanelReference('./laos_powerline_transmission.png'),
    'LAOSSOLAR': rightPanelReference('./laos_solar.png'),
	'UZBEKISTANONSHORE': rightPanelReference('./uzbekistan_onshore_wind.png'),
    'UZBEKISTANPWDIST': rightPanelReference('./uzbekistan_powerline_distribution.png'),
    'UZBEKISTANPWTRAN': rightPanelReference('./uzbekistan_powerline_transmission.png'),
    'UZBEKISTANSOLAR': rightPanelReference('./uzbekistan_solar.png'),
	'EGYPTONSHORE': rightPanelReference('./egypt_onshore_wind.png'),
	'EGYPTOFFSHORE': rightPanelReference('./egypt_offshore_wind.png'),
    'EGYPTPWDIST': rightPanelReference('./egypt_powerline_distribution.png'),
    'EGYPTPWTRAN': rightPanelReference('./egypt_powerline_transmission.jpg'),
    'EGYPTSOLAR': rightPanelReference('./egypt_solar.png'),


    'CORALREEF': rightPanelReference('./coral_reef.png'),
    'MANGROVE': rightPanelReference('./mangrove.png'),
    'SEAGRASS': rightPanelReference('./seagrass.png')
}
    
export const BirdSpeciesImageSource = {
	'Ardenna grisea': birdSpeciesReference('./Ardenna grisea.png'),
	'Buteo lagopus': birdSpeciesReference('./Buteo lagopus.png'),
	'Bycanistes bucinator': birdSpeciesReference('./Bycanistes bucinator.png'),
	'Calonectris diomedea': birdSpeciesReference('./Calonectris diomedea.png'),
	'Chlidonias niger': birdSpeciesReference('./Chlidonias niger.png'),
	'Gulosus aristotelis': birdSpeciesReference('./Gulosus aristotelis.png'),
	'Hydrobates matsudairae': birdSpeciesReference('./Hydrobates matsudairae.png'),
	'Morus capensis': birdSpeciesReference('./Morus capensis.png'),
	'Pavo muticus': birdSpeciesReference('./Pavo muticus.png'),
	'Phalaropus lobatus': birdSpeciesReference('./Phalaropus lobatus.png'),
	'Poeoptera femoralis': birdSpeciesReference('./Poeoptera femoralis.png'),
	'Porphyrio porphyrio': birdSpeciesReference('./Porphyrio porphyrio.png'),
	'Pseudobulweria aterrima': birdSpeciesReference('./Pseudobulweria aterrima.png'),
	'Puffinus mauretanicus': birdSpeciesReference('./Puffinus mauretanicus.png'),
	'Puffinus yelkouan': birdSpeciesReference('./Puffinus yelkouan.png'),
	'Stercorarius parasiticus': birdSpeciesReference('./Stercorarius parasiticus.png'),
	'Accipiter badius': birdSpeciesReference('./Accipiter badius.jpg'),
	'Accipiter brevipes': birdSpeciesReference('./Accipiter brevipes.png'),
	'Accipiter gentilis': birdSpeciesReference('./Accipiter gentilis.jpg'),
	'Accipiter gularis': birdSpeciesReference('./Accipiter gularis.jpg'),
	'Accipiter melanoleucus': birdSpeciesReference('./Accipiter melanoleucus.png'),
	'Accipiter minullus': birdSpeciesReference('./Accipiter minullus.png'),
	'Accipiter nisus': birdSpeciesReference('./Accipiter nisus.jpg'),
	'Accipiter ovampensis': birdSpeciesReference('./Accipiter ovampensis.png'),
	'Accipiter rufiventris': birdSpeciesReference('./Accipiter rufiventris.png'),
	'Accipiter soloensis': birdSpeciesReference('./Accipiter soloensis.jpg'),
	'Accipiter tachiro': birdSpeciesReference('./Accipiter tachiro.png'),
	'Accipiter trivirgatus': birdSpeciesReference('./Accipiter trivirgatus.jpg'),
	'Accipiter virgatus': birdSpeciesReference('./Accipiter virgatus.jpg'),
	'Aceros nipalensis': birdSpeciesReference('./Aceros nipalensis.jpg'),
	'Acrocephalus griseldis': birdSpeciesReference('./Acrocephalus griseldis.png'),
	'Acrocephalus tangorum': birdSpeciesReference('./Acrocephalus tangorum.jpg'),
	'Actitis hypoleucos': birdSpeciesReference('./Actitis hypoleucos.jpg'),
	'Aegypius monachus': birdSpeciesReference('./Aegypius monachus.jpg'),
	'Aerodramus unicolor': birdSpeciesReference('./Aerodramus unicolor.jpg'),
	'Agapornis pullarius': birdSpeciesReference('./Agapornis pullarius.png'),
	'Alophoixus tephrogenys': birdSpeciesReference('./Alophoixus tephrogenys.jpg'),
	'Amaurornis phoenicurus': birdSpeciesReference('./Amaurornis phoenicurus.png'),
	'Anastomus lamelligerus': birdSpeciesReference('./Anastomus lamelligerus.png'),
	'Anastomus oscitans': birdSpeciesReference('./Anastomus oscitans.jpg'),
	'Anhinga melanogaster': birdSpeciesReference('./Anhinga melanogaster.jpg'),
	'Anhinga rufa': birdSpeciesReference('./Anhinga rufa.png'),
	'Anorrhinus austeni': birdSpeciesReference('./Anorrhinus austeni.jpg'),
	'Anorrhinus galeritus': birdSpeciesReference('./Anorrhinus galeritus.jpg'),
	'Anorrhinus tickelli': birdSpeciesReference('./Anorrhinus tickelli.jpg'),
	'Anous stolidus': birdSpeciesReference('./Anous stolidus.jpg'),
	'Anous tenuirostris': birdSpeciesReference('./Anous tenuirostris.jpg'),
	'Anser erythropus': birdSpeciesReference('./Anser erythropus.png'),
	'Anthracoceros albirostris': birdSpeciesReference('./Anthracoceros albirostris.png'),
	'Anthracoceros coronatus': birdSpeciesReference('./Anthracoceros coronatus.jpg'),
	'Anthracoceros malayanus': birdSpeciesReference('./Anthracoceros malayanus.jpg'),
	'Anthropoides virgo': birdSpeciesReference('./Anthropoides virgo.jpg'),
	'Anthus cervinus': birdSpeciesReference('./Anthus cervinus.png'),
	'Anthus hodgsoni': birdSpeciesReference('./Anthus hodgsoni.png'),
	'Anthus lineiventris': birdSpeciesReference('./Anthus lineiventris.png'),
	'Anthus melindae': birdSpeciesReference('./Anthus melindae.png'),
	'Anthus nilghiriensis': birdSpeciesReference('./Anthus nilghiriensis.jpg'),
	'Anthus richardi': birdSpeciesReference('./Anthus richardi.png'),
	'Anthus rufulus': birdSpeciesReference('./Anthus rufulus.png'),
	'Anthus similis': birdSpeciesReference('./Anthus similis.png'),
	'Anthus sokokensis': birdSpeciesReference('./Anthus sokokensis.png'),
	'Aplopelia larvata': birdSpeciesReference('./Aplopelia larvata.png'),
	'Apus acuticauda': birdSpeciesReference('./Apus acuticauda.jpg'),
	'Aquila chrysaetos': birdSpeciesReference('./Aquila chrysaetos.jpg'),
	'Aquila fasciata': birdSpeciesReference('./Aquila fasciata.jpg'),
	'Aquila heliaca': birdSpeciesReference('./Aquila heliaca.jpg'),
	'Aquila nipalensis': birdSpeciesReference('./Aquila nipalensis.jpg'),
	'Aquila rapax': birdSpeciesReference('./Aquila rapax.jpg'),
	'Aquila spilogaster': birdSpeciesReference('./Aquila spilogaster.png'),
	'Aquila verreauxii': birdSpeciesReference('./Aquila verreauxii.png'),
	'Arborophila atrogularis': birdSpeciesReference('./Arborophila atrogularis.jpg'),
	'Arborophila chloropus': birdSpeciesReference('./Arborophila chloropus.jpg'),
	'Arborophila davidi': birdSpeciesReference('./Arborophila davidi.jpg'),
	'Arborophila rufogularis': birdSpeciesReference('./Arborophila rufogularis.jpg'),
	'Arborophila torqueola': birdSpeciesReference('./Arborophila torqueola.jpg'),
	'Ardea alba': birdSpeciesReference('./Ardea alba.png'),
	'Ardea cinerea': birdSpeciesReference('./Ardea cinerea.png'),
	'Ardea goliath': birdSpeciesReference('./Ardea goliath.png'),
	'Ardea insignis': birdSpeciesReference('./Ardea insignis.jpg'),
	'Ardea intermedia': birdSpeciesReference('./Ardea intermedia.png'),
	'Ardea purpurea': birdSpeciesReference('./Ardea purpurea.png'),
	'Ardenna carneipes': birdSpeciesReference('./Ardenna carneipes.jpg'),
	'Ardenna pacifica': birdSpeciesReference('./Ardenna pacifica.jpg'),
	'Ardeola bacchus': birdSpeciesReference('./Ardeola bacchus.png'),
	'Ardeola grayii': birdSpeciesReference('./Ardeola grayii.jpg'),
	'Ardeola idae': birdSpeciesReference('./Ardeola idae.png'),
	'Ardeotis kori': birdSpeciesReference('./Ardeotis kori.png'),
	'Ardeotis nigriceps': birdSpeciesReference('./Ardeotis nigriceps.jpg'),
	'Arenaria interpres': birdSpeciesReference('./Arenaria interpres.jpg'),
	'Artamus fuscus': birdSpeciesReference('./Artamus fuscus.jpg'),
	'Asarcornis scutulata': birdSpeciesReference('./Asarcornis scutulata.jpg'),
	'Asio abyssinicus': birdSpeciesReference('./Asio abyssinicus.png'),
	'Asio capensis': birdSpeciesReference('./Asio capensis.png'),
	'Asio flammeus': birdSpeciesReference('./Asio flammeus.jpg'),
	'Asio otus': birdSpeciesReference('./Asio otus.jpg'),
	'Athene blewitti': birdSpeciesReference('./Athene blewitti.jpg'),
	'Athene brama': birdSpeciesReference('./Athene brama.jpg'),
	'Athene noctua': birdSpeciesReference('./Athene noctua.jpg'),
	'Aviceda cuculoides': birdSpeciesReference('./Aviceda cuculoides.png'),
	'Aviceda jerdoni': birdSpeciesReference('./Aviceda jerdoni.jpg'),
	'Aviceda leuphotes': birdSpeciesReference('./Aviceda leuphotes.jpg'),
	'Aythya baeri': birdSpeciesReference('./Aythya baeri.jpg'),
	'Aythya ferina': birdSpeciesReference('./Aythya ferina.jpg'),
	'Aythya fuligula': birdSpeciesReference('./Aythya fuligula.png'),
	'Aythya nyroca': birdSpeciesReference('./Aythya nyroca.jpg'),
	'Balearica pavonina': birdSpeciesReference('./Balearica pavonina.png'),
	'Balearica regulorum': birdSpeciesReference('./Balearica regulorum.png'),
	'Bambusicola fytchii': birdSpeciesReference('./Bambusicola fytchii.jpg'),
	'Berenicornis comatus': birdSpeciesReference('./Berenicornis comatus.jpg'),
	'Bostrychia hagedash': birdSpeciesReference('./Bostrychia hagedash.png'),
	'Bostrychia olivacea': birdSpeciesReference('./Bostrychia olivacea.png'),
	'Brachypodius priocephalus': birdSpeciesReference('./Brachypodius priocephalus.jpg'),
	'Bubo africanus': birdSpeciesReference('./Bubo africanus.png'),
	'Bubo bengalensis': birdSpeciesReference('./Bubo bengalensis.jpg'),
	'Bubo bubo': birdSpeciesReference('./Bubo bubo.jpg'),
	'Bubo capensis': birdSpeciesReference('./Bubo capensis.png'),
	'Bubo cinerascens': birdSpeciesReference('./Bubo cinerascens.png'),
	'Bubo coromandus': birdSpeciesReference('./Bubo coromandus.jpg'),
	'Bubo lacteus': birdSpeciesReference('./Bubo lacteus.png'),
	'Bubo nipalensis': birdSpeciesReference('./Bubo nipalensis.png'),
	'Bubo sumatranus': birdSpeciesReference('./Bubo sumatranus.jpg'),
	'Bubulcus ibis': birdSpeciesReference('./Bubulcus ibis.png'),
	'Buceros bicornis': birdSpeciesReference('./Buceros bicornis.jpg'),
	'Buceros rhinoceros': birdSpeciesReference('./Buceros rhinoceros.jpg'),
	'Bucorvus abyssinicus': birdSpeciesReference('./Bucorvus abyssinicus.png'),
	'Bucorvus leadbeateri': birdSpeciesReference('./Bucorvus leadbeateri.png'),
	'Bulweria bulwerii': birdSpeciesReference('./Bulweria bulwerii.jpg'),
	'Bulweria fallax': birdSpeciesReference('./Bulweria fallax.jpg'),
	'Burhinus capensis': birdSpeciesReference('./Burhinus capensis.png'),
	'Burhinus indicus': birdSpeciesReference('./Burhinus indicus.jpg'),
	'Burhinus oedicnemus': birdSpeciesReference('./Burhinus oedicnemus.png'),
	'Burhinus senegalensis': birdSpeciesReference('./Burhinus senegalensis.png'),
	'Burhinus vermiculatus': birdSpeciesReference('./Burhinus vermiculatus.png'),
	'Butastur indicus': birdSpeciesReference('./Butastur indicus.jpg'),
	'Butastur liventer': birdSpeciesReference('./Butastur liventer.jpg'),
	'Butastur rufipennis': birdSpeciesReference('./Butastur rufipennis.png'),
	'Butastur teesa': birdSpeciesReference('./Butastur teesa.jpg'),
	'Buteo augur': birdSpeciesReference('./Buteo augur.png'),
	'Buteo buteo': birdSpeciesReference('./Buteo buteo.jpg'),
	'Buteo hemilasius': birdSpeciesReference('./Buteo hemilasius.jpg'),
	'Buteo japonicus': birdSpeciesReference('./Buteo japonicus.jpg'),
	'Buteo oreophilus': birdSpeciesReference('./Buteo oreophilus.png'),
	'Buteo refectus': birdSpeciesReference('./Buteo refectus.jpg'),
	'Buteo rufinus': birdSpeciesReference('./Buteo rufinus.jpg'),
	'Butorides striata': birdSpeciesReference('./Butorides striata.png'),
	'Bycanistes brevis': birdSpeciesReference('./Bycanistes brevis.png'),
	'Bycanistes subcylindricus': birdSpeciesReference('./Bycanistes subcylindricus.png'),
	'Calendulauda poecilosterna': birdSpeciesReference('./Calendulauda poecilosterna.png'),
	'Calidris alba': birdSpeciesReference('./Calidris alba.jpg'),
	'Calidris alpina': birdSpeciesReference('./Calidris alpina.jpg'),
	'Calidris canutus': birdSpeciesReference('./Calidris canutus.jpg'),
	'Calidris falcinellus': birdSpeciesReference('./Calidris falcinellus.jpg'),
	'Calidris ferruginea': birdSpeciesReference('./Calidris ferruginea.jpg'),
	'Calidris minuta': birdSpeciesReference('./Calidris minuta.jpg'),
	'Calidris pugnax': birdSpeciesReference('./Calidris pugnax.jpg'),
	'Calidris pygmaea': birdSpeciesReference('./Calidris pygmaea.jpg'),
	'Calidris ruficollis': birdSpeciesReference('./Calidris ruficollis.jpg'),
	'Calidris subminuta': birdSpeciesReference('./Calidris subminuta.jpg'),
	'Calidris temminckii': birdSpeciesReference('./Calidris temminckii.jpg'),
	'Calidris tenuirostris': birdSpeciesReference('./Calidris tenuirostris.jpg'),
	'Calliope obscura': birdSpeciesReference('./Calliope obscura.png'),
	'Calonectris leucomelas': birdSpeciesReference('./Calonectris leucomelas.jpg'),
	'Campethera mombassica': birdSpeciesReference('./Campethera mombassica.png'),
	'Campocolinus coqui': birdSpeciesReference('./Campocolinus coqui.png'),
	'Carpococcyx renauldi': birdSpeciesReference('./Carpococcyx renauldi.jpg'),
	'Catreus wallichii': birdSpeciesReference('./Catreus wallichii.jpg'),
	'Centropus rectunguis': birdSpeciesReference('./Centropus rectunguis.jpg'),
	'Chalcophaps indica': birdSpeciesReference('./Chalcophaps indica.png'),
	'Charadrius alexandrinus': birdSpeciesReference('./Charadrius alexandrinus.jpg'),
	'Charadrius asiaticus': birdSpeciesReference('./Charadrius asiaticus.png'),
	'Charadrius dealbatus': birdSpeciesReference('./Charadrius dealbatus.jpg'),
	'Charadrius dubius': birdSpeciesReference('./Charadrius dubius.jpg'),
	'Charadrius hiaticula': birdSpeciesReference('./Charadrius hiaticula.jpg'),
	'Charadrius leschenaultii': birdSpeciesReference('./Charadrius leschenaultii.jpg'),
	'Charadrius marginatus': birdSpeciesReference('./Charadrius marginatus.png'),
	'Charadrius mongolus': birdSpeciesReference('./Charadrius mongolus.jpg'),
	'Charadrius pallidus': birdSpeciesReference('./Charadrius pallidus.png'),
	'Charadrius pecuarius': birdSpeciesReference('./Charadrius pecuarius.png'),
	'Charadrius peronii': birdSpeciesReference('./Charadrius peronii.jpg'),
	'Charadrius placidus': birdSpeciesReference('./Charadrius placidus.jpg'),
	'Charadrius tricollaris': birdSpeciesReference('./Charadrius tricollaris.png'),
	'Charadrius veredus': birdSpeciesReference('./Charadrius veredus.jpg'),
	'Chelictinia riocourii': birdSpeciesReference('./Chelictinia riocourii.png'),
	'Chersophilus duponti': birdSpeciesReference('./Chersophilus duponti.png'),
	'Chlamydotis macqueenii': birdSpeciesReference('./Chlamydotis macqueenii.jpg'),
	'Chlamydotis undulata': birdSpeciesReference('./Chlamydotis undulata.png'),
	'Chlidonias hybrida': birdSpeciesReference('./Chlidonias hybrida.png'),
	'Chlidonias leucopterus': birdSpeciesReference('./Chlidonias leucopterus.png'),
	'Ciconia abdimii': birdSpeciesReference('./Ciconia abdimii.png'),
	'Ciconia ciconia': birdSpeciesReference('./Ciconia ciconia.jpg'),
	'Ciconia episcopus': birdSpeciesReference('./Ciconia episcopus.jpg'),
	'Ciconia nigra': birdSpeciesReference('./Ciconia nigra.jpg'),
	'Ciconia stormi': birdSpeciesReference('./Ciconia stormi.jpg'),
	'Circaetus beaudouini': birdSpeciesReference('./Circaetus beaudouini.png'),
	'Circaetus cinerascens': birdSpeciesReference('./Circaetus cinerascens.png'),
	'Circaetus cinereus': birdSpeciesReference('./Circaetus cinereus.png'),
	'Circaetus fasciolatus': birdSpeciesReference('./Circaetus fasciolatus.png'),
	'Circaetus gallicus': birdSpeciesReference('./Circaetus gallicus.jpg'),
	'Circaetus pectoralis': birdSpeciesReference('./Circaetus pectoralis.png'),
	'Circus aeruginosus': birdSpeciesReference('./Circus aeruginosus.jpg'),
	'Circus cyaneus': birdSpeciesReference('./Circus cyaneus.jpg'),
	'Circus macrourus': birdSpeciesReference('./Circus macrourus.jpg'),
	'Circus melanoleucos': birdSpeciesReference('./Circus melanoleucos.jpg'),
	'Circus pygargus': birdSpeciesReference('./Circus pygargus.jpg'),
	'Circus ranivorus': birdSpeciesReference('./Circus ranivorus.png'),
	'Circus spilonotus': birdSpeciesReference('./Circus spilonotus.jpg'),
	'Cissa chinensis': birdSpeciesReference('./Cissa chinensis.jpg'),
	'Cissa hypoleuca': birdSpeciesReference('./Cissa hypoleuca.jpg'),
	'Clanga clanga': birdSpeciesReference('./Clanga clanga.jpg'),
	'Clanga hastata': birdSpeciesReference('./Clanga hastata.jpg'),
	'Clanga pomarina': birdSpeciesReference('./Clanga pomarina.png'),
	'Columba arquatrix': birdSpeciesReference('./Columba arquatrix.png'),
	'Columba delegorguei': birdSpeciesReference('./Columba delegorguei.png'),
	'Columba elphinstonii': birdSpeciesReference('./Columba elphinstonii.jpg'),
	'Columba eversmanni': birdSpeciesReference('./Columba eversmanni.jpg'),
	'Columba guinea': birdSpeciesReference('./Columba guinea.png'),
	'Columba hodgsonii': birdSpeciesReference('./Columba hodgsonii.png'),
	'Columba livia': birdSpeciesReference('./Columba livia.png'),
	'Columba oenas': birdSpeciesReference('./Columba oenas.png'),
	'Columba palumbus': birdSpeciesReference('./Columba palumbus.png'),
	'Columba pulchricollis': birdSpeciesReference('./Columba pulchricollis.png'),
	'Columba punicea': birdSpeciesReference('./Columba punicea.jpg'),
	'Coracias abyssinicus': birdSpeciesReference('./Coracias abyssinicus.png'),
	'Coracias affinis': birdSpeciesReference('./Coracias affinis.png'),
	'Coracias benghalensis': birdSpeciesReference('./Coracias benghalensis.jpg'),
	'Coracias caudatus': birdSpeciesReference('./Coracias caudatus.png'),
	'Coracias garrulus': birdSpeciesReference('./Coracias garrulus.png'),
	'Coracias naevius': birdSpeciesReference('./Coracias naevius.png'),
	'Corvus albicollis': birdSpeciesReference('./Corvus albicollis.png'),
	'Corvus albus': birdSpeciesReference('./Corvus albus.png'),
	'Corvus capensis': birdSpeciesReference('./Corvus capensis.png'),
	'Corvus corax': birdSpeciesReference('./Corvus corax.jpg'),
	'Corvus corone': birdSpeciesReference('./Corvus corone.jpg'),
	'Corvus edithae': birdSpeciesReference('./Corvus edithae.png'),
	'Corvus frugilegus': birdSpeciesReference('./Corvus frugilegus.jpg'),
	'Corvus macrorhynchos': birdSpeciesReference('./Corvus macrorhynchos.jpg'),
	'Corvus pectoralis': birdSpeciesReference('./Corvus pectoralis.jpg'),
	'Corvus rhipidurus': birdSpeciesReference('./Corvus rhipidurus.png'),
	'Corvus ruficollis': birdSpeciesReference('./Corvus ruficollis.png'),
	'Corvus splendens': birdSpeciesReference('./Corvus splendens.jpg'),
	'Coturnix coromandelica': birdSpeciesReference('./Coturnix coromandelica.jpg'),
	'Coturnix delegorguei': birdSpeciesReference('./Coturnix delegorguei.png'),
	'Coturnix japonica': birdSpeciesReference('./Coturnix japonica.jpg'),
	'Crypsirina temia': birdSpeciesReference('./Crypsirina temia.jpg'),
	'Cursorius coromandelicus': birdSpeciesReference('./Cursorius coromandelicus.jpg'),
	'Cursorius somalensis': birdSpeciesReference('./Cursorius somalensis.png'),
	'Cursorius temminckii': birdSpeciesReference('./Cursorius temminckii.png'),
	'Cygnus olor': birdSpeciesReference('./Cygnus olor.png'),
	'Dendrocitta formosae': birdSpeciesReference('./Dendrocitta formosae.jpg'),
	'Dendrocitta frontalis': birdSpeciesReference('./Dendrocitta frontalis.jpg'),
	'Dendrocitta leucogastra': birdSpeciesReference('./Dendrocitta leucogastra.jpg'),
	'Dendrocitta vagabunda': birdSpeciesReference('./Dendrocitta vagabunda.jpg'),
	'Dendrocygna javanica': birdSpeciesReference('./Dendrocygna javanica.png'),
	'Dendronanthus indicus': birdSpeciesReference('./Dendronanthus indicus.png'),
	'Dicrurus caerulescens': birdSpeciesReference('./Dicrurus caerulescens.jpg'),
	'Dromas ardeola': birdSpeciesReference('./Dromas ardeola.jpg'),
	'Ducula aenea': birdSpeciesReference('./Ducula aenea.png'),
	'Ducula badia': birdSpeciesReference('./Ducula badia.png'),
	'Egretta eulophotes': birdSpeciesReference('./Egretta eulophotes.jpg'),
	'Elanus caeruleus': birdSpeciesReference('./Elanus caeruleus.jpg'),
	'Emberiza aureola': birdSpeciesReference('./Emberiza aureola.jpg'),
	'Ephippiorhynchus asiaticus': birdSpeciesReference('./Ephippiorhynchus asiaticus.jpg'),
	'Ephippiorhynchus senegalensis': birdSpeciesReference('./Ephippiorhynchus senegalensis.png'),
	'Esacus magnirostris': birdSpeciesReference('./Esacus magnirostris.jpg'),
	'Esacus recurvirostris': birdSpeciesReference('./Esacus recurvirostris.jpg'),
	'Eupodotis senegalensis': birdSpeciesReference('./Eupodotis senegalensis.png'),
	'Eurystomus glaucurus': birdSpeciesReference('./Eurystomus glaucurus.png'),
	'Eurystomus orientalis': birdSpeciesReference('./Eurystomus orientalis.png'),
	'Falco alopex': birdSpeciesReference('./Falco alopex.png'),
	'Falco amurensis': birdSpeciesReference('./Falco amurensis.jpg'),
	'Falco ardosiaceus': birdSpeciesReference('./Falco ardosiaceus.png'),
	'Falco biarmicus': birdSpeciesReference('./Falco biarmicus.png'),
	'Falco cherrug': birdSpeciesReference('./Falco cherrug.jpg'),
	'Falco chicquera': birdSpeciesReference('./Falco chicquera.jpg'),
	'Falco columbarius': birdSpeciesReference('./Falco columbarius.jpg'),
	'Falco concolor': birdSpeciesReference('./Falco concolor.png'),
	'Falco cuvierii': birdSpeciesReference('./Falco cuvierii.png'),
	'Falco eleonorae': birdSpeciesReference('./Falco eleonorae.png'),
	'Falco fasciinucha': birdSpeciesReference('./Falco fasciinucha.png'),
	'Falco jugger': birdSpeciesReference('./Falco jugger.jpg'),
	'Falco naumanni': birdSpeciesReference('./Falco naumanni.jpg'),
	'Falco peregrinus': birdSpeciesReference('./Falco peregrinus.jpg'),
	'Falco ruficollis': birdSpeciesReference('./Falco ruficollis.png'),
	'Falco rupicoloides': birdSpeciesReference('./Falco rupicoloides.png'),
	'Falco severus': birdSpeciesReference('./Falco severus.jpg'),
	'Falco subbuteo': birdSpeciesReference('./Falco subbuteo.jpg'),
	'Falco tinnunculus': birdSpeciesReference('./Falco tinnunculus.jpg'),
	'Falco vespertinus': birdSpeciesReference('./Falco vespertinus.png'),
	'Ficedula subrubra': birdSpeciesReference('./Ficedula subrubra.jpg'),
	'Francolinus francolinus': birdSpeciesReference('./Francolinus francolinus.jpg'),
	'Francolinus gularis': birdSpeciesReference('./Francolinus gularis.jpg'),
	'Francolinus pictus': birdSpeciesReference('./Francolinus pictus.jpg'),
	'Francolinus pintadeanus': birdSpeciesReference('./Francolinus pintadeanus.jpg'),
	'Francolinus pondicerianus': birdSpeciesReference('./Francolinus pondicerianus.jpg'),
	'Fraseria lendu': birdSpeciesReference('./Fraseria lendu.png'),
	'Fregata andrewsi': birdSpeciesReference('./Fregata andrewsi.jpg'),
	'Fregata ariel': birdSpeciesReference('./Fregata ariel.jpg'),
	'Fregata minor': birdSpeciesReference('./Fregata minor.jpg'),
	'Fregatta tropica': birdSpeciesReference('./Fregatta tropica.jpg'),
	'Fulica atra': birdSpeciesReference('./Fulica atra.png'),
	'Gallicrex cinerea': birdSpeciesReference('./Gallicrex cinerea.png'),
	'Gallinago gallinago': birdSpeciesReference('./Gallinago gallinago.jpg'),
	'Gallinago media': birdSpeciesReference('./Gallinago media.png'),
	'Gallinago megala': birdSpeciesReference('./Gallinago megala.jpg'),
	'Gallinago nemoricola': birdSpeciesReference('./Gallinago nemoricola.jpg'),
	'Gallinago nigripennis': birdSpeciesReference('./Gallinago nigripennis.png'),
	'Gallinago solitaria': birdSpeciesReference('./Gallinago solitaria.jpg'),
	'Gallinago stenura': birdSpeciesReference('./Gallinago stenura.jpg'),
	'Galloperdix lunulata': birdSpeciesReference('./Galloperdix lunulata.jpg'),
	'Galloperdix spadicea': birdSpeciesReference('./Galloperdix spadicea.png'),
	'Gallus gallus': birdSpeciesReference('./Gallus gallus.jpg'),
	'Gallus sonneratii': birdSpeciesReference('./Gallus sonneratii.jpg'),
	'Garrulus bispecularis': birdSpeciesReference('./Garrulus bispecularis.jpg'),
	'Garrulus lanceolatus': birdSpeciesReference('./Garrulus lanceolatus.jpg'),
	'Garrulus leucotis': birdSpeciesReference('./Garrulus leucotis.jpg'),
	'Gelochelidon nilotica': birdSpeciesReference('./Gelochelidon nilotica.jpg'),
	'Glareola lactea': birdSpeciesReference('./Glareola lactea.jpg'),
	'Glareola maldivarum': birdSpeciesReference('./Glareola maldivarum.png'),
	'Glareola nordmanni': birdSpeciesReference('./Glareola nordmanni.png'),
	'Glareola nuchalis': birdSpeciesReference('./Glareola nuchalis.png'),
	'Glareola ocularis': birdSpeciesReference('./Glareola ocularis.png'),
	'Glareola pratincola': birdSpeciesReference('./Glareola pratincola.png'),
	'Glaucidium brodiei': birdSpeciesReference('./Glaucidium brodiei.jpg'),
	'Glaucidium capense': birdSpeciesReference('./Glaucidium capense.png'),
	'Glaucidium cuculoides': birdSpeciesReference('./Glaucidium cuculoides.jpg'),
	'Glaucidium perlatum': birdSpeciesReference('./Glaucidium perlatum.png'),
	'Glaucidium radiatum': birdSpeciesReference('./Glaucidium radiatum.jpg'),
	'Glaucidium tephronotum': birdSpeciesReference('./Glaucidium tephronotum.png'),
	'Grus antigone': birdSpeciesReference('./Grus antigone.jpg'),
	'Grus grus': birdSpeciesReference('./Grus grus.jpg'),
	'Grus nigricollis': birdSpeciesReference('./Grus nigricollis.jpg'),
	'Gypaetus barbatus': birdSpeciesReference('./Gypaetus barbatus.jpg'),
	'Gypohierax angolensis': birdSpeciesReference('./Gypohierax angolensis.png'),
	'Gyps africanus': birdSpeciesReference('./Gyps africanus.png'),
	'Gyps bengalensis': birdSpeciesReference('./Gyps bengalensis.jpg'),
	'Gyps fulvus': birdSpeciesReference('./Gyps fulvus.jpg'),
	'Gyps himalayensis': birdSpeciesReference('./Gyps himalayensis.jpg'),
	'Gyps indicus': birdSpeciesReference('./Gyps indicus.jpg'),
	'Gyps rueppelli': birdSpeciesReference('./Gyps rueppelli.png'),
	'Gyps tenuirostris': birdSpeciesReference('./Gyps tenuirostris.jpg'),
	'Haematopus ostralegus': birdSpeciesReference('./Haematopus ostralegus.jpg'),
	'Haliaeetus albicilla': birdSpeciesReference('./Haliaeetus albicilla.jpg'),
	'Haliaeetus leucogaster': birdSpeciesReference('./Haliaeetus leucogaster.jpg'),
	'Haliaeetus leucoryphus': birdSpeciesReference('./Haliaeetus leucoryphus.jpg'),
	'Haliaeetus vocifer': birdSpeciesReference('./Haliaeetus vocifer.png'),
	'Haliastur indus': birdSpeciesReference('./Haliastur indus.jpg'),
	'Heliopais personatus': birdSpeciesReference('./Heliopais personatus.jpg'),
	'Hieraaetus ayresii': birdSpeciesReference('./Hieraaetus ayresii.png'),
	'Hieraaetus pennatus': birdSpeciesReference('./Hieraaetus pennatus.jpg'),
	'Hieraaetus wahlbergi': birdSpeciesReference('./Hieraaetus wahlbergi.png'),
	'Himalayapsitta cyanocephala': birdSpeciesReference('./Himalayapsitta cyanocephala.jpg'),
	'Himalayapsitta finschii': birdSpeciesReference('./Himalayapsitta finschii.jpg'),
	'Himalayapsitta himalayana': birdSpeciesReference('./Himalayapsitta himalayana.jpg'),
	'Himalayapsitta roseata': birdSpeciesReference('./Himalayapsitta roseata.jpg'),
	'Hirundo atrocaerulea': birdSpeciesReference('./Hirundo atrocaerulea.png'),
	'Houbaropsis bengalensis': birdSpeciesReference('./Houbaropsis bengalensis.jpg'),
	'Hydrobates monorhis': birdSpeciesReference('./Hydrobates monorhis.jpg'),
	'Hydrocoloeus minutus': birdSpeciesReference('./Hydrocoloeus minutus.png'),
	'Hydroprogne caspia': birdSpeciesReference('./Hydroprogne caspia.jpg'),
	'Hypocolius ampelinus': birdSpeciesReference('./Hypocolius ampelinus.jpg'),
	'Ibidorhyncha struthersii': birdSpeciesReference('./Ibidorhyncha struthersii.jpg'),
	'Icthyophaga humilis': birdSpeciesReference('./Icthyophaga humilis.jpg'),
	'Icthyophaga ichthyaetus': birdSpeciesReference('./Icthyophaga ichthyaetus.jpg'),
	'Ictinaetus malaiensis': birdSpeciesReference('./Ictinaetus malaiensis.jpg'),
	'Ithaginis cruentus': birdSpeciesReference('./Ithaginis cruentus.jpg'),
	'Ixobrychus flavicollis': birdSpeciesReference('./Ixobrychus flavicollis.png'),
	'Kaupifalco monogrammicus': birdSpeciesReference('./Kaupifalco monogrammicus.png'),
	'Ketupa flavipes': birdSpeciesReference('./Ketupa flavipes.jpg'),
	'Ketupa ketupu': birdSpeciesReference('./Ketupa ketupu.jpg'),
	'Ketupa zeylonensis': birdSpeciesReference('./Ketupa zeylonensis.jpg'),
	'Larus armenicus': birdSpeciesReference('./Larus armenicus.png'),
	'Larus brunnicephalus': birdSpeciesReference('./Larus brunnicephalus.jpg'),
	'Larus cachinnans': birdSpeciesReference('./Larus cachinnans.png'),
	'Larus canus': birdSpeciesReference('./Larus canus.jpg'),
	'Larus cirrocephalus': birdSpeciesReference('./Larus cirrocephalus.png'),
	'Larus fuscus': birdSpeciesReference('./Larus fuscus.jpg'),
	'Larus genei': birdSpeciesReference('./Larus genei.jpg'),
	'Larus hemprichii': birdSpeciesReference('./Larus hemprichii.png'),
	'Larus ichthyaetus': birdSpeciesReference('./Larus ichthyaetus.png'),
	'Larus leucophthalmus': birdSpeciesReference('./Larus leucophthalmus.png'),
	'Larus melanocephalus': birdSpeciesReference('./Larus melanocephalus.png'),
	'Larus michahellis': birdSpeciesReference('./Larus michahellis.png'),
	'Larus ridibundus': birdSpeciesReference('./Larus ridibundus.jpg'),
	'Larus smithsonianus': birdSpeciesReference('./Larus smithsonianus.jpg'),
	'Leptoptilos crumenifer': birdSpeciesReference('./Leptoptilos crumenifer.png'),
	'Leptoptilos dubius': birdSpeciesReference('./Leptoptilos dubius.jpg'),
	'Leptoptilos javanicus': birdSpeciesReference('./Leptoptilos javanicus.jpg'),
	'Lerwa lerwa': birdSpeciesReference('./Lerwa lerwa.jpg'),
	'Limnodromus semipalmatus': birdSpeciesReference('./Limnodromus semipalmatus.jpg'),
	'Limosa lapponica': birdSpeciesReference('./Limosa lapponica.jpg'),
	'Limosa limosa': birdSpeciesReference('./Limosa limosa.jpg'),
	'Lissotis hartlaubii': birdSpeciesReference('./Lissotis hartlaubii.png'),
	'Lissotis melanogaster': birdSpeciesReference('./Lissotis melanogaster.png'),
	'Lophaetus occipitalis': birdSpeciesReference('./Lophaetus occipitalis.png'),
	'Lophoceros alboterminatus': birdSpeciesReference('./Lophoceros alboterminatus.png'),
	'Lophoceros hemprichii': birdSpeciesReference('./Lophoceros hemprichii.png'),
	'Lophoceros nasutus': birdSpeciesReference('./Lophoceros nasutus.png'),
	'Lophophorus impejanus': birdSpeciesReference('./Lophophorus impejanus.jpg'),
	'Lophophorus sclateri': birdSpeciesReference('./Lophophorus sclateri.jpg'),
	'Lophotis gindiana': birdSpeciesReference('./Lophotis gindiana.png'),
	'Lophotriorchis kienerii': birdSpeciesReference('./Lophotriorchis kienerii.jpg'),
	'Lophura leucomelanos': birdSpeciesReference('./Lophura leucomelanos.jpg'),
	'Lophura nycthemera': birdSpeciesReference('./Lophura nycthemera.jpg'),
	'Loriculus vernalis': birdSpeciesReference('./Loriculus vernalis.png'),
	'Lymnocryptes minimus': birdSpeciesReference('./Lymnocryptes minimus.jpg'),
	'Macheiramphus alcinus': birdSpeciesReference('./Macheiramphus alcinus.jpg'),
	'Machlolophus nuchalis': birdSpeciesReference('./Machlolophus nuchalis.jpg'),
	'Macronyx ameliae': birdSpeciesReference('./Macronyx ameliae.png'),
	'Macronyx aurantiigula': birdSpeciesReference('./Macronyx aurantiigula.png'),
	'Macronyx croceus': birdSpeciesReference('./Macronyx croceus.png'),
	'Macronyx sharpei': birdSpeciesReference('./Macronyx sharpei.png'),
	'Macropygia ruficeps': birdSpeciesReference('./Macropygia ruficeps.png'),
	'Macropygia unchall': birdSpeciesReference('./Macropygia unchall.png'),
	'Mareca falcata': birdSpeciesReference('./Mareca falcata.jpg'),
	'Mareca penelope': birdSpeciesReference('./Mareca penelope.png'),
	'Marmaronetta angustirostris': birdSpeciesReference('./Marmaronetta angustirostris.jpg'),
	'Melierax metabates': birdSpeciesReference('./Melierax metabates.png'),
	'Melierax poliopterus': birdSpeciesReference('./Melierax poliopterus.png'),
	'Merops revoilii': birdSpeciesReference('./Merops revoilii.png'),
	'Microcarbo africanus': birdSpeciesReference('./Microcarbo africanus.png'),
	'Microcarbo niger': birdSpeciesReference('./Microcarbo niger.jpg'),
	'Microhierax caerulescens': birdSpeciesReference('./Microhierax caerulescens.jpg'),
	'Microhierax fringillarius': birdSpeciesReference('./Microhierax fringillarius.jpg'),
	'Microhierax melanoleucos': birdSpeciesReference('./Microhierax melanoleucos.jpg'),
	'Micronisus gabar': birdSpeciesReference('./Micronisus gabar.png'),
	'Milvus migrans': birdSpeciesReference('./Milvus migrans.jpg'),
	'Mirafra hypermetra': birdSpeciesReference('./Mirafra hypermetra.png'),
	'Mirafra williamsi': birdSpeciesReference('./Mirafra williamsi.png'),
	'Motacilla aguimp': birdSpeciesReference('./Motacilla aguimp.png'),
	'Motacilla alba': birdSpeciesReference('./Motacilla alba.png'),
	'Motacilla capensis': birdSpeciesReference('./Motacilla capensis.png'),
	'Motacilla cinerea': birdSpeciesReference('./Motacilla cinerea.png'),
	'Motacilla citreola': birdSpeciesReference('./Motacilla citreola.png'),
	'Motacilla clara': birdSpeciesReference('./Motacilla clara.png'),
	'Motacilla maderaspatensis': birdSpeciesReference('./Motacilla maderaspatensis.jpg'),
	'Motacilla samveasnae': birdSpeciesReference('./Motacilla samveasnae.jpg'),
	'Motacilla tschutschensis': birdSpeciesReference('./Motacilla tschutschensis.png'),
	'Mulleripicus pulverulentus': birdSpeciesReference('./Mulleripicus pulverulentus.jpg'),
	'Mycteria ibis': birdSpeciesReference('./Mycteria ibis.png'),
	'Mycteria leucocephala': birdSpeciesReference('./Mycteria leucocephala.jpg'),
	'Necrosyrtes monachus': birdSpeciesReference('./Necrosyrtes monachus.png'),
	'Neohierax insignis': birdSpeciesReference('./Neohierax insignis.png'),
	'Neophron percnopterus': birdSpeciesReference('./Neophron percnopterus.jpg'),
	'Neotis denhami': birdSpeciesReference('./Neotis denhami.png'),
	'Neotis heuglinii': birdSpeciesReference('./Neotis heuglinii.png'),
	'Netta erythrophthalma': birdSpeciesReference('./Netta erythrophthalma.png'),
	'Nicopsitta columboides': birdSpeciesReference('./Nicopsitta columboides.jpg'),
	'Ninox scutulata': birdSpeciesReference('./Ninox scutulata.jpg'),
	'Nisaetus alboniger': birdSpeciesReference('./Nisaetus alboniger.png'),
	'Nisaetus cirrhatus': birdSpeciesReference('./Nisaetus cirrhatus.jpg'),
	'Nisaetus nanus': birdSpeciesReference('./Nisaetus nanus.jpg'),
	'Nisaetus nipalensis': birdSpeciesReference('./Nisaetus nipalensis.jpg'),
	'Nucifraga hemispila': birdSpeciesReference('./Nucifraga hemispila.jpg'),
	'Nucifraga multipunctata': birdSpeciesReference('./Nucifraga multipunctata.jpg'),
	'Numenius arquata': birdSpeciesReference('./Numenius arquata.jpg'),
	'Numenius madagascariensis': birdSpeciesReference('./Numenius madagascariensis.jpg'),
	'Numenius phaeopus': birdSpeciesReference('./Numenius phaeopus.jpg'),
	'Numenius tenuirostris': birdSpeciesReference('./Numenius tenuirostris.png'),
	'Nycticorax nycticorax': birdSpeciesReference('./Nycticorax nycticorax.png'),
	'Oceanites oceanicus': birdSpeciesReference('./Oceanites oceanicus.jpg'),
	'Ocyceros birostris': birdSpeciesReference('./Ocyceros birostris.jpg'),
	'Ocyceros griseus': birdSpeciesReference('./Ocyceros griseus.jpg'),
	'Oena capensis': birdSpeciesReference('./Oena capensis.png'),
	'Onychoprion anaethetus': birdSpeciesReference('./Onychoprion anaethetus.jpg'),
	'Onychoprion fuscatus': birdSpeciesReference('./Onychoprion fuscatus.jpg'),
	'Oriolus mellianus': birdSpeciesReference('./Oriolus mellianus.jpg'),
	'Oroanassa magnifica': birdSpeciesReference('./Oroanassa magnifica.jpg'),
	'Ortygornis sephaena': birdSpeciesReference('./Ortygornis sephaena.png'),
	'Ortyxelos meiffrenii': birdSpeciesReference('./Ortyxelos meiffrenii.png'),
	'Otis tarda': birdSpeciesReference('./Otis tarda.png'),
	'Otus bakkamoena': birdSpeciesReference('./Otus bakkamoena.jpg'),
	'Otus brucei': birdSpeciesReference('./Otus brucei.jpg'),
	'Otus ireneae': birdSpeciesReference('./Otus ireneae.png'),
	'Otus lempiji': birdSpeciesReference('./Otus lempiji.jpg'),
	'Otus lettia': birdSpeciesReference('./Otus lettia.jpg'),
	'Otus sagittatus': birdSpeciesReference('./Otus sagittatus.jpg'),
	'Otus scops': birdSpeciesReference('./Otus scops.png'),
	'Otus senegalensis': birdSpeciesReference('./Otus senegalensis.png'),
	'Otus spilocephalus': birdSpeciesReference('./Otus spilocephalus.png'),
	'Otus sunia': birdSpeciesReference('./Otus sunia.jpg'),
	'Oxyura leucocephala': birdSpeciesReference('./Oxyura leucocephala.png'),
	'Oxyura maccoa': birdSpeciesReference('./Oxyura maccoa.png'),
	'Palaeornis eupatria': birdSpeciesReference('./Palaeornis eupatria.jpg'),
	'Pandion haliaetus': birdSpeciesReference('./Pandion haliaetus.jpg'),
	'Pavo cristatus': birdSpeciesReference('./Pavo cristatus.jpg'),
	'Pelagodroma marina': birdSpeciesReference('./Pelagodroma marina.jpg'),
	'Pelargopsis amauroptera': birdSpeciesReference('./Pelargopsis amauroptera.jpg'),
	'Pelecanus crispus': birdSpeciesReference('./Pelecanus crispus.jpg'),
	'Pelecanus onocrotalus': birdSpeciesReference('./Pelecanus onocrotalus.jpg'),
	'Pelecanus philippensis': birdSpeciesReference('./Pelecanus philippensis.jpg'),
	'Pelecanus rufescens': birdSpeciesReference('./Pelecanus rufescens.png'),
	'Perdicula argoondah': birdSpeciesReference('./Perdicula argoondah.jpg'),
	'Perdicula asiatica': birdSpeciesReference('./Perdicula asiatica.jpg'),
	'Perdicula erythrorhyncha': birdSpeciesReference('./Perdicula erythrorhyncha.jpg'),
	'Perdicula manipurensis': birdSpeciesReference('./Perdicula manipurensis.jpg'),
	'Perdix hodgsoniae': birdSpeciesReference('./Perdix hodgsoniae.jpg'),
	'Pernis apivorus': birdSpeciesReference('./Pernis apivorus.png'),
	'Pernis ptilorhynchus': birdSpeciesReference('./Pernis ptilorhynchus.jpg'),
	'Phaethon aethereus': birdSpeciesReference('./Phaethon aethereus.jpg'),
	'Phaethon lepturus': birdSpeciesReference('./Phaethon lepturus.jpg'),
	'Phaethon rubricauda': birdSpeciesReference('./Phaethon rubricauda.jpg'),
	'Phalacrocorax carbo': birdSpeciesReference('./Phalacrocorax carbo.jpg'),
	'Phalacrocorax fuscicollis': birdSpeciesReference('./Phalacrocorax fuscicollis.jpg'),
	'Phodilus badius': birdSpeciesReference('./Phodilus badius.jpg'),
	'Phoeniconaias minor': birdSpeciesReference('./Phoeniconaias minor.jpg'),
	'Phoenicopterus roseus': birdSpeciesReference('./Phoenicopterus roseus.jpg'),
	'Pica pica': birdSpeciesReference('./Pica pica.jpg'),
	'Picus rabieri': birdSpeciesReference('./Picus rabieri.jpg'),
	'Pitta brachyura': birdSpeciesReference('./Pitta brachyura.jpg'),
	'Pitta moluccensis': birdSpeciesReference('./Pitta moluccensis.png'),
	'Pitta sordida': birdSpeciesReference('./Pitta sordida.png'),
	'Platalea alba': birdSpeciesReference('./Platalea alba.png'),
	'Platalea leucorodia': birdSpeciesReference('./Platalea leucorodia.jpg'),
	'Platalea minor': birdSpeciesReference('./Platalea minor.jpg'),
	'Platysmurus leucopterus': birdSpeciesReference('./Platysmurus leucopterus.jpg'),
	'Ploceus golandi': birdSpeciesReference('./Ploceus golandi.png'),
	'Ploceus megarhynchus': birdSpeciesReference('./Ploceus megarhynchus.jpg'),
	'Pluvialis fulva': birdSpeciesReference('./Pluvialis fulva.jpg'),
	'Pluvialis squatarola': birdSpeciesReference('./Pluvialis squatarola.jpg'),
	'Podica senegalensis': birdSpeciesReference('./Podica senegalensis.png'),
	'Podiceps cristatus': birdSpeciesReference('./Podiceps cristatus.jpg'),
	'Podiceps grisegena': birdSpeciesReference('./Podiceps grisegena.png'),
	'Podiceps nigricollis': birdSpeciesReference('./Podiceps nigricollis.jpg'),
	'Podoces panderi': birdSpeciesReference('./Podoces panderi.png'),
	'Poicephalus cryptoxanthus': birdSpeciesReference('./Poicephalus cryptoxanthus.png'),
	'Poicephalus gulielmi': birdSpeciesReference('./Poicephalus gulielmi.png'),
	'Poicephalus meyeri': birdSpeciesReference('./Poicephalus meyeri.png'),
	'Poicephalus rufiventris': birdSpeciesReference('./Poicephalus rufiventris.png'),
	'Polemaetus bellicosus': birdSpeciesReference('./Polemaetus bellicosus.png'),
	'Polihierax insignis': birdSpeciesReference('./Polihierax insignis.jpg'),
	'Polihierax semitorquatus': birdSpeciesReference('./Polihierax semitorquatus.png'),
	'Polyboroides typus': birdSpeciesReference('./Polyboroides typus.png'),
	'Porphyrio porphyrio': birdSpeciesReference('./Porphyrio porphyrio.png'),
	'Pseudibis davisoni': birdSpeciesReference('./Pseudibis davisoni.png'),
	'Pseudibis papillosa': birdSpeciesReference('./Pseudibis papillosa.jpg'),
	'Psittacula alexandri': birdSpeciesReference('./Psittacula alexandri.jpg'),
	'Psittacula derbiana': birdSpeciesReference('./Psittacula derbiana.jpg'),
	'Psittacus erithacus': birdSpeciesReference('./Psittacus erithacus.png'),
	'Psittinus cyanurus': birdSpeciesReference('./Psittinus cyanurus.jpg'),
	'Pternistis afer': birdSpeciesReference('./Pternistis afer.png'),
	'Pternistis hildebrandti': birdSpeciesReference('./Pternistis hildebrandti.png'),
	'Pternistis jacksoni': birdSpeciesReference('./Pternistis jacksoni.png'),
	'Pternistis leucoscepus': birdSpeciesReference('./Pternistis leucoscepus.png'),
	'Pternistis squamatus': birdSpeciesReference('./Pternistis squamatus.png'),
	'Pterocles alchata': birdSpeciesReference('./Pterocles alchata.png'),
	'Pterocles decoratus': birdSpeciesReference('./Pterocles decoratus.png'),
	'Pterocles indicus': birdSpeciesReference('./Pterocles indicus.jpg'),
	'Pterodroma arminjoniana': birdSpeciesReference('./Pterodroma arminjoniana.jpg'),
	'Ptilopsis granti': birdSpeciesReference('./Ptilopsis granti.png'),
	'Ptilopsis leucotis': birdSpeciesReference('./Ptilopsis leucotis.png'),
	'Ptilostomus afer': birdSpeciesReference('./Ptilostomus afer.png'),
	'Pucrasia macrolopha': birdSpeciesReference('./Pucrasia macrolopha.jpg'),
	'Puffinus bailloni': birdSpeciesReference('./Puffinus bailloni.jpg'),
	'Puffinus persicus': birdSpeciesReference('./Puffinus persicus.jpg'),
	'Pycnonotus xantholaemus': birdSpeciesReference('./Pycnonotus xantholaemus.jpg'),
	'Pyrrhocorax graculus': birdSpeciesReference('./Pyrrhocorax graculus.jpg'),
	'Pyrrhocorax pyrrhocorax': birdSpeciesReference('./Pyrrhocorax pyrrhocorax.jpg'),
	'Ramphiculus jambu': birdSpeciesReference('./Ramphiculus jambu.jpg'),
	'Rhabdotorrhinus corrugatus': birdSpeciesReference('./Rhabdotorrhinus corrugatus.jpg'),
	'Rhinoplax vigil': birdSpeciesReference('./Rhinoplax vigil.jpg'),
	'Rhinoptilus bitorquatus': birdSpeciesReference('./Rhinoptilus bitorquatus.jpg'),
	'Rhinoptilus chalcopterus': birdSpeciesReference('./Rhinoptilus chalcopterus.png'),
	'Rhinoptilus cinctus': birdSpeciesReference('./Rhinoptilus cinctus.png'),
	'Rhyticeros subruficollis': birdSpeciesReference('./Rhyticeros subruficollis.jpg'),
	'Rhyticeros undulatus': birdSpeciesReference('./Rhyticeros undulatus.jpg'),
	'Rostratula benghalensis': birdSpeciesReference('./Rostratula benghalensis.jpg'),
	'Rynchops albicollis': birdSpeciesReference('./Rynchops albicollis.jpg'),
	'Rynchops flavirostris': birdSpeciesReference('./Rynchops flavirostris.png'),
	'Sagittarius serpentarius': birdSpeciesReference('./Sagittarius serpentarius.png'),
	'Sarcogyps calvus': birdSpeciesReference('./Sarcogyps calvus.jpg'),
	'Saundersilarus saundersi': birdSpeciesReference('./Saundersilarus saundersi.jpg'),
	'Saxicola insignis': birdSpeciesReference('./Saxicola insignis.jpg'),
	'Saxicola macrorhynchus': birdSpeciesReference('./Saxicola macrorhynchus.jpg'),
	'Scleroptila gutturalis': birdSpeciesReference('./Scleroptila gutturalis.png'),
	'Scleroptila levaillantii': birdSpeciesReference('./Scleroptila levaillantii.png'),
	'Scleroptila shelleyi': birdSpeciesReference('./Scleroptila shelleyi.png'),
	'Scleroptila streptophora': birdSpeciesReference('./Scleroptila streptophora.png'),
	'Scolopax rusticola': birdSpeciesReference('./Scolopax rusticola.jpg'),
	'Scopus umbretta': birdSpeciesReference('./Scopus umbretta.png'),
	'Scotopelia peli': birdSpeciesReference('./Scotopelia peli.png'),
	'Sheppardia gunningi': birdSpeciesReference('./Sheppardia gunningi.png'),
	'Sholicola albiventris': birdSpeciesReference('./Sholicola albiventris.jpg'),
	'Sholicola major': birdSpeciesReference('./Sholicola major.jpg'),
	'Smutsornis africanus': birdSpeciesReference('./Smutsornis africanus.png'),
	'Spatula clypeata': birdSpeciesReference('./Spatula clypeata.png'),
	'Spilopelia chinensis': birdSpeciesReference('./Spilopelia chinensis.png'),
	'Spilopelia senegalensis': birdSpeciesReference('./Spilopelia senegalensis.png'),
	'Spilornis cheela': birdSpeciesReference('./Spilornis cheela.jpg'),
	'Stephanoaetus coronatus': birdSpeciesReference('./Stephanoaetus coronatus.png'),
	'Stercorarius pomarinus': birdSpeciesReference('./Stercorarius pomarinus.jpg'),
	'Sterna acuticauda': birdSpeciesReference('./Sterna acuticauda.jpg'),
	'Sterna aurantia': birdSpeciesReference('./Sterna aurantia.jpg'),
	'Sterna dougallii': birdSpeciesReference('./Sterna dougallii.jpg'),
	'Sterna hirundo': birdSpeciesReference('./Sterna hirundo.jpg'),
	'Sterna repressa': birdSpeciesReference('./Sterna repressa.jpg'),
	'Sterna sumatrana': birdSpeciesReference('./Sterna sumatrana.jpg'),
	'Sternula albifrons': birdSpeciesReference('./Sternula albifrons.jpg'),
	'Sternula saundersi': birdSpeciesReference('./Sternula saundersi.jpg'),
	'Streptopelia capicola': birdSpeciesReference('./Streptopelia capicola.png'),
	'Streptopelia decipiens': birdSpeciesReference('./Streptopelia decipiens.png'),
	'Streptopelia lugens': birdSpeciesReference('./Streptopelia lugens.png'),
	'Streptopelia orientalis': birdSpeciesReference('./Streptopelia orientalis.png'),
	'Streptopelia reichenowi': birdSpeciesReference('./Streptopelia reichenowi.png'),
	'Streptopelia semitorquata': birdSpeciesReference('./Streptopelia semitorquata.png'),
	'Streptopelia tranquebarica': birdSpeciesReference('./Streptopelia tranquebarica.png'),
	'Streptopelia turtur': birdSpeciesReference('./Streptopelia turtur.png'),
	'Strix aluco': birdSpeciesReference('./Strix aluco.jpg'),
	'Strix leptogrammica': birdSpeciesReference('./Strix leptogrammica.png'),
	'Strix nivicolum': birdSpeciesReference('./Strix nivicolum.jpg'),
	'Strix ocellata': birdSpeciesReference('./Strix ocellata.jpg'),
	'Strix seloputo': birdSpeciesReference('./Strix seloputo.jpg'),
	'Strix woodfordii': birdSpeciesReference('./Strix woodfordii.png'),
	'Sula dactylatra': birdSpeciesReference('./Sula dactylatra.jpg'),
	'Sula leucogaster': birdSpeciesReference('./Sula leucogaster.jpg'),
	'Sula sula': birdSpeciesReference('./Sula sula.jpg'),
	'Synoicus chinensis': birdSpeciesReference('./Synoicus chinensis.jpg'),
	'Sypheotides indicus': birdSpeciesReference('./Sypheotides indicus.jpg'),
	'Syrmaticus humiae': birdSpeciesReference('./Syrmaticus humiae.jpg'),
	'Tachybaptus ruficollis': birdSpeciesReference('./Tachybaptus ruficollis.png'),
	'Temnurus temnurus': birdSpeciesReference('./Temnurus temnurus.jpg'),
	'Terathopius ecaudatus': birdSpeciesReference('./Terathopius ecaudatus.png'),
	'Tetraogallus himalayensis': birdSpeciesReference('./Tetraogallus himalayensis.jpg'),
	'Tetraogallus tibetanus': birdSpeciesReference('./Tetraogallus tibetanus.jpg'),
	'Tetraophasis szechenyii': birdSpeciesReference('./Tetraophasis szechenyii.jpg'),
	'Tetrax tetrax': birdSpeciesReference('./Tetrax tetrax.png'),
	'Thalasseus bengalensis': birdSpeciesReference('./Thalasseus bengalensis.jpg'),
	'Thalasseus bergii': birdSpeciesReference('./Thalasseus bergii.jpg'),
	'Thalasseus bernsteini': birdSpeciesReference('./Thalasseus bernsteini.jpg'),
	'Thalasseus sandvicensis': birdSpeciesReference('./Thalasseus sandvicensis.jpg'),
	'Thaumatibis gigantea': birdSpeciesReference('./Thaumatibis gigantea.png'),
	'Threskiornis aethiopicus': birdSpeciesReference('./Threskiornis aethiopicus.png'),
	'Threskiornis melanocephalus': birdSpeciesReference('./Threskiornis melanocephalus.jpg'),
	'Tmetothylacus tenellus': birdSpeciesReference('./Tmetothylacus tenellus.png'),
	'Tockus deckeni': birdSpeciesReference('./Tockus deckeni.png'),
	'Tockus erythrorhynchus': birdSpeciesReference('./Tockus erythrorhynchus.png'),
	'Tockus jacksoni': birdSpeciesReference('./Tockus jacksoni.png'),
	'Torgos tracheliotos': birdSpeciesReference('./Torgos tracheliotos.png'),
	'Treron apicauda': birdSpeciesReference('./Treron apicauda.png'),
	'Treron bicinctus': birdSpeciesReference('./Treron bicinctus.png'),
	'Treron calvus': birdSpeciesReference('./Treron calvus.png'),
	'Treron capellei': birdSpeciesReference('./Treron capellei.jpg'),
	'Treron curvirostra': birdSpeciesReference('./Treron curvirostra.png'),
	'Treron fulvicollis': birdSpeciesReference('./Treron fulvicollis.jpg'),
	'Treron phayrei': birdSpeciesReference('./Treron phayrei.jpg'),
	'Treron phoenicopterus': birdSpeciesReference('./Treron phoenicopterus.png'),
	'Treron seimundi': birdSpeciesReference('./Treron seimundi.png'),
	'Treron sieboldii': birdSpeciesReference('./Treron sieboldii.png'),
	'Treron sphenurus': birdSpeciesReference('./Treron sphenurus.png'),
	'Treron waalia': birdSpeciesReference('./Treron waalia.png'),
	'Trigonoceps occipitalis': birdSpeciesReference('./Trigonoceps occipitalis.png'),
	'Tringa brevipes': birdSpeciesReference('./Tringa brevipes.jpg'),
	'Tringa erythropus': birdSpeciesReference('./Tringa erythropus.jpg'),
	'Tringa glareola': birdSpeciesReference('./Tringa glareola.jpg'),
	'Tringa guttifer': birdSpeciesReference('./Tringa guttifer.jpg'),
	'Tringa nebularia': birdSpeciesReference('./Tringa nebularia.jpg'),
	'Tringa ochropus': birdSpeciesReference('./Tringa ochropus.jpg'),
	'Tringa stagnatilis': birdSpeciesReference('./Tringa stagnatilis.jpg'),
	'Tringa totanus': birdSpeciesReference('./Tringa totanus.jpg'),
	'Turdus feae': birdSpeciesReference('./Turdus feae.png'),
	'Turdus helleri': birdSpeciesReference('./Turdus helleri.png'),
	'Turnix suscitator': birdSpeciesReference('./Turnix suscitator.jpg'),
	'Turnix sylvaticus': birdSpeciesReference('./Turnix sylvaticus.jpg'),
	'Turnix tanki': birdSpeciesReference('./Turnix tanki.jpg'),
	'Turtur afer': birdSpeciesReference('./Turtur afer.png'),
	'Turtur chalcospilos': birdSpeciesReference('./Turtur chalcospilos.png'),
	'Turtur tympanistria': birdSpeciesReference('./Turtur tympanistria.png'),
	'Tyto alba': birdSpeciesReference('./Tyto alba.jpg'),
	'Tyto capensis': birdSpeciesReference('./Tyto capensis.png'),
	'Tyto longimembris': birdSpeciesReference('./Tyto longimembris.jpg'),
	'Upupa epops': birdSpeciesReference('./Upupa epops.png'),
	'Urocissa erythroryncha': birdSpeciesReference('./Urocissa erythroryncha.jpg'),
	'Urocissa flavirostris': birdSpeciesReference('./Urocissa flavirostris.jpg'),
	'Urocissa xanthomelana': birdSpeciesReference('./Urocissa xanthomelana.jpg'),
	'Vanellus armatus': birdSpeciesReference('./Vanellus armatus.png'),
	'Vanellus cinereus': birdSpeciesReference('./Vanellus cinereus.jpg'),
	'Vanellus coronatus': birdSpeciesReference('./Vanellus coronatus.png'),
	'Vanellus crassirostris': birdSpeciesReference('./Vanellus crassirostris.png'),
	'Vanellus duvaucelii': birdSpeciesReference('./Vanellus duvaucelii.jpg'),
	'Vanellus gregarius': birdSpeciesReference('./Vanellus gregarius.jpg'),
	'Vanellus indicus': birdSpeciesReference('./Vanellus indicus.jpg'),
	'Vanellus leucurus': birdSpeciesReference('./Vanellus leucurus.jpg'),
	'Vanellus lugubris': birdSpeciesReference('./Vanellus lugubris.png'),
	'Vanellus malabaricus': birdSpeciesReference('./Vanellus malabaricus.jpg'),
	'Vanellus melanopterus': birdSpeciesReference('./Vanellus melanopterus.png'),
	'Vanellus senegallus': birdSpeciesReference('./Vanellus senegallus.png'),
	'Vanellus spinosus': birdSpeciesReference('./Vanellus spinosus.png'),
	'Vanellus superciliosus': birdSpeciesReference('./Vanellus superciliosus.png'),
	'Vanellus tectus': birdSpeciesReference('./Vanellus tectus.png'),
	'Vanellus vanellus': birdSpeciesReference('./Vanellus vanellus.jpg'),
	'Xenus cinereus': birdSpeciesReference('./Xenus cinereus.jpg'),
	'Zapornia akool': birdSpeciesReference('./Zapornia akool.jpg'),
	'Zapornia paykullii': birdSpeciesReference('./Zapornia paykullii.jpg'),
	'Zoonavena sylvatica': birdSpeciesReference('./Zoonavena sylvatica.jpg')
}

export const ImageReference = {
    'LOGO_TRANSPARENT': imgReference('./bli_logo_transparent50.png'),
}

export const Icon = (name) => {
    return IconSource[name];
}
