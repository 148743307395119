import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Graphic from '@arcgis/core/Graphic';
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter';
import FeatureEffect from '@arcgis/core/layers/support/FeatureEffect';

import MapTooltip from '../../../ui/MapTooltip';
import { filterRegion, clearRegion } from '../../../../redux/reducers/map';

/*
This component is associated with and is used to control the behavior/visibility of the 
"avistep_internal_boundaries" feature layer in the AviStep webmap (published in AGO)
The avistep_internal_boundaries feature layer contains all of the regional/state boundaries for every country in the 
AviStep map. 
When a country is selected, this layer is visible and filtered to regions in that specific country

When looking at the sensitivity data for a specific region, this component will allow the user to hover over and
to select another region on the map.

*/

function changeCursor(response) {
  if (response.results.length > 0) {
    document.getElementById('map-view-container').style.cursor = 'pointer';
  } else {
    document.getElementById('map-view-container').style.cursor = 'default';
  }
}

const RegionalBoundaries = (props) => {
  const layerTitle = 'avistep_internal_boundaries';
  const regionsLayerRef = useRef(null);
  const mapStateRef = useRef({});
  const highlightGraphic = useRef(null);
  const selectGraphic = useRef(null);
  const watchHover = useRef(null);
  const watchClick = useRef(null);
  const withinScale = useRef(false);

  // Layer Loaded state
  let [lyrLoaded, setLyrLoaded] = useState(false);
  let [lyrView, setLyrView] = useState(null);
  let [showPopup, setShowPopup] = useState(false);
  let [regionName, setRegionName] = useState('');

  const config = useSelector((state) => state.config);
  const mapState = useSelector((state) => state.map.mapState);
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const addHighlight = (graphic) => {
    let highlightID = highlightGraphic.current
      ? highlightGraphic.current.attributes?.OBJECTID
      : null;
    if (highlightID != graphic.attributes.OBJECTID) {
      if (highlightGraphic.current) {
        props.mapView.graphics.remove(highlightGraphic.current);
      }
      let fillOpacity = mapStateRef.current.energy ? 0 : 0.2;
      let sym = {
        type: 'simple-fill',
        outline: { width: 1.75, color: [0, 131, 205, 0.75] },
        color: [76, 129, 205, fillOpacity],
      };
      graphic.symbol = sym;
      highlightGraphic.current = graphic;
      props.mapView.graphics.add(highlightGraphic.current);
    }
  };

  const selectRegion = async (graphic) => {
    if (mapStateRef.current.energy?.code != 'OFFSHORE') {
      let query = {
        where: `objectid = ${graphic.getObjectId()}`,
        returnGeometry: true,
        outFields: ['*'],
      };
      let results = await regionsLayerRef.current.queryFeatures(query);
      if (results.features.length > 0) {
        let regioncode = results.features[0].attributes['GID_1'];
        let newstate = mapStateRef.current.energy
          ? {
              country: mapStateRef.current.country,
              region: regioncode,
              energy: mapStateRef.current.energy.code,
            }
          : { country: mapStateRef.current.country, region: regioncode };
        const params = new URLSearchParams(newstate);
        navigate({
          pathname: location.pathname,
          search: params.toString(),
          replace: true,
        });
        props.mapView.graphics.removeAll();
        if (selectGraphic.current) {
          props.mapView.graphics.remove(selectGraphic.current);
        }
        selectGraphic.current = results.features[0].clone();
        selectGraphic.current.symbol = {
          type: 'simple-fill',
          outline: { width: 2, color: [0, 131, 205, 1] },
          color: [76, 129, 205, 0],
        };
        props.mapView.graphics.add(selectGraphic.current);

        dispatch(filterRegion(results.features[0]));
        props.mapView.goTo({
          target: results.features[0].geometry
        });
      } else {
        dispatch(clearRegion());
      }
    }
  };

  const updateVisibility = async () => {
    if (
      mapStateRef.current.country &&
      mapStateRef.current.energy?.code != 'OFFSHORE' &&
      withinScale.current
    ) {
      regionsLayerRef.current.visible = true;
    } else {
      if (highlightGraphic.current) {
        props.mapView.graphics.remove(highlightGraphic.current);
      }
      if (selectGraphic.current) {
        props.mapView.graphics.remove(selectGraphic.current);
      }
      regionsLayerRef.current.visible = false;
    }
  };

  const updateDefinitionQuery = async () => {
    if (mapStateRef.current.country) {
      let countryfield = 'GID_0';
      let clause = `${countryfield} = '${mapStateRef.current.country}'`;
      regionsLayerRef.current.definitionExpression = clause;
      if (highlightGraphic.current != null) {
        highlightGraphic.current = null;
      }
      if (selectGraphic.current != null) {
        selectGraphic.current = null;
      }
    } else {
      regionsLayerRef.current.definitionExpression = null;
      if (highlightGraphic.current != null) {
        highlightGraphic.current = null;
      }
      if (selectGraphic.current != null) {
        selectGraphic.current = null;
      }
    }
  };

  const updateWatchers = async () => {
    if (mapStateRef.current.country && regionsLayerRef.current.visible) {
      if (watchHover.current == null) {
        watchHover.current = props.mapView.on('pointer-move', (evt) => {
          var screenPoint = { x: evt.x, y: evt.y };
          props.mapView
            .hitTest(screenPoint, {
              include: regionsLayerRef.current,
            })
            .then((response) => {
              if (response.results.length > 0 && withinScale.current) {
                changeCursor(response);
                addHighlight(response.results[0].graphic.clone());

                if (
                  response.results[0].graphic.attributes['NAME_1'] != regionName
                ) {
                  setRegionName(
                    response.results[0].graphic.attributes['NAME_1']
                  );
                  setShowPopup(true);
                } else {
                  setShowPopup(false);
                }
              } else {
                if (highlightGraphic.current) {
                  props.mapView.graphics.remove(highlightGraphic.current);
                }
                setShowPopup(false);
                setRegionName('');
                highlightGraphic.current = null;
              }
            });
        });
      }
      if (watchClick.current == null) {
        watchClick.current = props.mapView.on('click', (evt) => {
          var screenPoint = { x: evt.x, y: evt.y };
          props.mapView
            .hitTest(screenPoint, {
              include: regionsLayerRef.current,
            })
            .then((response) => {
              if (response.results.length > 0 && withinScale.current) {
                selectRegion(response.results[0].graphic);
                highlightGraphic.current = null;
              }
            });
        });
      }
    } else {
      setShowPopup(false);
      if (watchHover.current) {
        watchHover.current.remove();
        watchHover.current = null;
      }
      if (watchClick.current) {
        watchClick.current.remove();
        watchClick.current = null;
      }
    }
  };

  useEffect(() => {
    let scaleWatch = props.mapView.watch('scale', (scale) => {
      let inScaleRange = scale > 1155582 ? true : false;
      if (inScaleRange != withinScale.current) {
        withinScale.current = inScaleRange;
        if (regionsLayerRef.current) {
          regionsLayerRef.current.visible = inScaleRange;
          updateVisibility();
          updateWatchers();
        }
      }
    });
    let viewWatch = props.mapView.on('pointer-leave', function (event) {
      setShowPopup(false);
      setRegionName('');
      if (highlightGraphic.current) {
        props.mapView.graphics.remove(highlightGraphic.current);
      }
    });

    (async () => {
      if (props.mapView && !lyrLoaded) {
        mapStateRef.current = mapState;
        let regionLyr = props.mapView.map.layers.find(
          (layer) => layer.title === layerTitle
        );
        if (regionLyr) {
          // If regionlyr is present, update its visibility
          regionsLayerRef.current = regionLyr;
          updateDefinitionQuery();
          updateVisibility();
          updateWatchers();
          setLyrLoaded(true);
          const featureFilter = new FeatureFilter({ where: `1=1` });
          regionsLayerRef.current.featureEffect = new FeatureEffect({
            filter: featureFilter,
            //includedEffect: 'drop-shadow(3px, 3px, 3px, gray)',
            //excludedEffect: 'drop-shadow(3px, 3px, 3px, gray)',
          });

          if (
            mapStateRef.current.country &&
            mapStateRef.current.region &&
            !mapStateRef.current.lat
          ) {
            let regionfield = 'GID_1';
            let region = mapStateRef.current.region.toString() || '';
            let query = {
              where: `${regionfield} = '${region}'`,
              outFields: ['*'],
              returnGeometry: true,
            };
            let results = await regionLyr.queryFeatures(query);
            if (results.features.length > 0) {
              dispatch(filterRegion(results.features[0].clone()));
              props.mapView.goTo({
                target: results.features[0].geometry,
              });
            } else {
              dispatch(clearRegion());
            }
          }
        }
      }
    })();
    return () => {
      scaleWatch.remove();
      viewWatch.remove();
    };
  }, []);

  useEffect(() => {
    mapStateRef.current = mapState;
    if (lyrLoaded) {
      updateDefinitionQuery();
      updateVisibility();
      updateWatchers();
    }
  }, [mapState]);

  return (
    <>
      {false && (
        <MapTooltip
          isOpen={showPopup}
          displaytext={regionName}
          mapView={props.mapView}
        />
      )}
    </>
  );
};

export default RegionalBoundaries;
