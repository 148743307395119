import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { buildAreaStats } from '../../../../utils/map';

//Redux Imports
import { setCountryReport } from '../../../../redux/reducers/map';

// Esri imports
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
//import * as watchUtils from "@arcgis/core/core/watchUtils";
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';

// Styled Components
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

// Component
const CountryMap = (props) => {
  const containerID = 'map-view-container';
  const mapDiv = useRef(null);
  const mapViewRef = useRef(null);
  const energyLyrRef = useRef(null);
  const regionLyrRef = useRef(null);
  const countryLyrRef = useRef(null);
  const mapStateRef = useRef({});
  const navigate = useNavigate();
  const [mapViewLoaded, setMapViewLoaded] = useState(false);
  const [mapDataLoaded, setMapDataLoaded] = useState(false);
  const [entireData, setEntireData] = useState(false);
  const [suitableData, setSuitableData] = useState(false);
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const countries = useSelector((state) => state.config.countries);
  const regions = useSelector((state) => state.config.regions);
  const mapState = useSelector((state) => state.map.mapState);
  const countryReportStatus = useSelector(
    (state) => state.map.countryReportStatus
  );

  const updateRegionalFilter = async () => {
    if (mapStateRef.current.country) {
      let countryfield = 'GID_0';
      let clause = `${countryfield} = '${mapStateRef.current.country}'`;
      regionLyrRef.current.definitionExpression = clause;
    } else {
      regionLyrRef.current.definitionExpression = null;
    }
  };

  const generateMapData = async () => {
    if (mapStateRef.current.country && mapStateRef.current.energy) {
      let regionfilter = false;
      let suitablefilter = false;
      let ranksfilter = false;
      let filters = [];
      let statisticsfilters = [];
      if (mapStateRef.current.region) {
        // Add filter for region
        let regionfield = 'GID_1';
        regionfilter = `${regionfield} = '${mapStateRef.current.region}'`;
        filters.push(regionfilter);
        statisticsfilters.push(regionfilter);
      }

      // Combine all filters into definitionExpression clause
      let clause =
        !regionfilter && !suitablefilter && !ranksfilter
          ? null
          : filters.join(` AND `);
      let statsclause =
        statisticsfilters.length == 0 ? '1=1' : statisticsfilters.join(` AND `);
      energyLyrRef.current.definitionExpression = clause;
      let query = {
        where: statsclause,
        returnGeometry: false,
        outFields: ['*'],
      };
      let results = await energyLyrRef.current.queryFeatures(query);
      if (results.features.length > 0) {
        let stats = buildAreaStats(results.features);

        mapViewRef.current
          .whenLayerView(energyLyrRef.current)
          .then(async (lview) => {
            /* watchUtils.whenFalseOnce(lview, "updating", function(){
                        setTimeout(function(){
                            mapViewRef.current.takeScreenshot().then(function(screenshot){
                              setEntireData({"stats":stats, "mapimage":screenshot});
                              // Now generate suitable areas only map and stats
                              generateSuitableData();
                            });
                          }, 500);
                    }); */
            reactiveUtils.when(
              () => !mapViewRef.current.updating,
              async () => {
                //console.log("updating: " + mapViewRef.current.updating);
                await setTimeout(() => {
                  mapViewRef.current.takeScreenshot().then((screenshot) => {
                    setEntireData({ stats: stats, mapimage: screenshot });
                    // Now generate suitable areas only map and stats
                    generateSuitableData();
                  });
                }, 500);
              },
              {
                once: true,
              }
            );
          });
      }
    }
  };

  const generateSuitableData = async () => {
    if (
      ['SOLAR', 'ONSHORE', 'OFFSHORE'].includes(mapStateRef.current.energy.code)
    ) {
      let regionfilter = false;
      let suitablefilter = false;
      let ranksfilter = false;
      let filters = [];
      let statisticsfilters = [];
      if (mapStateRef.current.region) {
        // Add filter for region
        let regionfield = 'GID_1';
        regionfilter = `${regionfield} = '${mapStateRef.current.region}'`;
        filters.push(regionfilter);
        statisticsfilters.push(regionfilter);
      }
      // Add filter for suitable areas
      let suitablefield = 'suitable';
      suitablefilter = `${suitablefield} = 1`;
      filters.push(suitablefilter);
      statisticsfilters.push(suitablefilter);

      // Combine all filters into definitionExpression clause
      let clause =
        !regionfilter && !suitablefilter && !ranksfilter
          ? null
          : filters.join(` AND `);
      let statsclause =
        statisticsfilters.length == 0 ? '1=1' : statisticsfilters.join(` AND `);
      energyLyrRef.current.definitionExpression = clause;
      let query = {
        where: statsclause,
        returnGeometry: false,
        outFields: ['*'],
      };
      let results = await energyLyrRef.current.queryFeatures(query);
      if (results.features.length > 0) {
        let suitablestats = buildAreaStats(results.features);

        mapViewRef.current
          .whenLayerView(energyLyrRef.current)
          .then(async (lview) => {
            /* watchUtils.whenFalseOnce(lview, "updating", function(){
                        setTimeout(function(){
                            mapViewRef.current.takeScreenshot().then(function(screenshot){
                                setSuitableData({"stats":suitablestats, "mapimage":screenshot})
                            });
                          }, 500);
                    }); */
            reactiveUtils.when(
              () => !lview.updating,
              async () => {
                //console.log("updating: " + mapViewRef.current.updating);
                await setTimeout(() => {
                  mapViewRef.current.takeScreenshot().then((screenshot) => {
                    setSuitableData({"stats":suitablestats, "mapimage":screenshot})
                  });
                }, 500);
              },
              {
                once: true,
              }
            );
          });
      }
    } else {
      setSuitableData({ stats: null, mapimage: null });
    }
  };

  useEffect(() => {
    let loaded = false;
    (async () => {
      try {
        if (true) {
          // Only load if we have creds
          let webmapID = config.webmapID; //config.webmapID
          var mapConfig = {
            portalItem: { id: webmapID, portal: { url: config.portal } },
            center: [79.48189730121511, 23.49926361971663],
            zoom: 4,
          };

          // create the Map
          const map = new WebMap({
            ...mapConfig,
          });

          map.when(async () => {});

          // eslint-disable-next-line
          const view = new MapView({
            map,
            container: mapDiv.current,
            center: [79.48189730121511, 23.49926361971663],
            zoom: 4,
            constraints: { snapToZoom: false },
          });
          //padding for the left panel
          let paddingWidth = document.getElementsByClassName("left-main")[0].clientWidth;
          view.padding.left = paddingWidth;
          // Remove attribution from map
          view.ui._removeComponents(['zoom', 'attribution']);
          view.when(async () => {
            mapViewRef.current = view;
            view.popup = null;

            view.map.basemap = 'terrain';

            let scaleBar = new ScaleBar({
              view: view,
            });
            view.ui.add(scaleBar, {
              position: 'bottom-left',
            });

            // Override layer settings
            view.map.layers.forEach((layer) => {
              layer.visible = false;
              layer.outFields = ['*'];
              if (layer.type === 'feature') {
                layer.popupEnabled = false;
              }
            });

            let regionLyr = view.map.layers.find(
              (layer) => layer.title === 'avistep_internal_boundaries'
            );
            if (regionLyr) {
              regionLyr.visible = true;
              regionLyrRef.current = regionLyr;
              updateRegionalFilter();
            }
            let countryLyr = view.map.layers.find(
              (layer) => layer.title === 'avistep_countries'
            );
            if (countryLyr) {
              countryLyrRef.current = countryLyr;
            }

            if (!loaded) {
              setMapViewLoaded(true);
            }
          });
        }
      } catch (err) {
        console.log('ERR', err);
        dispatch({
          payload: {
            error: err,
            msg: 'map failed to load',
          },
          type: 'MAP_ERROR',
        });
      }
    })();
    return () => {
      loaded = false;
    };
  }, []);

  useEffect(() => {
    mapStateRef.current = mapState;
  }, [mapState]);

  useEffect(() => {
    if (entireData && suitableData) {
      //props function or dispatch to set data for PDF Report
      let country =
        countries.find(
          (countryitem) => countryitem.code == mapStateRef.current.country
        ) || null;
      country = country ? country.label : '';
      let region = regions[mapStateRef.current.region] || null;
      let page2 = ['SOLAR', 'ONSHORE', 'OFFSHORE'].includes(
        mapStateRef.current.energy.code
      )
        ? suitableData
        : null;
      dispatch(
        setCountryReport({
          country: country,
          region: region,
          energy: mapStateRef.current.energy,
          page1: entireData,
          page2: page2,
        })
      );
    }
  }, [entireData, suitableData]);

  useEffect(() => {
    if (countryReportStatus == 'pending' && mapViewLoaded) {
      (async () => {
        if (mapStateRef.current.country != null && mapViewLoaded) {
          if (mapStateRef.current.region != null && regionLyrRef.current) {
            //Zoom to Region
            let regionfield = 'GID_1';
            let region = mapStateRef.current.region.toString() || '';
            let query = {
              where: `${regionfield} = '${region}'`,
              outFields: ['*'],
              returnGeometry: true,
            };
            let results = await regionLyrRef.current.queryFeatures(query);
            if (results.features.length > 0) {
              mapViewRef.current.goTo({
                target: results.features[0].geometry,
              });
              console.log('SCALE REGION', mapViewRef.current.scale);
            }
          } else {
            //Zoom to Country
            let countryfield = 'GID_0';
            let countrycode = mapStateRef.current.country.toString() || '';
            let query = {
              where: `${countryfield} = '${countrycode}'`,
              outFields: ['*'],
              returnGeometry: true,
            };
            let results = await countryLyrRef.current.queryFeatures(query);
            if (results.features.length > 0) {
              mapViewRef.current.goTo({
                target: results.features[0].geometry,
              });
              console.log('SCALE COUNTRY', mapViewRef.current.scale);
            }
          }
          let layertitle = `avistep_${mapStateRef.current.country.toLowerCase()}_${mapStateRef.current.energy.code.toLowerCase()}_areas`;
          if (layertitle != energyLyrRef.current?.title) {
            if (energyLyrRef.current) {
              energyLyrRef.current.visible = false;
            }
            let energyLyr = mapViewRef.current.map.layers.find(
              (layer) => layer.title === layertitle
            );
            if (energyLyr) {
              // If regionlyr is present, update its visibility
              energyLyr.opacity = 0.3;
              energyLyr.visible = true;
              energyLyrRef.current = energyLyr;
              generateMapData();
            }
          }
        }
      })();
    }
  }, [countryReportStatus, mapViewLoaded]);

  // Map Container
  return <Container id={containerID} ref={mapDiv}></Container>;
};

export default CountryMap;
